import { useEffect, useState } from 'react';

interface PositionOptions {
    preferredPositionY?: 'top' | 'bottom';
    preferredPositionX?: 'left' | 'right';
    offset?: number;
    shift?: number;
}

export const useDynamicPosition = (
    shouldUpdatePosition: boolean,
    triggerRef: React.RefObject<HTMLElement>,
    targetRef: React.RefObject<HTMLElement>,
    { preferredPositionY = 'bottom', preferredPositionX = 'right', offset = 8, shift = 10 }: PositionOptions = {}
) => {
    const [position, setPosition] = useState({ top: 0, left: 0 });

    const adjustPosition = () => {
        if (!triggerRef.current || !targetRef.current) return;

        const triggerRect = triggerRef.current.getBoundingClientRect();
        const targetRect = targetRef.current.getBoundingClientRect();

        let top = 0;
        let left = 0;

        // Pozycja pionowa (góra/dół)
        if (preferredPositionY === "top") {
            top = triggerRect.top - targetRect.height - offset;
            // Jeśli nie mieści się na górze, zmień na dół
            if (top < 0) {
                top = triggerRect.bottom + offset;
            }
        } else {
            top = triggerRect.bottom + offset;
            // Jeśli nie mieści się na dole, zmień na górę
            if (top + targetRect.height > window.innerHeight) {
                top = triggerRect.top - targetRect.height - offset;
            }
        }

        // Pozycja pozioma (lewo/prawo)
        if (preferredPositionX === "left") {
            left = triggerRect.left - targetRect.width + shift;
            // Jeśli nie mieści się po lewej, zmień na prawą
            if (left < 0) {
                left = triggerRect.left + shift;
            }
        } else {
            left = triggerRect.left + shift;
            // Jeśli nie mieści się po prawej, zmień na lewą
            if (left + targetRect.width > window.innerWidth) {
                left = triggerRect.left - targetRect.width + shift;
            }
        }

        setPosition({ top, left });
    };


    useEffect(() => {
        if (shouldUpdatePosition) {
            adjustPosition();
            window.addEventListener('scroll', adjustPosition);
            return () => {
                window.removeEventListener('scroll', adjustPosition);
            };
        }
    }, [shouldUpdatePosition, preferredPositionY, preferredPositionX, offset, shift]);

    return position;
};
