import {TransactionSection} from "../models/interfaces/transaction-section.interface";
import {Transaction} from "../../core/models/interfaces/transaction.interface";

export const mergeTransactionSections = (
    existingSections: TransactionSection[],
    newSections: TransactionSection[]
): TransactionSection[] => {
    const sectionMap: { [label: string]: Transaction[] } = {};

    existingSections.forEach(section => {
        if (!sectionMap[section.label]) {
            sectionMap[section.label] = [...section.items];
        } else {
            sectionMap[section.label] = [...sectionMap[section.label], ...section.items];
        }
    });

    newSections.forEach(section => {
        if (!sectionMap[section.label]) {
            sectionMap[section.label] = [...section.items];
        } else {
            sectionMap[section.label] = [...sectionMap[section.label], ...section.items];
        }
    });

    const mergedSections = Object.keys(sectionMap).map(label => ({
        label,
        items: sectionMap[label]
    }));

    mergedSections.sort((a, b) => {
        // Przykład sortowania na podstawie pierwszej transakcji w każdej sekcji
        const dateA = new Date(a.items[0].txnDate).getTime();
        const dateB = new Date(b.items[0].txnDate).getTime();
        return dateB - dateA;
    });

    return mergedSections;
};
