import {useContext} from "react";
import {FormDigitalProductContext} from "../contexts/form-digital-product-context";

export const useFormDigitalProduct = () => {
    const context = useContext(FormDigitalProductContext);
    if (!context) {
        throw new Error("useFormDigitalProduct must be used within a FormDigitalProductProvider");
    }
    return context;
};
