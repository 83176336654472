import BecomeCreatorForm from "../features/become-creator/views/become-creator-form";
import AppContentWrapper from "../layout/components/app-content-wrapper";
import React from "react";

const BecomeCreatorPage = () => {
    return (
        <div>
            <header className='hidden md:block md:px-6 md:py-4'>
                <img src={require("../assets/images/main-logo.png")} alt='logo' className='h-[3.5rem]'/>
            </header>
            <AppContentWrapper className='p-4 bg-white rounded-t-[1.5rem] min-h-full flex flex-col'>
                <BecomeCreatorForm/>
            </AppContentWrapper>
        </div>
    )
}

export default BecomeCreatorPage
