import {Benefit} from "../models/types/account-benefit.type";
import {ReactComponent as CelebrityIcon} from "@assets/icons/celebrity.svg";
import {ReactComponent as ProductsIcon} from "@assets/icons/products.svg";
import {ReactComponent as SubOutlinedIcon} from "@assets/icons/sub-outlined.svg";

export const accBenefitsFan: Array<Benefit> = [
    {
        text: "Direct access to celebrities",
        Icon: CelebrityIcon,

    },
    {
        text: "Subscribe and get special features",
        Icon: SubOutlinedIcon,

    },
    {
        text: "Buy unique digital products from celebrities",
        Icon: ProductsIcon,
    },
];
