export enum ModalPosition {
    TOP = "top",
    BOTTOM = "bottom",
    LEFT = "left",
    RIGHT = "right",
    CENTER = "center",
    TOP_CENTER = "top-center",
    BOTTOM_CENTER = "bottom-center",
    LEFT_CENTER = "left-center",
    RIGHT_CENTER = "right-center",
    TOP_LEFT = "top-left",
    TOP_RIGHT = "top-right",
    BOTTOM_LEFT = "bottom-left",
    BOTTOM_RIGHT = "bottom-right",
}
