import React, {createContext, ReactNode, useState} from 'react';

interface RedirectContextProps {
    intendedRoute: string | null;
    setIntendedRoute: (route: string | null) => void;
}

export const RedirectContext = createContext<RedirectContextProps | undefined>(undefined);

export const RedirectProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [intendedRoute, setIntendedRoute] = useState<string | null>(null);

    return (
        <RedirectContext.Provider value={{intendedRoute, setIntendedRoute}}>
            {children}
        </RedirectContext.Provider>
    );
};
