const sanitizeUrlString = (str: string): string => {
    return str
        .toLowerCase() // Zamiana na małe litery
        .normalize('NFD') // Zamienia znaki diakrytyczne na ich odpowiedniki (np. ą -> a)
        .replace(/[\u0300-\u036f]/g, '') // Usuwa znaki diakrytyczne
        .replace(/[^a-z0-9\s-]/g, '') // Usuwa niedozwolone znaki
        .trim() // Usuwa białe znaki na początku i końcu
        .replace(/\s+/g, '-') // Zamienia spacje na "-"
        .replace(/-+/g, '-'); // Zmniejsza ciągi "-" do jednego
};

export default sanitizeUrlString
