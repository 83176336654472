import React, {useEffect} from "react";
import useGuardOnBoarding from "../hooks/use-guard-on-boarding";
import useAuth from "../../auth/hooks/use-auth";
import {Navigate, Outlet, RouteProps} from "react-router-dom";


const GuardOnBoarding = (props: RouteProps) => {
    const {currentUser} = useAuth();
    const hasCompletedBoarding: boolean = useGuardOnBoarding(currentUser);

    useEffect(() => {
    }, [hasCompletedBoarding]);


    if (!hasCompletedBoarding) {
        return <Navigate to="/app/on-boarding" replace/>;
    }

    return <Outlet/>;
};

export default GuardOnBoarding;
