import { XMarkIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { NotificationProps } from "../../models/interfaces/notification.interface";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

const SuccessNotification: React.FC<NotificationProps> = (props: NotificationProps) => {
    const {
        notification,
        onClose,
    } = props;

    const [progress, setProgress] = useState(100); // Ustawiamy początkowy stan

    useEffect(() => {
        const duration = notification.duration || 3000;
        setProgress(0);

        const timer = setTimeout(() => {
            onClose();
        }, duration);

        return () => {
            clearTimeout(timer);
        };
    }, [notification.duration, onClose]);

    return (
        <div
            className="pointer-events-auto w-full max-w-sm overflow-hidden bg-white rounded-2xl shadow-notification flex items-center p-3 px-4 relative">
            <div className="flex-shrink-0">
                <CheckCircleIcon aria-hidden="true" className="h-8 w-8 text-semantic-success"/>
            </div>
            <div className="ml-3 w-0 flex-1">
                <p className="text-sm font-semibold text-black">{notification.message}</p>
            </div>
            <div className="ml-auto flex-shrink-0">
                <button
                    type="button"
                    onClick={onClose}
                    className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-light text-gray hover:text-primary focus:outline-none"
                >
                    <XMarkIcon aria-hidden="true" className="h-5 w-5"/>
                </button>
            </div>
            <div className="absolute bottom-0 left-0 right-0 h-1 bg-gray-200">
                <div
                    style={{
                        width: `${progress}%`,
                        transition: `width ${notification.duration || 3000}ms linear`,
                    }}
                    className="h-full bg-green-500"
                />
            </div>
        </div>
    );
}

export default SuccessNotification;
