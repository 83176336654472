import React from "react";
import WrapperCard from "../../common/components/wrapper-card";
import {DigitalProduct} from "../../core/models/interfaces/digital-product.interface";
import Coin from "../../core/components/ui/coin";
import {PhotoIcon} from "@heroicons/react/24/outline";
import {ReactComponent as PinFilledIcon} from "@assets/icons/pin-filled.svg";
import {classNames} from "../../../utils/class-names";
import {useNavigate} from "react-router-dom";
import {Base64Prefix} from "../../common/constats/base64-prefix";

interface CardDigitalProductProps {
    product: DigitalProduct;
    isPinned?: boolean;
}

const CardDigitalProduct = (props: CardDigitalProductProps) => {
    const {
        product,
        isPinned = false,
    } = props;
    const {name, price} = product;
    const navigate = useNavigate();
    const goToEditProductHandler = (aliasName: string) => navigate(`../edit-product/${aliasName}`)
    // const goToProductDetails = () => navigate(`../digital-product/${product.aliasName}`)

    return (
        <WrapperCard
            onClick={() => goToEditProductHandler(product.aliasName)}
            className={classNames(
                "w-full flex justify-between gap-3 !p-[7px] border rounded-2xl shadow-sm relative ",
                "cursor-pointer overflow-ellipsis hover:shadow-md hover:shadow-primary-pastel focus:shadow-primary-pastel",
                isPinned && "!border-black"
            )}>
            {isPinned && (
                <div
                    className="absolute top-[-1px] right-[-1px] bg-black rounded-bl-xl rounded-tr-xl p-2 cursor-pointer">
                    <PinFilledIcon className="w-[10px] h-[10px] text-white"/>
                </div>
            )}

            <div className='flex items-center gap-3 truncate'>

                <div
                    className='min-w-[72px] w-[72px] h-[72px] flex-grow border border-special-gray rounded-2xl bg-gray-light flex justify-center items-center'>
                    {product.productPicture ?
                        <img
                            src={`${Base64Prefix}${product.productPicture}`}
                            alt="Avatar"
                            className="w-full h-full object-cover rounded-2xl"
                        />
                        : <PhotoIcon className='w-5 h-5 text-gray'/>}

                </div>

                <div className="flex flex-col justify-center truncate mt-1">
                    <div className="text-primary  text-[10px] font-semibold ">
                        CATEGORY
                    </div>
                    <span className="text-primary font-semibold text-[0.625rem] uppercase tracking-wide truncate">
                        {/*{DigitalProductCategoryService.getCategoryName(type)}*/}
                    </span>
                    <h3 className="font-semibold text-base max-w-full truncate">{name}</h3>
                    <div className="flex items-center gap-1 text-lg font-bold text-gray-700">
                        {price}
                        <Coin/>
                    </div>
                </div>
            </div>
            {/* <div className='flex flex-col justify-between gap-2'>
                <AppButton
                    onClick={() => deleteProduct(product.id)}
                    label=''
                    className='!p-2 hover:text-primary focus:text-primary'>
                    <div><TrashIcon className='w-4 h-4'/></div>
                </AppButton>
                {showEdit && (
                    <AppButton
                        onClick={() => goToEditProductHandler(product.id)}
                        label=''
                        className='!p-2 hover:text-primary focus:text-primary'>
                        <div><PencilSquareIcon className='w-4 h-4'/></div>
                    </AppButton>
                )}
            </div> */}
        </WrapperCard>
    );
};

export default CardDigitalProduct;
