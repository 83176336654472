import {useContext} from "react";
import {ProfileFormContext} from "../contexts/profile-form-context";

export const useProfileForm = () => {
    const context = useContext(ProfileFormContext);
    if (!context) {
        throw new Error("useProfileForm must be used within a ProfileFormProvider");
    }
    return context;
};
