import {useCallback} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import useAuth from '../hooks/use-auth';
import {useRedirect} from './use-redirect';

interface UseRequiredLoginOptions {
    onLoginRequired?: () => void;
}

const useRequiredLogin = (options?: UseRequiredLoginOptions) => {
    const {isAuthenticated} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const {setIntendedRoute} = useRedirect();

    return useCallback(
        (event?: React.MouseEvent) => {
            if (!isAuthenticated) {
                if (event) {
                    event.preventDefault();
                    event.stopPropagation();
                }
                setIntendedRoute(location.pathname);
                if (options?.onLoginRequired) {
                    options.onLoginRequired();
                } else {
                    navigate('/auth/login');
                }
                return false;
            }
            return true;
        },
        [isAuthenticated, navigate, location, setIntendedRoute, options]
    );
};

export default useRequiredLogin;
