import { useContext } from "react";
import { SidebarContext } from "../contexts/sidebar-context";

/**
 * Custom hook `useSidebar` provides easy access to sidebar management functions and state from the `SidebarContext`.
 *
 * Functions available in `useSidebar`:
 * - `openSidebar(id: SidebarIds)`: Opens the specified sidebar by ID and closes all other sidebars.
 * - `closeSidebar()`: Closes all sidebars.
 * - `toggleSidebar(id: SidebarIds)`: Toggles the state of the specified sidebar by ID.
 * - `isOpen(id: SidebarIds): boolean`: Returns `true` if the specified sidebar is open, otherwise `false`.
 * - `registerSidebar(id: SidebarIds)`: Registers a sidebar by adding its ID to the list of managed sidebars.
 *
 * Requirements:
 * - `useSidebar` must be used within a component wrapped by `SidebarProvider`. If not, it will throw an error.
 * - **Sidebars must be registered** using `registerSidebar(id: SidebarIds)` before interacting with them. This registration step is essential to ensure the sidebar ID is tracked and managed by the context. Unregistered sidebars will not respond to functions like `openSidebar` or `toggleSidebar`.
 *
 * Example usage:
 * ```typescript
 * const { openSidebar, closeSidebar, toggleSidebar, isOpen, registerSidebar } = useSidebar();
 *
 * // Register the sidebar once, typically during the component's initial render
 * const sidebarId = 'exampleSidebarId';
 * registerSidebar(sidebarId);
 *
 * // Interact with the sidebar
 * openSidebar(sidebarId); // Opens the sidebar with ID 'exampleSidebarId'
 * closeSidebar(); // Closes all sidebars
 * toggleSidebar(sidebarId); // Toggles the state of the specified sidebar
 * const isOpenStatus = isOpen(sidebarId); // Checks if the sidebar is open
 * ```
 *
 * This hook simplifies access to sidebar management, ensuring that sidebar operations are consistent and reliable across the application.
 */
export const useSidebar = () => {
    const context = useContext(SidebarContext);
    if (!context) {
        throw new Error('useSidebar must be used within a SidebarProvider');
    }
    return context;
};
