import React, {createContext, ReactNode, useEffect, useState} from 'react';
import {useDigitalProductApi} from "../../core/hooks/use-digital-product-api";
import {DigitalProduct} from "../../core/models/interfaces/digital-product.interface";
import {Pricing} from "../../core/models/interfaces/pricing.interface";
import useAuth from "../../auth/hooks/use-auth";
import {DigitalProductFormBody} from "../models/interfaces/digital-product-form-body.interface";
import {UserAuthority} from "../../auth/models/enums/user-authority.enum";
import {UpdateDigitalProduct} from "../../core/models/interfaces/update-digital-product-request.interface";
import FileHelper from "../../../utils/file-helper";
import Base64Helper from "../../../utils/base64-helper";

export interface SessionCreatorToolsContextProps {
    products: DigitalProduct[];
    contactPrices: Pricing | null;
    setContactPrices: (value: Pricing | null) => void;
    initLoading: boolean;
    error: string | null;
    fetchProducts: () => Promise<void>;
    fetchContactPrices: () => Promise<void>;
    forceReload: () => Promise<void>;
    deleteProduct: (productId: string) => Promise<void>
    addNewProduct: (body: DigitalProductFormBody) => Promise<void>
    editProduct: (productId: string, body: UpdateDigitalProduct) => Promise<void>
}

interface SessionCreatorToolsProviderProps {
    children: ReactNode;
    initFetch?: boolean;
}

export const SessionCreatorToolsContext = createContext<SessionCreatorToolsContextProps | undefined>(undefined);

export const SessionCreatorToolsProvider = (props: SessionCreatorToolsProviderProps) => {
    const [products, setProducts] = useState<DigitalProduct[]>([]);
    const [contactPrices, setContactPrices] = useState<Pricing | null>(null);
    const [initLoading, setInitLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const {currentUser, hasAnyAuthorities} = useAuth();

    const {children, initFetch = false} = props;
    const {
        getOwnerDigitalProducts,
        getContactPrices,
        deleteDigitalProduct,
        createDigitalProduct,
        updateDigitalProduct,
    } = useDigitalProductApi();

    const fetchProducts = async () => {
        setError(null);
        try {
            const products = await getOwnerDigitalProducts(currentUser?.id!);
            setProducts(products);
        } catch (err: any) {
            setError(err.message);
            throw err
        }
    };

    const deleteProduct = async (productId: string) => {
        setError(null);
        try {
            await deleteDigitalProduct(productId);
            setProducts(prevProducts => prevProducts.filter(p => p.id !== productId));
        } catch (err: any) {
            setError(err.message);
            throw err
        }
    };

    const addNewProduct = async (body: DigitalProductFormBody) => {
        setError(null);
        try {
            await createDigitalProduct(body);
            await fetchProducts();
        } catch (err: any) {
            setError(err.message);
            throw err
        }
    };

    const editProduct = async (productId: string, body: UpdateDigitalProduct) => {
        setError(null);
        try {
            await updateDigitalProduct(productId, body);
            const newProductImageBase64 = body.productPicture ? await FileHelper.convertFileToBase64(body.productPicture) : null

            setProducts((prevState: DigitalProduct[]) =>
                prevState.map(product =>
                    product.id === productId
                        ? {
                            id: productId,
                            price: body.price || product.price,
                            name: body.name || product.name,
                            productPicture: Base64Helper.removeBase64Prefix(newProductImageBase64) || product.productPicture,
                            aliasName: body.aliasName || product.aliasName,
                            description: body.description || product.description,
                        }
                        : product
                )
            );
        } catch (err: any) {
            setError(err.message);
            throw err
        }
    };

    const fetchContactPrices = async () => {
        setError(null);
        try {
            const prices = await getContactPrices();
            setContactPrices(prices);
        } catch (err: any) {
            setError(err.message);
            throw err
        }
    };

    const fetchData = async () => {
        setInitLoading(true);
        setError(null);
        try {
            const promises = [fetchProducts()];

            if (hasAnyAuthorities([UserAuthority.CONTENT_CREATOR])) {
                promises.push(fetchContactPrices());
            }

            await Promise.all(promises);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setInitLoading(false);
        }
    };

    useEffect(() => {
        if (initFetch) {
            fetchData();
        }
    }, [initFetch]);

    const forceReload = async () => {
        return await fetchData();
    };

    return (
        <SessionCreatorToolsContext.Provider
            value={{
                products,
                contactPrices,
                initLoading,
                error,
                fetchProducts,
                fetchContactPrices,
                forceReload,
                deleteProduct,
                addNewProduct,
                editProduct,
                setContactPrices
            }}
        >
            {children}
        </SessionCreatorToolsContext.Provider>
    );
};
