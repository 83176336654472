import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import useAuth from "../../auth/hooks/use-auth";
import ProfileApiService from "../services/profile-api.service";

interface UseRequiredFollowOptions {
    onFollowRequired?: () => void;
}

const useRequiredFollow = (ccUsername: string, options?: UseRequiredFollowOptions) => {
    const {isAuthenticated} = useAuth();
    const navigate = useNavigate();

    return useCallback(
        async (event?: React.MouseEvent) => {
            if (!isAuthenticated) {
                if (event) {
                    event.preventDefault();
                    event.stopPropagation();
                }
                return false;
            }

            try {
                const isFollowing: boolean = await ProfileApiService.isCreatorFollowed(ccUsername);

                if (!isFollowing) {
                    if (options?.onFollowRequired) {
                        options.onFollowRequired();
                    }
                    return false;
                }
                return true;
            } catch (error) {
                console.error('Error checking follow status:', error);
                return false;
            }
        },
        [isAuthenticated, navigate, ccUsername, options]
    );
};

export default useRequiredFollow;
