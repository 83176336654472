import useStepper from "../../common/hooks/use-stepper";
import {RegistrationSteps} from "../../core/models/enums/registration-steps.enum";
import useAuth from "./use-auth";
import {useUserApi} from "../../core/hooks/use-user-api";
import {useEffect} from "react";
import {AuthenticatedUser} from "../models/interfaces/authenticated-user.interface";

const useRegistrationSteps = () => {
    const {currentUser, updateAuthenticationField} = useAuth();
    const {updateRegistrationStep} = useUserApi();
    const numberOfSteps = Object.keys(RegistrationSteps).length;
    const steps = Object.values(RegistrationSteps);
    const {currentStep: currentStepIndex, nextStep, isLastStep} = useStepper({
        initialStep: ((currentUser?.registrationStep! || 1) - 1),
        maxSteps: numberOfSteps,
    });
    const currentStep = steps[currentStepIndex];

    const handleNextStep = async () => {
        try {
            await updateRegistrationStep(currentStep + 1);
            nextStep();
        } catch (err: any) {
            console.error(err)
        }
    }

    useEffect(() => {
        const updatedUser: AuthenticatedUser = {
            ...currentUser!,
            registrationStep: currentStep,
        }
        updateAuthenticationField("user", updatedUser);
    }, [currentStepIndex]);

    return {
        currentStep,
        nextStep: handleNextStep,
        isLastStep,
    };
};

export default useRegistrationSteps;
