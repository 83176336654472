import {PwaInstallContextProps} from "../models/interfaces/pwa-install-context-props.interface";
import {useContext} from "react";
import {PwaInstallContext} from "../contexts/pwa-install-context";

/**
 * usePwaInstallFlow hook
 *
 * `usePwaInstallFlow` is a custom hook that allows components to access `PwaInstallContext`.
 * This hook is designed for use in components to check if the PWA is installable and trigger the installation prompt.
 * `usePwaInstallFlow` simplifies access to the context, providing a clean and accessible way to manage PWA installation logic.
 *
 * ### Returned Values
 * - `installPWA`: Function that triggers the PWA installation prompt.
 * - `instructions`: JSX-based installation instructions for unsupported browsers.
 * - `hideInstructions`: Function to hide instructions.
 * - `isInstalled`: A flag indicating if the app has been installed.
 *
 * ### Example Usage
 *
 * ```typescript
 * const { installPWA, instructions, isInstalled } = usePwaInstallFlow();
 *
 * return (
 *   <div>
 *     {!isInstalled && (
 *       <button onClick={installPWA}>Install PWA</button>
 *     )}
 *     {isInstalled && <p>The app is already installed.</p>}
 *   </div>
 * );
 * ```
 *
 * ### Requirements
 * `usePwaInstallFlow` requires `PwaInstallProvider` to be placed higher in the component tree to access `PwaInstallContext`.
 */
export const usePwaInstallFlow = (): PwaInstallContextProps => {
    const context = useContext(PwaInstallContext);
    if (!context) {
        throw new Error('usePwaInstallFlow must be used within an PwaInstallProvider');
    }
    return context;
};
