import React from "react";
import {Benefit} from "../../models/types/account-benefit.type";
import Tooltip from "../../../common/components/tooltip";

interface BenefitsAccountProps {
    benefits: Benefit[];
}

const BenefitsAccount: React.FC<BenefitsAccountProps> = ({benefits}) => {
    return (
        <section className='flex flex-col gap-4'>
            {benefits.map((benefit, index) => (
                <div key={index} className="flex gap-2 text-special-gray">
                    <span className="text-xl">
                        <benefit.Icon className='w-[1.125rem] h-[1.125rem] min-w-[1.125rem]'/>
                    </span>
                    <p className="text-sm w-full font-medium">{benefit.text}</p>
                    <Tooltip/>
                </div>
            ))}
        </section>
    );
};

export default BenefitsAccount;
