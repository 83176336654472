import React from "react";
import {useNavigate} from "react-router-dom";
import AppButton from "../../features/common/components/app-button";
import {ChevronLeftIcon} from "@heroicons/react/20/solid";
import {classNames} from "../../utils/class-names";
import {UniversalClickHandler} from "../../features/common/models/types/universal-click-handler.type";

interface BackButtonProps {
    path?: string;
    className?: string;
    custom?: boolean;
    customEventBack?: UniversalClickHandler;
}

const AppBackButton = (props: BackButtonProps) => {
    const {path, custom = false,customEventBack} = props
    const navigate = useNavigate();

    const handleBack = () => {
        if (customEventBack){
            customEventBack();
        }else {
            if (path) {
                navigate(path);
            } else {
                navigate(-1);
            }
        }
    };

    return (
        <AppButton label=''
                   onClick={handleBack}
                   className={classNames(
                       "relative bg-primary-pastel overflow-clip w-[42px] h-[42px] !rounded-2xl !p-0 !m-0" +
                       "text-primary-darken hover:bg-white focus:bg-white",
                       props.className
                   )}>
            {custom ? (
                <ChevronLeftIcon className='w-5 h-5 min-w-5'/>
            ) : (
                <div className='absolute inset-0 bg-[#5E17EB12] bg-blend-multiply flex items-center justify-center'>
                    <ChevronLeftIcon className='w-5 h-5 min-w-5'/>
                </div>
            )}

        </AppButton>
    );
}

export default AppBackButton
