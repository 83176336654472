import {useCallback} from 'react';
import {useModal} from "./use-modal";
import {ModalIds} from "../models/enums/modal-ids.enum";

const useModalWithData = <T, >(modalId: ModalIds) => {
    const {openModal, closeModal, getModalData, setCloseCallback, isOpenModal} = useModal();

    const showModal = useCallback((data: T) => {
        openModal(modalId, data);
    }, [openModal, modalId]);

    const hideModal = useCallback(() => {
        closeModal(modalId);
    }, [closeModal, modalId]);

    const data = getModalData(modalId) as T | null;

    return {data, showModal, hideModal, setCloseCallback, isOpenModal};
};

export default useModalWithData;
