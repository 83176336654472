import {classNames} from "../../../../utils/class-names";
import RenderPaymentMethodIcon from "./render-payment-method-icon";
import {PaymentMethodType} from "../../../core/models/enums/payment-method-type.enum";

interface BlikProps {
    isSelected?: boolean;
}

const Blik = ({isSelected}: BlikProps) => {
    return (
        <div className="flex flex-col gap-2 items-center justify-center">
            <RenderPaymentMethodIcon option={PaymentMethodType.BLIK}/>
            <span
                className={classNames(
                    "font-medium text-[0.8125rem] leading-[0.975rem]",
                    isSelected ? "text-black" : "text-special-gray"
                )}
            >
                BLIK
            </span>
        </div>
    );
};

export default Blik;
