import React, {useRef} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import TextFormField from "../../common/components/forms/text-form-field";
import Tooltip from "../../common/components/tooltip";
import AppButton from "../../common/components/app-button";
import {UniversalClickHandler} from "../../common/models/types/universal-click-handler.type";
import DateTimeFormField from "../../common/components/forms/date-time-form-field";
import SelectorUserRole from "./selector-user-role";
import {UserAuthority} from "../../auth/models/enums/user-authority.enum";
import {schemaAccountDetailsPartOne} from "../constants/schema-account-details";
import {BasicAccountDetails} from "../models/interfaces/basic-account-details.interface";
import LogoutButton from "../../auth/components/logout-button";
import {useUserApi} from "../hooks/use-user-api";
import useAuth from "../../auth/hooks/use-auth";

interface AccountDetailsFormProps {
    onSuccess?: UniversalClickHandler
}

const AccountDetailsForm = ({onSuccess}: AccountDetailsFormProps) => {
    const {sendBasicInfo, loading} = useUserApi();
    const {reloadSession, currentUser} = useAuth();
    const formRef = useRef<HTMLFormElement>(null);
    const methods = useForm<BasicAccountDetails>({
        resolver: yupResolver(schemaAccountDetailsPartOne),
        mode: 'onSubmit',
        defaultValues: {
            roleId: currentUser?.authority === UserAuthority.FAN ? 4 : 3,
            username: currentUser?.username ?? "",
        }
    });

    const handleSendBasicInfo = async () => {
        const isValid = await methods.trigger();
        if (!isValid) return;

        const data: BasicAccountDetails = methods.getValues();
        try {
            await sendBasicInfo(data);
            await reloadSession();
            onSuccess && await onSuccess();
        } catch (error) {
            console.error("Send basic data user failed:", error);
        }
    };

    const selectRole = async (role: UserAuthority): Promise<void> => {
        const roleId: number = role === UserAuthority.FAN ? 4 : 3
        methods.setValue('roleId', roleId)
        await methods.trigger('roleId')
    }

    const getRole = (): UserAuthority => {
        const roleId: number = methods.getValues('roleId')
        return roleId === 3 ? UserAuthority.CONTENT_CREATOR : UserAuthority.FAN
    }

    return (
        <FormProvider {...methods}>
            <form ref={formRef} onSubmit={methods.handleSubmit(handleSendBasicInfo)}
                  className="flex flex-col gap-y-6 p-4 max-w-md mx-auto h-full min-h-fit">

                <div className='flex-grow md:flex-grow-0 space-y-4'>
                    <div className="flex items-center gap-3 justify-between">
                        <h2 className="text-[1.75rem] md:text-[2.5rem] font-bold">Account details</h2>
                        <span className='inline-block md:hidden'><LogoutButton/></span>
                    </div>

                    <div>
                        <TextFormField name="username" label="Username" placeholder="Write"/>

                        <div className='flex text-special-gray text-xs leading-3 gap-1 mt-2'>
                            <Tooltip/>
                            <span>Use only letters, numbers and some special signs ($ _ -)</span>
                        </div>
                    </div>

                    <DateTimeFormField
                        name='birthDate'
                        onlyDate={true}
                        label='Date of birth'
                        placeholder='DD-MM-YYYY'/>

                    <SelectorUserRole selectedRole={getRole()} onSelect={selectRole}/>

                </div>

                <AppButton
                    type='submit'
                    loading={loading}
                    label='Next'
                    className='w-full text-white text-sm py-[9px] h-fit rounded-lg font-semibold bg-primary'/>
            </form>
        </FormProvider>
    );
}

export default AccountDetailsForm
