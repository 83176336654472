import {PaymentMethodType} from "../../core/models/enums/payment-method-type.enum";

const paymentMethodButtonNames: Record<PaymentMethodType, string> = {
    [PaymentMethodType.CARD]: "with card",
    [PaymentMethodType.BLIK]: "with BLIK",
    [PaymentMethodType.P24]: "with Przelewy24",
    [PaymentMethodType.PAYPAL]: "with PayPal",
    [PaymentMethodType.SKRILL]: "with Skrill",
};

export const getPaymentMethodButtonName = (method: PaymentMethodType | null): string => {
    return method ? paymentMethodButtonNames[method] || "with selected method" : "with method";
};
