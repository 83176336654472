import React, { useRef, useState } from 'react';
import { useFormContext } from "react-hook-form";
import SkeletonFormField from "./skeleton-form-field";
import { classNames } from "../../../../utils/class-names";
import { ReactComponent as CalendarIcon } from "@assets/icons/calendar.svg";
import { DateHelper } from "../../../../utils/date-helper";

interface DateTimeFormFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label: string;
    className?: string;
    labelClassName?: string;
    isLoading?: boolean;
    isRequired?: boolean;
    onlyDate?: boolean;
    onlyTime?: boolean;
    forceDisabled?: boolean;
}

const DateTimeFormField: React.FC<DateTimeFormFieldProps> = (props) => {
    const { register, setValue, formState: { errors, isSubmitting, disabled } } = useFormContext();
    const {
        name,
        label,
        className = '',
        isLoading,
        labelClassName,
        isRequired = false,
        onlyDate = false,
        onlyTime = false,
        forceDisabled = false,
        placeholder = 'Select date',
        ...rest
    } = props;

    const [selectedDate, setSelectedDate] = useState<string | null>(null);
    const [isFocused, setIsFocused] = useState(false);
    const parentInputRef = useRef<HTMLDivElement>(null);

    const handleCalendarClick = () => {
        if (parentInputRef.current?.querySelector('input')) {
            const input = parentInputRef.current.querySelector('input') as HTMLInputElement;
            input.focus();
            input.showPicker?.();
            setIsFocused(true);
        }
    };

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const dateValue = event.target.value;
        const formattedDate = dateValue ? DateHelper.formatDate(new Date(dateValue), "DD-MM-YYYY") : null;
        setSelectedDate(formattedDate);
        setValue(name, dateValue);
        setIsFocused(false);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const inputType = onlyDate ? 'date' : onlyTime ? 'time' : 'datetime-local';

    return (
        <div className="w-full">
            <div className="relative w-full h-fit" ref={parentInputRef} onClick={handleCalendarClick}>
                <label htmlFor={name} className={classNames(
                    "absolute block text-xs font-medium top-2 left-[14px]",
                    (forceDisabled || disabled) && "text-gray"
                )}>
                    {label}
                    {isRequired && <span className="text-red-500">*</span>}
                </label>
                {isLoading ? (
                    <SkeletonFormField />
                ) : (
                    <>
                        <input
                            type={inputType}
                            {...register(name)}
                            onChange={handleDateChange}
                            onFocus={() => setIsFocused(true)}
                            onBlur={handleBlur}
                            className="absolute opacity-0 h-0 w-0 pointer-events-none"
                            disabled={isSubmitting || forceDisabled || disabled}
                            {...rest}
                        />
                        <div
                            className={classNames(
                                `border-2 rounded-xl px-3 py-2 pr-14 pt-6 w-full cursor-pointer transition-all
                                 ${isFocused && 'ring-2 ring-primary'}
                                 ${errors[name] ? 'border-red-400' : 'border-gray-light'} ${className}`,
                                (forceDisabled || disabled) && "bg-gray-light text-gray",
                                selectedDate ? "text-black" : "text-gray"
                            )}
                        >
                            {selectedDate || placeholder}
                        </div>
                    </>
                )}
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer">
                    <div className="bg-gray-light rounded-2xl w-fit h-fit p-[14px]">
                        <CalendarIcon className={`h-[0.75rem] w-[0.75rem] ${errors[name] ? 'text-red-500' : 'text-special-gray'}`} />
                    </div>
                </div>
            </div>
            {errors[name] && <p className="text-red-500 text-xs">{'' + errors[name]?.message}</p>}
        </div>
    );
};

export default DateTimeFormField;
