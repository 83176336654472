import {useEffect, useRef, useState} from "react";
import {ReactComponent as InformationCircleIcon} from "@assets/icons/info.svg";
import {useDynamicPosition} from "../hooks/use-dynamic-position";
import useClickOutside from "../hooks/use-click-outside";
import {classNames} from "../../../utils/class-names";

interface TooltipProps {
    shortInfo?: string;
    info?: string;
    triggerMode?: 'hover' | 'click';
    clickActivationRef?: React.RefObject<HTMLElement>;
    preferredPositionY?: 'top' | 'bottom';
    preferredPositionX?: 'left' | 'right';
    children?: React.ReactNode;
    classNameIcon?: string;
}

const Tooltip = (props: TooltipProps) => {
    const [isVisible, setIsVisible] = useState(false);
    const [showFullInfo, setShowFullInfo] = useState(false);
    const tooltipRef = useRef<HTMLDivElement>(null);
    const iconRef = useRef<HTMLDivElement>(null);
    const readMoreButtonRef = useRef<HTMLButtonElement>(null); // Nowa referencja dla przycisku "Read More"
    const {
        shortInfo,
        info,
        triggerMode = 'click',
        clickActivationRef,
        preferredPositionY = 'bottom',
        preferredPositionX = 'right',
        classNameIcon,
    } = props;

    const showTooltip = () => setIsVisible(true);
    const hideTooltip = () => {
        setIsVisible(false);
        setShowFullInfo(false); // Reset pełnej informacji, gdy tooltip jest zamykany
    };
    const toggleTooltip = () => setIsVisible(prev => !prev);

    const position = useDynamicPosition(isVisible, iconRef, tooltipRef, {
        preferredPositionY,
        preferredPositionX,
        offset: 8,
        shift: 10,
    });

    const showFullInfoHandle = (e: React.MouseEvent<HTMLButtonElement>) => {
        setShowFullInfo(true);
    };

    useClickOutside(hideTooltip, [tooltipRef, iconRef, clickActivationRef!]);

    useEffect(() => {
        if (triggerMode === 'click') {
            const element = clickActivationRef ? clickActivationRef.current : iconRef.current;
            element?.addEventListener('click', toggleTooltip);

            return () => {
                element?.removeEventListener('click', toggleTooltip);
            };
        }
    }, [clickActivationRef, triggerMode]);

    const getActivationHandler = () => ({
        onMouseEnter: triggerMode === 'hover' ? showTooltip : undefined,
        onMouseLeave: triggerMode === 'hover' ? hideTooltip : undefined,
    });

    return (
        <>
            <div ref={iconRef} className="relative inline-block">
                <InformationCircleIcon
                    className={classNames(
                        "w-3 h-3 relative top-0 mx-[2px] cursor-pointer text-special-gray",
                        classNameIcon
                    )}
                    {...getActivationHandler()}
                />
            </div>
            {isVisible && (info || shortInfo) && (
                <div
                    ref={tooltipRef}
                    className="fixed z-10 w-fit p-3 text-sm text-white bg-black rounded-lg shadow-lg"
                    style={{top: position.top, left: position.left}}
                >
                    <div className="relative font-semibold text-xs leading-4">
                        <p>{showFullInfo ? info : shortInfo}</p>
                        {!showFullInfo && info && (
                            <button
                                ref={readMoreButtonRef} // Przypisujemy referencję do przycisku "Read More"
                                className="text-white underline mt-2"
                                onClick={(e) => showFullInfoHandle(e)}
                            >
                                Read More
                            </button>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default Tooltip;
