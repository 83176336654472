import {useState} from 'react';
import DigitalProductApiService from "../services/digital-product-api.service";
import {DigitalProduct} from "../models/interfaces/digital-product.interface";
import {ContactType} from "../models/enums/communications-type.enum";
import {DigitalProductDTO} from "../models/interfaces/digital-product-dto.interface";
import {ActiveDigitalProductPreview} from "../models/interfaces/active-digital-product-preview.interface";
import {CreateDigitalProductRequest} from "../models/interfaces/create-digital-product-request.interface";
import {Pricing} from "../models/interfaces/pricing.interface";
import {DigitalProductFormBody} from "../../digital-products/models/interfaces/digital-product-form-body.interface";
import {
    UpdateDigitalProduct,
    UpdateDigitalProductRequest
} from "../models/interfaces/update-digital-product-request.interface";
import {ProductFile, TempProductFile} from "../../digital-products/models/interfaces/product-file.interface";
import {DigitalProductDetails} from "../models/interfaces/digital-product-details.interface";

export const useDigitalProductApi = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const getOwnerDigitalProducts = async (userId: string): Promise<DigitalProduct[]> => {
        setLoading(true);
        setError(null);
        try {
            return await DigitalProductApiService.getOwnerDigitalProducts(userId);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const getPublicProfileDigitalProducts = async (userId: string): Promise<DigitalProduct[]> => {
        setLoading(true);
        setError(null);
        try {
            return await DigitalProductApiService.getPublicProfileDigitalProducts(userId);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const existsProduct = async (aliasName: string, userName: string): Promise<boolean> => {
        setLoading(true);
        setError(null);
        try {
            return await DigitalProductApiService.existsProduct(aliasName, userName);
        } finally {
            setLoading(false);
        }
    };

    const getDigitalProduct = async (aliasName: string, userName: string): Promise<DigitalProductDetails> => {
        setLoading(true);
        setError(null);
        try {
            return await DigitalProductApiService.getDigitalProduct(aliasName, userName);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const getActiveDigitalProducts = async (): Promise<ActiveDigitalProductPreview[]> => {
        setLoading(true);
        setError(null);
        try {
            return await DigitalProductApiService.getActiveDigitalProducts();
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const createDigitalProduct = async (data: DigitalProductFormBody): Promise<DigitalProductDTO> => {
        setLoading(true);
        setError(null);
        try {
            const mappedBody: CreateDigitalProductRequest = {
                description: data.description!,
                price: data.price,
                name: data.name!,
                aliasName: data.aliasName,
            }
            const files: ProductFile[] = data.files
                ?.sort((el, el2) => el.ordinalNumber - el2.ordinalNumber)
                .map(el => ({ordinalNumber: el.ordinalNumber, file: el.file, uuid: el.uuid})) ?? [];
            return await DigitalProductApiService.createDigitalProduct(mappedBody, data.productPicture, files);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const updateDigitalProduct = async (id: string, data: UpdateDigitalProduct): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            const mappedBody: UpdateDigitalProductRequest = {
                description: data.description!,
                price: data.price,
                name: data.name!,
                aliasName: data.aliasName,
                existingFilesIds: data.existingFilesIds,
                filesIdsToRemove: data.filesIdsToRemove,
                fileMappings: (data.files || []).map(el => ({
                    ordinalNumber: el.ordinalNumber,
                    uuid: el.uuid,
                    indexMultipart: null,
                })),
                shouldDeleteImageProduct: data.productPicture === undefined ? false : data.productPicture === null,
            }
            const files: TempProductFile[] | undefined = data.files
                ?.sort((el, el2) => el.ordinalNumber - el2.ordinalNumber)
                .filter(el => !data.existingFilesIds.includes(el.uuid))
            return await DigitalProductApiService.updateDigitalProduct(id, mappedBody, data.productPicture, files);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const deleteDigitalProduct = async (id: string): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            return await DigitalProductApiService.deleteDigitalProduct(id);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const buyDigitalProduct = async (productId: string): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            return await DigitalProductApiService.buyDigitalProduct(productId);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const getContactPrices = async (): Promise<Pricing> => {
        setLoading(true);
        setError(null);
        try {
            return await DigitalProductApiService.getContactPrices();
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const changeContactPrice = async (yCredits: number, typeContact: ContactType): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            return await DigitalProductApiService.changeContactPrice(yCredits, typeContact);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return {
        loading,
        error,
        getOwnerDigitalProducts,
        getPublicProfileDigitalProducts,
        getDigitalProduct,
        getActiveDigitalProducts,
        createDigitalProduct,
        updateDigitalProduct,
        deleteDigitalProduct,
        buyDigitalProduct,
        getContactPrices,
        changeContactPrice,
        existsProduct,
    };
};
