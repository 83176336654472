import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useFormContext} from "react-hook-form";
import SkeletonFormField from "./skeleton-form-field";
import {classNames} from "../../../../utils/class-names";

interface DescriptionFormFieldProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    name: string;
    label: string;
    maxLength: number;
    className?: string;
    isLoading?: boolean;
    isRequired?: boolean;
    forceDisabled?: boolean;
}

const DescriptionFormField: React.FC<DescriptionFormFieldProps> = (props: DescriptionFormFieldProps) => {
    const {register, watch, formState: {errors, isSubmitting, disabled}, getValues, setValue} = useFormContext();
    const {
        name,
        label,
        maxLength,
        className = '',
        isLoading,
        isRequired = false,
        forceDisabled = false,
        ...rest
    } = props;
    const currentValue = watch(name);
    const [charCount, setCharCount] = useState(getValues(name)?.length);
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(name, event.target.value, {shouldDirty: true});
        event.target.style.height = "20px";
        event.target.style.height = `${event.target.scrollHeight}px`;
        event.target.style.maxHeight = `${event.target.scrollHeight}px`;
    };

    const setHeightArea = () => {
        const textarea = document.getElementById(name) as HTMLTextAreaElement;
        if (textarea) {
            textarea.style.height = "20px";
            textarea.style.height = `${textarea.scrollHeight}px`;
            textarea.style.maxHeight = `${textarea.scrollHeight}px`;
        }
    }

    useEffect(() => {
        setHeightArea();
    }, [name, currentValue]);

    useLayoutEffect(() => {
        window.addEventListener('resize', setHeightArea)
        return () => window.removeEventListener('resize', setHeightArea)
    }, []);

    useEffect(() => {
        setCharCount(currentValue?.length || 0);
    }, [currentValue]);

    return (
        <div className='w-full'>
            <div
                onClick={() => document.getElementById(name)?.focus()}
                className={classNames(
                    "relative w-full border-2 rounded-xl px-3 py-2 pb-[1px] space-y-1 cursor-text ",
                    isFocused ? `border-primary ring-primary_400 ${errors[name] && "ring-2"}` : "border-gray-light",
                    (forceDisabled || disabled || isSubmitting) && "!bg-[#FAFAFA] !cursor-default",
                    errors[name] ? "!ring-red-500 !border-red-500" : "",
                    className
                )}
            >
                <label htmlFor={name} className={classNames(
                    "block text-xs font-medium first-letter:uppercase w-fit",
                    (forceDisabled || disabled) && "text-gray"
                )}>
                    {label}
                    {isRequired && <span className='text-red-500'>*</span>}
                </label>
                {isLoading
                    ? <SkeletonFormField/>
                    : <>
                        <textarea
                            id={name}
                            {...register(name)}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            placeholder="Write your description"
                            maxLength={maxLength}
                            onChange={handleInputChange}
                            className={classNames(
                                `overflow-hidden h-[20px] appearance-none w-full focus:outline-none resize-none leading-[20px]`,
                            )}
                            disabled={isSubmitting || forceDisabled || disabled}
                            {...rest}
                        />
                        <div className="absolute top-[8px] right-[12px] text-right text-gray text-xs">
                            {charCount || 0} / {maxLength}
                        </div>
                    </>
                }
            </div>
            {errors[name] && <p className="text-red-500 text-xs">{'' + errors[name]?.message}</p>}
        </div>
    );
};

export default DescriptionFormField;
