import React, {RefObject, useEffect, useRef, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {Listbox} from '@headlessui/react';
import {MagnifyingGlassIcon} from "@heroicons/react/24/solid";
import {ReactComponent as ChevronDownIcon} from "@assets/icons/chevron-down.svg";
import {classNames} from "../../../../utils/class-names";
import SkeletonFormField from "./skeleton-form-field";

interface SelectFormFieldProps {
    name: string;
    label: string;
    options?: any[];
    fetchOptions?: () => Promise<any[]>;
    className?: string;
    valueKey?: string;
    labelKey?: string;
    parentWrapperRef?: RefObject<HTMLElement>;
    onChange?: (value: any) => void;
    isLoading?: boolean;
    isRequired?: boolean;
    searchable?: boolean;
}

const SelectFormField: React.FC<SelectFormFieldProps> = (props: SelectFormFieldProps) => {
    const {register, trigger, setValue, watch, formState: {errors, isSubmitting, disabled}} = useFormContext();
    const [dropUp, setDropUp] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(props.isLoading || false);
    const [options, setOptions] = useState<any[]>(props.options || []);
    const [filteredOptions, setFilteredOptions] = useState<any[]>(props.options || []);
    const [selectedOption, setSelectedOption] = useState<any | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const selectRef = useRef<HTMLDivElement>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);
    const {
        name,
        label,
        className = '',
        valueKey = 'value',
        labelKey = 'label',
        isRequired = false,
        searchable = false,
        parentWrapperRef,
        onChange,
        fetchOptions,
    } = props;
    const currentValue = watch(name);

    const checkDropUp = () => {
        if (parentWrapperRef?.current && selectRef.current) {
            const optionHeight = 42;
            const optionsHeight = optionHeight * Math.min(filteredOptions.length, 6);
            const parentRect = parentWrapperRef.current.getBoundingClientRect();
            const selectRect = selectRef.current.getBoundingClientRect();
            const spaceBelow = parentRect.bottom - selectRect.bottom;
            const shouldDropUp = spaceBelow - optionsHeight < 0;
            setDropUp(shouldDropUp);
        }
    };

    const handleChange = async (option: any) => {
        setSelectedOption(option);
        setValue(name, option[valueKey]);
        await trigger(name)
        onChange && onChange(option);
        setIsOpen(false);
        if (searchable) {
            setFilteredOptions(options);
            setSearchTerm("");
        }
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchTerm(value);
        if (value) {
            const filtered = options.filter(option => option[labelKey].toLowerCase().includes(value.toLowerCase()));
            setFilteredOptions(filtered);
        } else {
            setFilteredOptions(options);
        }
    };

    const focusSearchBar = () => {
        setTimeout(() => {
            searchInputRef?.current?.focus()
        }, 0)
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    useEffect(() => {
        const loadOptions = async () => {
            if (fetchOptions) {
                setIsLoading(true);
                try {
                    const fetchedOptions = await fetchOptions();
                    setOptions(fetchedOptions);
                    setFilteredOptions(fetchedOptions);
                } catch (error) {
                    console.error("Failed to fetch options:", error);
                } finally {
                    setIsLoading(false);
                }
            }
        };
        loadOptions();
    }, []);

    useEffect(() => {
        if (currentValue !== undefined && options.length > 0) {
            const initialOption = options.find(option => option[valueKey] === currentValue);
            setSelectedOption(initialOption || null);
            setValue(name, initialOption?.[valueKey] || '');
        }
    }, [currentValue]);

    useEffect(() => {
        setIsLoading(!!props.isLoading);
    }, [props.isLoading]);

    useEffect(() => {
        if (isOpen && searchable) focusSearchBar();
    }, [isOpen, searchable]);

    return (
        <div className="relative w-full h-fit" ref={selectRef}>
            <label htmlFor={name} className="absolute block text-sm font-medium top-2 left-[14px]">
                {label}
                {isRequired && <span className='text-red-500'>*</span>}
            </label>

            {isLoading ? (
                <SkeletonFormField/>
            ) : (
                <Listbox value={selectedOption} onChange={handleChange} disabled={isSubmitting}>
                    <Listbox.Button
                        onClick={() => {
                            checkDropUp();
                            setIsOpen(!isOpen);
                        }}
                        className={classNames(
                            'relative border-2 rounded-xl px-3 py-2 pt-6 appearance-none w-full focus:outline-none focus:ring-0 focus:border-primary',
                            errors[name] ? 'border-red-500 focus:ring-red-500' : isOpen ? 'border-primary' : 'border-gray-light',
                            className,
                            disabled && "!bg-gray-light text-gray"
                        )}
                    >
                        <span className={classNames(
                            "block truncate text-left text-[#9ca3afe8]",
                            selectedOption && '!text-black'
                        )}>{selectedOption?.[labelKey] || 'Select an option'}</span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                            <ChevronDownIcon className="w-2 h-2 text-black" aria-hidden="true"/>
                        </span>
                    </Listbox.Button>
                    {isOpen && (
                        <Listbox.Options
                            className={classNames(
                                'absolute w-full py-1 bg-white border-2 rounded-xl focus:outline-none z-10 max-h-[200px] flex flex-col overflow-clip',
                                dropUp ? 'bottom-full mb-2' : 'top-full mt-1',
                                isOpen ? 'border-primary ring-primary' : 'border-gray-light'
                            )}
                        >
                            {searchable && (
                                <div className="relative p-2 min-h-fit w-full">
                                    <input
                                        ref={searchInputRef}
                                        type="text"
                                        value={searchTerm}
                                        onChange={handleSearch}
                                        placeholder="Search..."
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                                    />
                                    <span className="absolute right-4 top-1/2 translate-y-[-50%]">
                                        <MagnifyingGlassIcon className="w-4 h-4 text-special-gray" aria-hidden="true"/>
                                    </span>
                                </div>
                            )}
                            <div className='mr-2 overflow-y-auto flex-grow'>
                                {!filteredOptions.length && (
                                    <Listbox.Option
                                        value={null}
                                        className={() => classNames(
                                            'cursor-pointer select-none relative py-2 mx-4',
                                        )}
                                    >
                                         <span
                                             className={classNames('block truncate font-normal text-special-gray text-left')}>
                                           Empty dropdown
                                        </span>
                                    </Listbox.Option>
                                )}
                                {filteredOptions.map((option, index) => (
                                    <Listbox.Option
                                        key={index}
                                        value={option}
                                        className={({active}) => classNames(
                                            'cursor-pointer select-none relative py-2 mx-4',
                                            active ? 'bg-primary-600 ' : '',
                                            filteredOptions.length > index && 'border-b border-gray-light'
                                        )}
                                    >
                                        {({selected}) => (
                                            <span className={classNames(
                                                'block truncate',
                                                selected ? 'font-semibold text-primary' : 'font-normal'
                                            )}>
                                            {option[labelKey]}
                                        </span>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </div>
                        </Listbox.Options>
                    )}
                </Listbox>
            )}

            <input type="hidden" {...register(name)} value={selectedOption?.[valueKey] || ''}/>

            {errors[name] && <p className="text-red-500 text-xs">{'' + errors[name]?.message}</p>}
        </div>
    );
};

export default SelectFormField;
