import React, {createContext, useContext, useEffect, useState} from 'react';
import {useForm, UseFormReturn} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {schemaAddProduct} from "../constants/schema-add-product";
import {DigitalProductFormBody} from "../models/interfaces/digital-product-form-body.interface";
import {SessionCreatorToolsContext} from "./session-creator-tools-context";
import {useNavigate, useParams} from "react-router-dom";
import {useDigitalProductApi} from "../../core/hooks/use-digital-product-api";
import sanitizeUrlString from "../../../utils/sanitize-url-string";
import useAuth from "../../auth/hooks/use-auth";
import FileHelper from "../../../utils/file-helper";
import {TempProductFile} from "../models/interfaces/product-file.interface";
import {DigitalProductDetails} from "../../core/models/interfaces/digital-product-details.interface";
import LocalStorageService from "../../common/services/local-storage.service";
import {LocalStorageKeys} from "../../common/models/enums/local-storage-keys.enum";

interface FormDigitalProductContextType {
    methods: UseFormReturn<DigitalProductFormBody>;
    handleSubmitProduct: () => Promise<void>;
    mode: "create" | "edit" | "preview";
    setMode: (mode: "create" | "edit" | "preview") => void;
    loading: boolean;
    isSubmittingSuccessful: boolean;
    previewModeOn: boolean,
    setPreviewModeOn: (previewModeOn: boolean) => void,
    productId: string | null;
    removeFile: (fileToRemove: TempProductFile) => void,
    addNewFiles: (newFile: TempProductFile[]) => void,
}

interface FormDigitalProductProviderProps {
    children: React.ReactNode,
    mode: "create" | "edit" | "preview"
}

export const FormDigitalProductContext = createContext<FormDigitalProductContextType | undefined>(undefined);

export const FormDigitalProductProvider: React.FC<FormDigitalProductProviderProps> = (props: FormDigitalProductProviderProps) => {
    const {addNewProduct, editProduct} = useContext(SessionCreatorToolsContext)!;
    const {currentUser} = useAuth();
    const {getDigitalProduct, loading,existsProduct} = useDigitalProductApi();
    const [mode, setMode] = useState(props.mode);
    const [productId, setProductId] = useState<string | null>(null);
    const [previewModeOn, setPreviewModeOn] = useState<boolean>(false);
    const [aliasName, setAliasName] = useState<string | null>(null);
    const params = useParams();
    const navigate = useNavigate();
    const {children} = props
    const [isSubmittingSuccessful, setIsSubmittingSuccessful] = useState(false);
    const methods = useForm<DigitalProductFormBody>({
        defaultValues: {
            name: "",
            aliasName: "",
            description: null,
            files: [],
            productPicture: null,
            price: 1,
        },
        // @ts-ignore
        resolver: yupResolver(schemaAddProduct),
        mode: 'onSubmit',
    });
    const nameValue = methods.watch('name');

    const [toDeleteFileIds, setToDeleteFileIds] = useState<string[]>([]);
    const [existingFileIds, setExistingFileIds] = useState<string[]>([]);
    const [ordinalNumberFiles, setOrdinalNumberFiles] = useState<Map<string, number>>(new Map());

    const handleRemoveFile = (fileToRemove: TempProductFile) => {
        if (existingFileIds.includes(fileToRemove.uuid) && !toDeleteFileIds.includes(fileToRemove.uuid)) {
            setToDeleteFileIds(prev => [...prev, fileToRemove.uuid]);
        }

        const currentFiles = methods.getValues('files') || [];
        const updatedFiles = currentFiles.filter(f => f.uuid !== fileToRemove.uuid);
        methods.setValue('files', updatedFiles, {shouldDirty: true});
        setOrdinalNumberFiles(generateOrdinalNumberMap(updatedFiles));
    };

    const handleAddNewFiles = (newFiles: TempProductFile[]) => {
        methods.setValue('files', newFiles, {shouldDirty: true, shouldTouch: true, shouldValidate: true});
        setOrdinalNumberFiles(generateOrdinalNumberMap(newFiles));
    };

    const handleSubmitProduct = async () => {
        await methods.handleSubmit(async (data) => {
            try {
                if (mode === "create") {
                    await addNewProduct(data);
                    navigate("../digital-products");
                    methods.reset();
                    setIsSubmittingSuccessful(true);
                } else if (mode === "edit" && aliasName) {
                    await editProduct(productId!, {
                        ...data,
                        existingFilesIds: existingFileIds,
                        filesIdsToRemove: toDeleteFileIds,
                        fileOrdinalNumbers: ordinalNumberFiles,
                        productPicture: methods.formState.dirtyFields.productPicture ? data.productPicture : undefined,
                    });
                    LocalStorageService.save(LocalStorageKeys.ALIAS_NAME_EDITED_PRODUCT, data.aliasName);

                    methods.reset({...data});
                    setToDeleteFileIds([]);
                    setExistingFileIds((data.files || []).map(el => el.uuid));
                }
            } catch (error) {
                console.error("Error while submitting product:", error);
            }
        })();
    };

    const generateOrdinalNumberMap = (files: TempProductFile[]): Map<string, number> => {
        const ordinalMap = new Map<string, number>();
        files.forEach((file, index) => {
            ordinalMap.set(file.uuid, index + 1);
        });
        return ordinalMap;
    };

    const mapProductFiles = (productFiles: DigitalProductDetails["files"]): TempProductFile[] => {
        return productFiles.map((file) => ({
            file: FileHelper.createEmptyFile(file.fileName, file.fileSize, file.fileFormat),
            uuid: file.uuid,
            ordinalNumber: file.ordinalNumber,
            previewUrl: "",
        }));
    };

    useEffect(() => {
        const sanitizedAlias = sanitizeUrlString(nameValue);
        methods.setValue('aliasName', sanitizedAlias);
        methods.trigger('aliasName');
        if (isSubmittingSuccessful) setIsSubmittingSuccessful(false);
    }, [nameValue]);

    useEffect(() => {
        if (mode === "edit") {
            const aliasName = params["aliasName"];
            aliasName && setAliasName(aliasName)
        }
    }, [])

    useEffect(() => {
        const fetchProduct = async (aliasName: string, userName: string) => {
            try {
                const isExistingProduct: boolean | undefined = await existsProduct(aliasName, userName);
                let product: DigitalProductDetails = null as unknown as any;
                let savedAliasNameEditedProduct = LocalStorageService.get(LocalStorageKeys.ALIAS_NAME_EDITED_PRODUCT);
                LocalStorageService.remove(LocalStorageKeys.ALIAS_NAME_EDITED_PRODUCT);

                if (isExistingProduct) {
                    product = await getDigitalProduct(aliasName, userName);
                } else {
                    if (savedAliasNameEditedProduct){
                        product = await getDigitalProduct(savedAliasNameEditedProduct, userName);
                        window.history.replaceState({}, '', `/app/cc-tools/edit-product/${product.aliasName}`)
                    }
                }

                const productPicture = product.productPicture?.base64
                    ? FileHelper.convertBase64ToFile(
                        product.productPicture.base64,
                        product.productPicture.fileName,
                        product.productPicture.fileFormat?.toLowerCase()
                    )
                    : null;

                const productFiles = mapProductFiles(product.files);
                setProductId(product.id);
                setExistingFileIds(product.files.map(file => file.uuid));
                setOrdinalNumberFiles(generateOrdinalNumberMap(productFiles));

                const formData: DigitalProductFormBody = {
                    name: product.name,
                    aliasName: product.aliasName,
                    price: product.price,
                    files: productFiles,
                    description: product.description,
                    productPicture,
                };
                methods.reset(formData);
            } catch (error) {
                console.error("Błąd podczas pobierania produktu:", error);
            }
        };

        if (aliasName) {
            fetchProduct(aliasName, currentUser?.username!);
        }
    }, [aliasName, methods, productId]);

    return (
        <FormDigitalProductContext.Provider
            value={{
                methods,
                handleSubmitProduct,
                mode,
                setMode,
                loading,
                isSubmittingSuccessful,
                previewModeOn,
                setPreviewModeOn,
                productId,
                removeFile: handleRemoveFile,
                addNewFiles: handleAddNewFiles,
            }}>
            {children}
        </FormDigitalProductContext.Provider>
    );
};
