import {useState} from "react";

const AppTopBarAnnouncement = () => {
    const [announcement, setAnnoucement] = useState('');

    if (!announcement) return null;

    return (
        <div className='mx-4 text-center p-3 rounded-b-3xl bg-primary text-white text-md'>
        </div>
    )
}

export default AppTopBarAnnouncement
