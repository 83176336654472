import useAuth from "../../auth/hooks/use-auth";
import { UserAuthority } from "../../auth/models/enums/user-authority.enum";
import SettingsHeader from "../components/settings-header";
import NotificationsFan from "../components/notifications-fan";
import NotificationsCC from "../components/notifications-cc";

export interface AppFanFormValues {
    isNotificationsApp: boolean,
    isTextMessagesApp: boolean,
    isVoiceMessagesApp: boolean,
    isVideoMessagesApp: boolean,
    isYCreditsApp: boolean,
    
}

export interface EmailFanFormValues {
    isNotificationsEmail: boolean,
    isTextMessagesEmail: boolean,
    isVoiceMessagesEmail: boolean,
    isVideoMessagesEmail: boolean,
    isYCreditsEmail: boolean
}

export interface AppCCFormValues {
    isNotificationsApp: boolean,
    isTextMessagesApp: boolean,
    isVoiceMessagesApp: boolean,
    isVideoMessagesApp: boolean,
    isNewFanApp: boolean,
    isNewSubscriptionApp: boolean,
    isPurchasePeApp: boolean,
    
}

export interface EmailCCFormValues {
    isNotificationsEmail: boolean,
    isTextMessagesEmail: boolean,
    isVoiceMessagesEmail: boolean,
    isVideoMessagesEmail: boolean,
    isNewFanEmail: boolean,
    isNewSubscriptionEmail: boolean,
    isPurchasePeEmail: boolean,
}


export enum NotificationType {
    App,Email
}

const NotificationsView = () => {

    const {currentUser} = useAuth();

    return (
        <>
        <div className="min-w-[300px] mr-auto ml-auto w-fit m-4 md:m-0 md:ml-auto md:mr-auto">
            <SettingsHeader title='Notifications' className="pl-0"/>
            <div className="md:flex md:justify-around w-full md:gap-x-[80px]">
                
                {currentUser!.authority == UserAuthority.FAN ? 
                (
                    <NotificationsFan/>
                )
                : 
                (
                    <NotificationsCC/>
                )
            }
            </div>
         </div>
        </>
    )
}

export default NotificationsView;