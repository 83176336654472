import {ItemSubmenuWallet} from "../models/interfaces/item-submenu-wallet.interface";

export const WalletSubmenu: Array<ItemSubmenuWallet> = [
    {
        text: "Balance",
        href: ""
    },
    {
        text:"Transactions history",
        href:"/history"
    }
]
