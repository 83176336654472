import React, { forwardRef } from "react";
import AppButton from "../../../common/components/app-button";
import { classNames } from "../../../../utils/class-names";
import { ReactComponent as EllipsisVerticalIcon } from "@assets/icons/more.svg";
import { UniversalClickHandler } from "../../../common/models/types/universal-click-handler.type";

interface ButtonMoreActionsProps {
    onClick?: UniversalClickHandler;
    isActive?: boolean;
}

const ButtonMoreActions = forwardRef<HTMLButtonElement, ButtonMoreActionsProps>((props, ref) => {
    const { onClick, isActive = false } = props;

    const clickHandler = async () => {
        if (onClick) {
            await onClick();
        }
    };

    return (
        <AppButton
            ref={ref}
            onClick={clickHandler}
            className={classNames(
                "flex items-center justify-center w-10 h-10 bg-white text-special-gray border border-special-gray rounded-2xl",
                isActive && "!border-black !text-black"
            )}
            label=""
        >
            <EllipsisVerticalIcon className="w-fit h-[0.875rem]" />
        </AppButton>
    );
});

export default ButtonMoreActions;
