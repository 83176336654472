import AppButton from "../../common/components/app-button";
import {useNavigate} from "react-router-dom";
import addDigitalProducts from "@assets/images/add_digital_products_2.png";

const OwnerDigitalProductsEmpty = () => {
    const navigate = useNavigate();
    const goToFormProductHandler = () => navigate("../new-product")

    return (
        <section className='w-full flex flex-col items-center justify-center px-12 py-10 gap-5 rounded-3xl
            border border-special-gray border-dashed max-h-full'>
            <header className='text-center space-y-2'>
                <h2 className='font-bold text-[1.75rem] leading-7'>You don't have any products yet</h2>
                <p className='text-special-gray text-xs leading-[0.9rem]'>Add first product, share it to followers and
                    increase income</p>
            </header>
            <AppButton onClick={goToFormProductHandler} label='+ Add product'
                       className='bg-secondary font-semibold text-sm border-0 !py-[10px]'/>
            <img src={addDigitalProducts} alt='add products illustration' className='relative w-[400px] min-w-[300px]'/>
        </section>
    )
}

export default OwnerDigitalProductsEmpty
