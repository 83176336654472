import {LocalStorageKeys} from "../models/enums/local-storage-keys.enum";

class LocalStorageService {
    save(key: LocalStorageKeys, value: any): void {
        localStorage.setItem(key, value);
    }

    get(key: LocalStorageKeys): any | null {
        return localStorage.getItem(key);
    }

    remove(key: LocalStorageKeys): void {
        localStorage.removeItem(key);
    }

    clearAll(): void {
        localStorage.clear();
    }
}

export default new LocalStorageService();
