import {NavLink, useLocation} from "react-router-dom";
import useAuth from "../../auth/hooks/use-auth";
import {classNames} from "../../../utils/class-names";
import {settingsNavigation} from "../../../layout/constants/menu";
import AppHeader from "../../../layout/app-header";
import AppHamburgerMenu from "../../../layout/components/app-hamburger-menu";
import {useMediaQuery} from "react-responsive";
import AppContentWrapper from "../../../layout/components/app-content-wrapper";


const SettingsMenu = () => {
    const location = useLocation();
    const {hasAnyAuthorities} = useAuth();
    const isRoot = location.pathname === '/app/settings';


    const isMain = (): boolean => {
        let pathname = location.pathname;
        return pathname === '/app/settings';
    };

    const determineIsActive = (
        itemHref: string,
        locationPathname: string,
        navigationItems: typeof settingsNavigation
    ): boolean => {

        const matchingNavItems = navigationItems.filter(navItem =>
            locationPathname.startsWith('/app/settings' + navItem.href)
        );

        if (matchingNavItems.length === 0) {
            return false;
        }

        let mostSpecificNavItem = matchingNavItems.reduce((prev, current) =>
            prev.href.length > current.href.length ? prev : current
        );

        return itemHref === mostSpecificNavItem.href;
    };

    const isHiddenMenu = useMediaQuery({maxWidth: 1023});

    return (
        <div className={classNames(
            "flex flex-col flex-grow w-full flex-shrink-[3]",
            !isRoot && "flex-grow-0"
        )}>
            <AppHeader className='!p-0' classNameBgDesktop='md:!rounded-3xl' customContent={true}>
                <div className="flex items-center gap-3 pt-9">
                    {isHiddenMenu && <AppHamburgerMenu/>}
                    <div
                        className="flex items-center text-gray-500 lg:text-[40px] text-primary-darken font-bold text-2xl">
                        Settings
                    </div>
                </div>
            </AppHeader>
            <AppContentWrapper className={classNames(
                'bg-white flex-grow rounded-t-3xl !px-0 !py-4',
                !isRoot && "hidden md:block"
            )}>
                <div className="border-radius">
                    <ul className={`space-y-2 flex-grow ${isMain() ? 'block' : 'hidden'} md:block`}>
                        {settingsNavigation
                            .filter(i => hasAnyAuthorities(i.accessRoles))
                            .map((item, index) => {
                                    const isActive = determineIsActive(item.href, location.pathname, settingsNavigation);
                                    return (
                                        <div key={item.name} className={classNames(
                                            'relative flex justify-between items-center',
                                        )}>
                                            <NavLink
                                                key={item.name}
                                                to={'.' + item.href}
                                                end
                                                className={
                                                    `relative flex justify-between items-center font-semibold 
                                                    hover:bg-gray-100 p-4 w-full rounded-2xl
                                                    ${isActive ? 'a' : 'd'}  
                                                    bg-${isActive ? 'gray-100' : 'inherit'}`}
                                            >
                                                <div className={classNames(
                                                    "flex items-center space-x-3",
                                                    isActive ? 'text-black' : 'text-gray '
                                                )}>
                                                    <item.Icon className="h-5 w-5 min-w-5"/>
                                                    {<span className='text-base whitespace-nowrap'>{item.name}</span>}
                                                </div>
                                            </NavLink>
                                        </div>
                                    )
                                }
                            )}
                    </ul>
                </div>
            </AppContentWrapper>
        </div>
    );
}
export default SettingsMenu;
