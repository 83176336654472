import {useMemo} from "react";
import {StripePrice} from "../../core/models/interfaces/stripe-price.interface";

export interface ProductPriceData {
    stripeData: StripePrice;
    originalPrice: string;
    originalPricePln: string | null;
    previousPrice: string | null;
    lowestPriceOf30Days: string | null;
    pricePerUnit: string;
    hasSavings: boolean;
    savePercent: number | null;
    isBestBuy: boolean;
    isHotPromotion: boolean;
    hasDiscount: boolean;
}

export const useDataStripePrice = (price: StripePrice | null): ProductPriceData | null => {
    return useMemo(() => {
        if (!price) return null;

        const originalPrice = (price.unitAmount / 100).toFixed(2);
        const originalPricePln = price.plnPrice
            ? (price.plnPrice.unitAmount / 100).toFixed(2)
            : null;
        const previousPrice = price.previousUnitAmount
            ? (price.previousUnitAmount / 100).toFixed(2)
            : null;
        const lowestPriceOf30Days = price.lowestPriceOf30Days
            ? (price.lowestPriceOf30Days / 100).toFixed(2)
            : null;
        const pricePerUnit = (price.rate / 100).toFixed(2);
        const hasSavings = !!price.savePercent;
        const isBestBuy = price.bestBuy;
        const hasDiscount = !!price.previousUnitAmount;
        const isHotPromotion = price.hotPromotion && hasDiscount;

        return {
            stripeData: price,
            originalPrice,
            originalPricePln,
            previousPrice,
            lowestPriceOf30Days,
            pricePerUnit,
            hasSavings,
            savePercent: price.savePercent || null,
            isBestBuy,
            isHotPromotion,
            hasDiscount
        };
    }, [price]);
};
