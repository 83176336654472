import React from 'react';
import SendTokenResetPasswordForm from "../../components/send-token-reset-password-form";

interface SendTokenResetPasswordViewProps {
    onSubmit: (token: string, newPassword: string) => Promise<void>
}

const SendTokenResetPasswordView: React.FC<SendTokenResetPasswordViewProps> = (props: SendTokenResetPasswordViewProps) => {
    return (
        <div className='max-h-full flex flex-col h-full md:h-fit'>
            <p className="mb-4 text-sm text-special-gray">
                Write the code you received on your email.
            </p>
            <SendTokenResetPasswordForm onSubmit={props.onSubmit}/>
        </div>
    );
};

export default SendTokenResetPasswordView;
