import DigitalProductDetails from "../components/digital-product-details";
import React, {useEffect, useRef, useState} from "react";
import {
    DigitalProductDetails as IDigitalProductDetails
} from "../../core/models/interfaces/digital-product-details.interface";
import {useParams} from "react-router-dom";
import {useDigitalProductApi} from "../../core/hooks/use-digital-product-api";
import PurchaseProductModal from "../../purchases/components/purchase-product-modal";
import PublicProductActions from "../components/public-product-actions";
import AppWrapper from "../../../layout/components/app-wrapper";
import useAuth from "../../auth/hooks/use-auth";


const PublicDigitalProductDetailsView = () => {
    const [product, setProduct] = useState<IDigitalProductDetails | null>(null);
    const params = useParams();
    const {currentUser} = useAuth();
    const {getDigitalProduct} = useDigitalProductApi();
    const [ownerUserName, setOwnerUserName] = useState<string | null>(null);
    const parentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const aliasName = params['aliasName'];
        const username = params["username"] || currentUser?.username;
        if (username) setOwnerUserName(username);
        if (aliasName && username) getDigitalProduct(aliasName, username).then(res => setProduct(res))
    }, [params]);

    return (
        <AppWrapper
            ref={parentRef}
            className='p-0 text-wrap break-words w-full overflow-y-auto space-y-4 h-full'>
            <DigitalProductDetails
                product={product}
                backEventPath="../"
            />
            <div>
                <PublicProductActions parentRef={parentRef} product={product} creatorUserName={ownerUserName ?? ""}/>
            </div>
            <PurchaseProductModal/>
        </AppWrapper>
    )
}

export default PublicDigitalProductDetailsView
