import NotificationService from "../../common/services/notification.service";
import {AxiosError} from "axios";
import ApiService from "../../../axios/api.service";

class ChangePasswordApiService extends ApiService {
    public async submitChangePassword(formData: { currentPassword: String, newPassword: String }) {
        return this.put<void>('/change-password', formData)
            .then(() => {
                NotificationService.success("Your password was changed.")
            })
            .catch((error: AxiosError) => {
            });
    }
}

export default ChangePasswordApiService;
