import {StepOnBoarding} from '../models/interfaces/step-on-boarding.interface';
import {LocalStorageKeys} from "../../common/models/enums/local-storage-keys.enum";
import useStepper from "../../common/hooks/use-stepper";

const useOnboarding = (steps: StepOnBoarding[]) => {
    const {currentStep, nextStep, resetSteps, isLastStep, goToStep} = useStepper({
        initialStep: 0,
        maxSteps: steps.length,
        storageKey: LocalStorageKeys.LAST_STEP_ONBOARDING,
    });

    const stepData: StepOnBoarding = steps[currentStep];

    return {
        currentStep,
        stepData,
        isLastStep,
        nextStep,
        resetOnboarding: resetSteps,
        goToStep
    };
};

export default useOnboarding;
