import {useContext} from "react";
import {WalletContext} from "../../contexts/wallet-context";
import OfferPriceCard from "../offer-price-card";
import PurchaseYcModal from "../purchase-yc-modal";

const OffersBuyCredits = () => {
    const {prices} = useContext(WalletContext)!;

    const sortedPrices = [...prices].sort((a, b) => {
        return a.coins - b.coins;
    });

    return (
        <div className="space-y-2">
            {sortedPrices
                .map((price) => (
                <OfferPriceCard key={price.id} price={price}/>
            ))}
            <PurchaseYcModal/>
        </div>
    )
}

export default OffersBuyCredits
