import React from "react";
import AppButton from "../../common/components/app-button";
import {classNames} from "../../../utils/class-names";

interface SendMessageToFanButtonProps {
    disabled: boolean;
}

const SendMessageToFanButton = (props: SendMessageToFanButtonProps) => {
    const {
        disabled = false,
    } = props
    //TODO handle this action
    const handleClick = async (event?: React.MouseEvent) => {

    };

    return (
        <div className="text-center">
            <AppButton
                className={classNames(
                    "w-full text-white text-sm py-[9px] h-fit rounded-lg font-semibold bg-primary",
                )}
                onClick={() => handleClick()}
                label='Send message'
                disabled={disabled}
            />
        </div>
    );
}

export default SendMessageToFanButton
