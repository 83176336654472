import React from 'react';
import useAuth from "../hooks/use-auth";
import {Navigate, Outlet , RouteProps} from "react-router-dom"
import {useRedirect} from "../hooks/use-redirect";

const GuardAuthRoutes = (props: RouteProps) => {
    const { isAuthenticated } = useAuth();
    const { intendedRoute } = useRedirect();

    if (isAuthenticated) {
        const redirectTo = intendedRoute || '/app';
        return <Navigate to={redirectTo} />;
    }

    return <Outlet />;
};
export default GuardAuthRoutes;
