import React from "react";

enum State {
    PREVIOUS,NEXT,ACTIVE
}

interface StepDotProps {
    state : State
}

const StepDot = (props : StepDotProps) => {
    const {state} = props;

    return (
        <>
            <div className={`
                ${state ==  State.PREVIOUS ? 'bg-primary rounded-full w-1.5 h-1.5 p-[1px] border-solid border-2 border-transparent' : ''}
                ${state ==  State.NEXT ? 'bg-primary-pastel rounded-full w-1.5 h-1.5 p-[1px] border-solid border-2 border-transparent' : ''}
                ${state ==  State.ACTIVE ? 'bg-white rounded-full w-2.5 h-2.5 border-solid border-[3px] border-primary' : ''} 
                `}></div>
        </>
    )
}

export default StepDot;
export {State as StepDotState};