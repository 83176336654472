import {Channel, MessageInput, MessageList, Thread, Window} from "stream-chat-react";
import {useActiveChat} from "../../hooks/use-active-chat";
import {classNames} from "../../../../utils/class-names";
import CustomChannelHeader from "../custom-channel-header";

const ActiveChat = () => {
    const {activeChannel} = useActiveChat();

    return (
        <div className={classNames(
            "md:static flex-grow h-full w-full bg-gray-light p-4 rounded-3xl",
            activeChannel && "absolute left-0 top-0 z-10 max-h-svh"
        )}>
            {activeChannel ? (
                <Channel channel={activeChannel}>
                    <Window>
                        <CustomChannelHeader/>
                        <MessageList/>
                        <MessageInput/>
                    </Window>
                    <div
                        className='absolute bottom-0 left-0 w-full shadow-2xl border-[5px] rounded-lg border-primary-pastel'>
                        <Thread/>
                    </div>
                </Channel>
            ) : (
                <div className="flex items-center justify-center h-full text-gray-500">
                    Select a chat to start messaging
                </div>
            )}
        </div>
    );
};

export default ActiveChat;
