import React from "react";

interface BreadcrumbsProps {
    path: string
}

const Breadcrumbs = ({path}: BreadcrumbsProps) => {
    const pathParts = path.split('/').filter(part => part);
    const breadcrumbText = pathParts[0]?.charAt(0).toUpperCase() + pathParts[0]?.slice(1);

    if (!breadcrumbText) return null;

    return <span className={'font-bold text-2xl lg:text-[40px] text-primary-darken'}>{breadcrumbText}</span>
}

export default Breadcrumbs
