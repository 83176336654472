import { useState } from 'react';
import ChatApiService from "../services/chat-api.service";

export const useChatApi = () => {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const createChannel = async (creatorUsername: string): Promise<string> => {
        setLoading(true);
        setError(null);
        try {
            const response = await ChatApiService.createChannel(creatorUsername);
            return response.channelId;
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return {
        createChannel,
        error,
        loading,
    };
};
