import {ModalPosition} from "../models/enums/modal-positions.enum";
import React from "react";

export const getPositionCSS = (pos: ModalPosition): React.CSSProperties => {
    switch (pos) {
        case ModalPosition.TOP:
            return {
                top: "0",
                left: "50%",
                transform: "translateX(-50%)",
            };
        case ModalPosition.BOTTOM:
            return {
                bottom: "0",
                left: "50%",
                transform: "translateX(-50%)",
            };
        case ModalPosition.CENTER:
            return {
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
            };
        case ModalPosition.LEFT:
            return {
                left: "0",
                top: "50%",
                transform: "translateY(-50%)",
            };
        case ModalPosition.RIGHT:
            return {
                right: "0",
                top: "50%",
                transform: "translateY(-50%)",
            };
        case ModalPosition.TOP_CENTER:
            return {
                top: "0",
                left: "50%",
                transform: "translateX(-50%)",
            };
        case ModalPosition.BOTTOM_CENTER:
            return {
                bottom: "0",
                left: "50%",
                transform: "translateX(-50%)",
            };
        case ModalPosition.LEFT_CENTER:
            return {
                left: "0",
                top: "50%",
                transform: "translateY(-50%)",
            };
        case ModalPosition.RIGHT_CENTER:
            return {
                right: "0",
                top: "50%",
                transform: "translateY(-50%)",
            };
        case ModalPosition.TOP_LEFT:
            return {
                top: "0",
                left: "0",
            };
        case ModalPosition.TOP_RIGHT:
            return {
                top: "0",
                right: "0",
            };
        case ModalPosition.BOTTOM_LEFT:
            return {
                bottom: "0",
                left: "0",
            };
        case ModalPosition.BOTTOM_RIGHT:
            return {
                bottom: "0",
                right: "0",
            };
    }
};
