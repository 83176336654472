import React, {createContext, ReactNode, useCallback, useEffect, useState} from 'react';
import { Channel as StreamChannel } from "stream-chat";
import LocalStorageService from "../../common/services/local-storage.service";
import { LocalStorageKeys } from "../../common/models/enums/local-storage-keys.enum";
import { useChatContext } from "stream-chat-react";
import StreamChatService from "../services/stream-chat.service";
import useAuth from "../../auth/hooks/use-auth";
import { useUserProfileApi } from "../../core/hooks/use-user-profile-api";
import { UserAuthority } from "../../auth/models/enums/user-authority.enum";

interface ActiveChatContextType {
    activeChannel: StreamChannel | null;
    setActiveChannel: (channel: StreamChannel | null) => void;
    isChatWithCC: boolean | null;
}

export const ActiveChatContext = createContext<ActiveChatContextType | undefined>(undefined);

export const ActiveChatProvider = ({ children }: { children: ReactNode }) => {
    const [activeChannel, setActiveChannel] = useState<StreamChannel | null>(null);
    const [isChatWithCC, setIsChatWithCC] = useState<boolean | null>(null);
    const { client } = useChatContext();
    const { currentUser } = useAuth();
    const { checkTypeUser } = useUserProfileApi();

    const handleSetActiveChannel = (channel: StreamChannel | null) => {
        setActiveChannel(channel);
        if (channel) {
            LocalStorageService.save(LocalStorageKeys.ID_ACTIVE_CHAT, channel.id);
            checkIfActiveChatUserIsCC(channel);
        } else {
            LocalStorageService.remove(LocalStorageKeys.ID_ACTIVE_CHAT);
            setIsChatWithCC(null);
        }
    };

    const checkIfActiveChatUserIsCC = useCallback(async (channel: StreamChannel) => {
        await channel.watch();
        const loggedInUserId = currentUser?.id;
        const otherUser = Object.values(channel.state.members).find(
            member => member.user?.id !== loggedInUserId
        )?.user;

        if (otherUser) {
            try {
                const typeUser = await checkTypeUser(otherUser.name ?? "");
                setIsChatWithCC(typeUser === UserAuthority.CONTENT_CREATOR);
            } catch (error) {
                console.error("Failed to check if user is CC:", error);
                setIsChatWithCC(null);
            }
        } else {
            console.log("No other user found in channel.");
            setIsChatWithCC(null);
        }
    }, [currentUser, checkTypeUser]);

    useEffect(() => {
        const initializeClientAndChannel = async () => {
            if (currentUser) {
                await StreamChatService.connectUser(currentUser);

                StreamChatService.onClientReady(async () => {
                    const storedChannelId = LocalStorageService.get(LocalStorageKeys.ID_ACTIVE_CHAT);
                    if (storedChannelId && client) {
                        const channel = client.channel('messaging', storedChannelId);
                        await channel.watch();
                        setActiveChannel(channel);
                    }
                });
            }
        };

        initializeClientAndChannel();
    }, [client, currentUser]);

    useEffect(() => {
        if (activeChannel) {
            checkIfActiveChatUserIsCC(activeChannel);
        }
    }, [activeChannel]);

    return (
        <ActiveChatContext.Provider value={{ activeChannel, setActiveChannel: handleSetActiveChannel, isChatWithCC }}>
            {children}
        </ActiveChatContext.Provider>
    );
};
