import ApiService from "../../../axios/api.service";
import {DictionaryEntry} from "../models/interfaces/dictionary-entry.interface";

class DictionaryService extends ApiService {

    public async getGenders(): Promise<DictionaryEntry[]> {
        return await this.get<DictionaryEntry[]>(`/users/dictionary/gender`, {});
    }

    public async getCountries(name?: string): Promise<DictionaryEntry[]> {
        const params = name ? { name } : {};
        return await this.get<DictionaryEntry[]>(`/users/dictionary/countries`, { params });
    }
}

export default new DictionaryService()


