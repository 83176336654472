import React from 'react';
import {Link} from 'react-router-dom';
import {NavigationFanProfile} from "../constants/navigation-c-c-profile";

const ProfileFanMenu = () => {

    return (
        <nav className="flex px-4 py-3 overflow-x-auto overflow-y-clip h-fit">
            {NavigationFanProfile.map((item: any) => (
                    <Link
                        key={item.name}
                        to={`${item.link}`}
                        className={
                            item.strictActive
                                ? 'border-b-2 border-black text-black text-center flex-grow text-sm font-semibold px-4 py-2 text-nowrap'
                                : 'text-special-gray border-b flex-grow text-center border-special-gray text-sm font-semibold px-3 py-2 text-nowrap'
                        }
                    >
                        {item.name}
                    </Link>
                )
            )}
        </nav>
    );
};

export default ProfileFanMenu;
