import ApiService from "../../../axios/api.service";
import { CCAppNotificationDto, CCEmailNotificationDto, FanAppNotificationDto, FanEmailNotificationDto } from "./notification-dto";


class NotificationsService extends ApiService {

    public getFanAppNotifications() : Promise<FanAppNotificationDto>  {
        return this.get<FanAppNotificationDto>("/users/app/fan")
    }

    public updateFanAppNotifications(body: FanAppNotificationDto)  {
        return this.post("/users/notifications/app/fan", body)
    }

    public getFanEmailNotifications() : Promise<FanEmailNotificationDto>  {
        return this.get<FanEmailNotificationDto>("/users/email/fan")
    }

    public updateFanEmailNotifications(body: FanEmailNotificationDto)  {
        return this.post("/users/notifications/email/fan", body)
    }

    public getCCAppNotifications() : Promise<CCAppNotificationDto>  {
        return this.get<CCAppNotificationDto>("/users/app/cc")
    }

    public updateCCAppNotifications(body: CCAppNotificationDto)  {
        return this.post("/users/notifications/app/cc", body)
    }

    public getCCEmailNotifications() : Promise<CCEmailNotificationDto>  {
        return this.get<CCEmailNotificationDto>("/users/email/cc")
    }

    public updateCCEmailNotifications(body: CCEmailNotificationDto)  {
        return this.post("/users/notifications/email/cc", body)
    }

}
export default new NotificationsService;