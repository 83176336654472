import React, {useEffect, useState} from 'react';
import {useFormContext} from "react-hook-form";
import {classNames} from "../../../../utils/class-names";

interface SwitcherFormFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label?: string;
    className?: string;
    showError?: boolean;
    onToggle?: ((checked: boolean, name?: any) => void);
}

const SwitcherFormField: React.FC<SwitcherFormFieldProps> = (props: SwitcherFormFieldProps) => {
    const {register, formState: {errors, isSubmitting}, getValues, watch, setValue} = useFormContext();
    const {
        name,
        className = '',
        disabled,
        showError,
        onToggle,
        ...rest
    } = props;

    const isDisabled = disabled !== undefined ? disabled : isSubmitting;
    const [checked, setChecked] = useState(rest.checked || false);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newChecked = e.target.checked;
        setChecked(newChecked);
        if (onToggle) {
            onToggle(newChecked, name);
        }
    };

    useEffect(() => {
        setChecked(getValues(name));
    }, [getValues, getValues(name)]);

    return (
        <div className="cursor-pointer">
            <label htmlFor={name} className="flex items-center gap-2 cursor-pointer">
                <div className="relative">
                    <input
                        type="checkbox"
                        id={name}
                        className="sr-only"
                        disabled={isDisabled}
                        checked={checked}
                        {...register(name, {
                            onChange: handleOnChange,
                        })}
                        {...rest}
                    />

                    <div
                        className={classNames(
                            "block w-[3.75rem] h-8 rounded-full transition-colors duration-200",
                            checked && !isDisabled? "bg-semantic-success" : "bg-gray-light",
                            isDisabled ? "bg-gray-300" : "",
                        )}
                    />
                    <div
                        className={classNames(
                            "absolute top-1 w-6 h-6 rounded-full bg-white transition duration-200",
                            checked ? "transform translate-x-8" : "transform translate-x-1",
                            isDisabled ? "bg-gray-300" : "bg-white"
                        )}
                    />
                </div>
                {props.label && <span className="text-gray-700 theme-dark:text-gray-50">{props.label}</span>}
            </label>
            {showError && errors[name] && <p className="text-red-500 text-xs">{'' + errors[name]?.message}</p>}
        </div>
    );
};

export default SwitcherFormField;
