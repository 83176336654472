import ListTransactionHistory from "../components/containers/list-transaction-history";
import {ReactComponent as HistoryIcon} from "../../../assets/icons/history.svg";
import AppHamburgerMenu from "../../../layout/components/app-hamburger-menu";
import Breadcrumbs from "../../../layout/components/breadcrumbs";
import WalletMenu from "../components/wallet-menu";
import YourBalanceWithWithdrawCard from "../components/your-balance-with-withdraw-card";
import AppHeader from "../../../layout/app-header";
import React, {useContext} from "react";
import AppContentWrapper from "../../../layout/components/app-content-wrapper";
import {WalletContext} from "../contexts/wallet-context";
import {useLocation} from "react-router-dom";

const TransactionsHistoryView = () => {
    const {balance} = useContext(WalletContext)!;
    const location = useLocation();

    return (
        <>
            <AppHeader customContent={true}>
                <div className='flex justify-between flex-wrap gap-5'>
                    <div className='flex flex-col gap-5'>
                        <div className='flex items-center w-fit'>
                            <div className='mr-3'><AppHamburgerMenu/></div>
                            <Breadcrumbs path='Wallet'/>
                        </div>
                        <WalletMenu/>
                    </div>
                    <YourBalanceWithWithdrawCard balance={balance || 0}/>
                </div>
            </AppHeader>
            <AppContentWrapper className='p-4 bg-white rounded-t-[1.5rem] pt-4 sm:pt-9'>
                <section className='h-full flex flex-col'>
                    <header className='flex gap-2 items-center mb-4'>
                        <HistoryIcon className='w-5 h-5'/>
                        <h3 className='font-bold text-[1.125rem] leading-5'>Transactions history</h3>
                    </header>
                    <ListTransactionHistory/>
                </section>
            </AppContentWrapper>
        </>
    )
}

export default TransactionsHistoryView
