import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {PwaInstallProvider} from "./features/common/contexts/pwa-install-context";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <PwaInstallProvider>
          <App />
      </PwaInstallProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();
