import {TransactionSection} from "../models/interfaces/transaction-section.interface";
import {Transaction} from "../../core/models/interfaces/transaction.interface";

export const mapTransactionsToSections = (transactions: Transaction[]): TransactionSection[] => {
    const sortedTransactions = transactions.sort((a, b) => new Date(b.txnDate).getTime() - new Date(a.txnDate).getTime());

    const sections: { [key: string]: Transaction[] } = {};

    sortedTransactions.forEach((transaction) => {
        const date = new Date(transaction.txnDate);  // Użycie txnDate z nowego interfejsu
        const now = new Date();

        let label = '';
        const diffDays = (now.getTime() - date.getTime()) / (1000 * 3600 * 24);

        if (diffDays < 1) {
            label = 'Yesterday';
        } else if (diffDays < 3) {
            label = '3 days ago';
        } else {
            label = `${Math.floor(diffDays)} days ago`;
        }

        if (!sections[label]) {
            sections[label] = [];
        }

        sections[label].push(transaction);
    });

    return Object.keys(sections).map((key) => ({
        label: key,
        items: sections[key]
    }));
};
