import {SpecialOfferType} from "../models/enums/special-offer-type.enum";
import {FC} from "react";
import {classNames} from "../../../utils/class-names";

interface SpecialOfferLabelProps {
    offerType: SpecialOfferType;
    isVisible: boolean;
    savings?: number | string;
}

const SpecialOfferLabel: FC<SpecialOfferLabelProps> = (props: SpecialOfferLabelProps) => {
    const {offerType, isVisible, savings} = props;

    if (!isVisible) return null;
    const defaultClassName = "w-fit h-fit rounded-full text-xs font-semibold px-2 py-1 "

    switch (offerType) {
        case SpecialOfferType.BEST_BUY:
            return (
                <div className={classNames(defaultClassName, "bg-secondary")}>
                    Best buy
                </div>
            );
        case SpecialOfferType.HOT_PROMOTION:
            return (
                <div className={classNames(defaultClassName, "relative bg-white text-semantic-danger overflow-clip")}>
                    <div className='absolute inset-0 bg-semantic-danger-pastel bg-blend-multiply'></div>
                    Hot promotion
                </div>
            );
        case SpecialOfferType.SAVINGS:
            if (!savings) console.error("Component SpecialOfferLabel need percentageDiscount property")
            return (
                <div className={classNames(defaultClassName, "relative bg-white text-semantic-success overflow-clip")}>
                    <div className='absolute inset-0 bg-semantic-success-pastel bg-blend-multiply'></div>
                    {`Save ${savings}%`}
                </div>
            );
        default:
            return null;
    }
};

export default SpecialOfferLabel;
