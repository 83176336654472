import React from 'react';
import AppHeader from "../layout/app-header";
import AppContentWrapper from "../layout/components/app-content-wrapper";
import withAnyProvider from "../features/common/hoc/with-any-provider";
import {ModalProvider} from "../features/common/contexts/modal-context";
import AppHamburgerMenu from "../layout/components/app-hamburger-menu";
import {useLocation} from "react-router-dom";
import useAuth from "../features/auth/hooks/use-auth";
import PromptInstallPwa from "../features/core/components/prompt-install-pwa";
import Logo from "../features/core/components/ui/logo";

const HomePage = () => {
    const location = useLocation();
    const {currentUser} = useAuth();

    return (
        <>
            <AppHeader customContent={true}>
                <div>
                    <div className='flex items-center gap-3 w-fit'>
                        <AppHamburgerMenu/>
                        <div
                            className='flex items-center text-gray-500 lg:text-[40px] text-primary-darken font-bold text-2xl'>
                            {location.pathname === '/app' && `Hi, ${currentUser?.firstname}`}
                        </div>
                    </div>
                    {/*<div className='w-full py-5 relative z-[3]'>*/}
                    {/*    <div className='flex gap-3 overflow-x-auto'>*/}
                    {/*        <div className='w-1/4 min-w-fit'>*/}
                    {/*            <YourBalanceCard balance={300}/>*/}
                    {/*        </div>*/}
                    {/*        <div className='w-1/4 min-w-[150px] h-20 bg-orange-500 rounded-3xl'></div>*/}
                    {/*        <div className='w-1/4 min-w-[150px] h-20 bg-orange-500 rounded-3xl'></div>*/}
                    {/*        <div className='w-1/4 min-w-[150px] h-20 bg-orange-500 rounded-3xl'></div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </AppHeader>
            <AppContentWrapper className='p-4 bg-white rounded-t-[1.5rem] pt-9'>
                <header className='h-full flex items-center justify-center relative -top-[50px]'>
                    <h2 className='text-2xl font-semibold text-center'>
                        Welcome
                        <br></br>
                        in
                        <br></br>
                        <Logo type='complete' className='w-[100px] relative top-[-6px]'/>
                    </h2>
                </header>
                <PromptInstallPwa/>
            </AppContentWrapper>
        </>
    );
};

export default withAnyProvider(ModalProvider, HomePage);
