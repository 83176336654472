import { ProfileImageDto } from "../models/dto/profileImageDto";
import { ProfileImageRepository } from "../repositories/profile-image-repository"

export class ProfileImageService {

    private repo = new ProfileImageRepository()

    public async saveImage(value: string) {
        const val : Omit<ProfileImageDto, ''> = {
            id: 1,
            imageValue: value
        }
        this.getImage()
        .then(async ()=> {
            console.log("updated")
            await this.repo.updateEntity(val);
        })
        .catch(async e => {
            console.log("created")
            await this.repo.createEntity(val);
        })
    }

    public async getImage() {
        return await this.repo.getAllEntities().then(res =>  res.length ? res[0] : null);
    }

    public async updateImage(value: string) {
        const val : ProfileImageDto = {
            id: 1,
            imageValue: value
        }
        await this.repo.updateEntity(val);
    }

    public async deleteImage() {
        const image = await this.repo.getAllEntities().then(res => res.length ? res[0] : null);
        if (image){
            await this.repo.deleteEntity(image.id)
        }
    }
}
export default new ProfileImageService();
