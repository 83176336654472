import React, {useEffect, useRef, useState} from "react";
import {usePaymentMethod} from "../hooks/use-payment-method";
import AppButton from "../../common/components/app-button";
import {classNames} from "../../../utils/class-names";
import {PaymentMethodType} from "../../core/models/enums/payment-method-type.enum";
import {Case, Default, Switch} from "../../common/components/switch-case";
import VisaCard from "./ui/visa-card";
import Transfers24 from "./ui/transfers-24";
import Blik from "./ui/blik";
import PayPal from "./ui/pay-pal";
import Skrill from "./ui/skrill";
import PreviewOtherPaymentMethod from "./preview-other-payment-method";
import RenderPaymentMethodIcon from "./ui/render-payment-method-icon";

interface PaymentMethodSelectorProps {
    excludedMethods?: PaymentMethodType[]
}

const PaymentMethodSelector: React.FC<PaymentMethodSelectorProps> = (props: PaymentMethodSelectorProps) => {
    const {selectedMethod, setPaymentMethod} = usePaymentMethod();
    const [showOtherOptions, setShowOtherOptions] = useState(false);
    const [maxVisibleOptions, setMaxVisibleOptions] = useState(3);
    const {excludedMethods} = props
    const containerRef = useRef<HTMLDivElement>(null);

    const options: any[] = [
        PaymentMethodType.CARD,
        PaymentMethodType.P24,
        PaymentMethodType.BLIK,
        PaymentMethodType.PAYPAL,
        // PaymentMethodType.SKRILL,
    ];

    const visibleOptions = options.slice(0, maxVisibleOptions);
    const otherOptions = options.slice(maxVisibleOptions);

    const handleResize = () => {
        if (containerRef.current) {
            const containerWidth = containerRef.current.offsetWidth;
            const buttonWidth = 90;
            const gapWidth = 8;

            const totalElementWidth = buttonWidth + gapWidth;
            const maxItems = Math.floor(containerWidth / totalElementWidth);

            if (options.length > maxItems) {
                setMaxVisibleOptions(maxItems - 1);
            } else {
                setMaxVisibleOptions(options.length);
            }
        }
    };


    useEffect(() => {
        const resizeObserver = new ResizeObserver(handleResize);

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        handleResize();

        return () => {
            if (containerRef.current) {
                resizeObserver.unobserve(containerRef.current);
            }
        };
    }, []);

    const handleOtherClick = () => {
        setShowOtherOptions((prev) => !prev);
        setPaymentMethod(null);
    };

    const clickVisibleOptionHandler = (option: PaymentMethodType) => {
        setPaymentMethod(option);
        if (visibleOptions.includes(option)) setShowOtherOptions(false);
    };

    return (
        <div className="space-y-2">
            <h3 className="font-semibold text-base leading-[1.2rem]">
                Choose payment method
            </h3>
            <div
                ref={containerRef}
                className="w-full p-1 flex bg-gray-light rounded-2xl h-[78px] mb-4 gap-1"
            >
                {visibleOptions.filter(el => !excludedMethods?.includes(el)).map((option) => (
                    <AppButton
                        key={option}
                        type="button"
                        onClick={() => clickVisibleOptionHandler(option)}
                        className={classNames(
                            "w-full border-none h-full !p-0 text-special-gray text-[0.8135rem] focus:outline-none " +
                            "focus:ring-2 focus:ring-primary min-w-[90px]",
                            selectedMethod === option &&
                            "bg-white font-semibold !text-black drop-shadow-xl"
                        )}
                        label=""
                    >
                        <Switch value={option}>
                            <Case case={PaymentMethodType.CARD}><VisaCard
                                isSelected={selectedMethod === option}/></Case>
                            <Case case={PaymentMethodType.P24}><Transfers24
                                isSelected={selectedMethod === option}/></Case>
                            <Case case={PaymentMethodType.BLIK}><Blik isSelected={selectedMethod === option}/></Case>
                            <Case case={PaymentMethodType.PAYPAL}><PayPal
                                isSelected={selectedMethod === option}/></Case>
                            <Case case={PaymentMethodType.SKRILL}><Skrill
                                isSelected={selectedMethod === option}/></Case>
                            <Default><span>Unsupported payment</span></Default>
                        </Switch>
                    </AppButton>
                ))}

                {otherOptions.length > 0 && (
                    <AppButton
                        type="button"
                        onClick={handleOtherClick}
                        className={classNames(
                            "w-full border-none h-full !p-0 text-special-gray text-[0.8135rem] focus:outline-none " +
                            "focus:ring-2 focus:ring-primary min-w-[90px] max-w-[108px]",
                            showOtherOptions && "bg-white font-semibold !text-black drop-shadow-xl"
                        )}
                        label=""
                    >
                        <div className="flex flex-col gap-2 items-center justify-center">
                            <div className="flex gap-2 items-center justify-center">
                                {otherOptions.slice(0, 2).map((option, index) => (
                                    <RenderPaymentMethodIcon key={index} option={option}/>
                                ))}
                            </div>
                            <span className="font-medium text-[0.8125rem] leading-[0.975rem]">
                                Other
                            </span>
                        </div>
                    </AppButton>
                )}
            </div>

            {showOtherOptions && (
                <div className="pt-4 space-y-4">
                    <h4 className="font-semibold text-base leading-[1.2rem]">Other payment methods</h4>
                    <div className="flex flex-col gap-4">
                        {otherOptions.map((option) => (
                            <PreviewOtherPaymentMethod
                                onSelect={() => setPaymentMethod(option)}
                                option={option}
                                isSelected={selectedMethod === option}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default PaymentMethodSelector;
