export const switchMap = async <T, R>(
    previousRequest: () => Promise<T>,
    nextRequest: (result: T) => Promise<R>
): Promise<R> => {
    try {
        const result = await previousRequest();
        return await nextRequest(result);
    } catch (error) {
        throw error;
    }
};
