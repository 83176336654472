import {ReactComponent as MobilePushIcon} from "@assets/icons/mobile-push.svg";
import {ReactComponent as CrossIcon} from "@assets/icons/cross.svg";
import AppButton from "../../common/components/app-button";
import {usePwaInstallFlow} from "../../common/hooks/use-pwa-install-flow";
import {useEffect, useState} from "react";
import Coin from "./ui/coin";

const PromptInstallPwa = () => {
    const [showPrompt, setShowPrompt] = useState(true);
    const {installPWA, isInstalled} = usePwaInstallFlow();

    const handleClosePrompt = () => setShowPrompt(false);

    useEffect(() => {

    }, [isInstalled]);

    if (isInstalled || !showPrompt) return null;

    return (
        <div className='absolute bottom-[15px] left-1/2 -translate-x-1/2 w-full px-5 overflow-clip py-1'>
            <div
                className='max-w-[800px] w-full bg-gray-light flex flex-wrap justify-between gap-3 flex-grow rounded-2xl
                p-3 shadow-md fade-slide-up opacity-0'>
                <header className='flex gap-2 items-center'>
                    <MobilePushIcon className='min-w-5 w-5 h-5 text-black inline-block'/>
                    <div className='flex flex-col'>
                        <h3 className='font-semibold text-base leading-5'>Get better experience</h3>
                        <div className='h-fit leading-5'>
                            <span className='inline-block text-gray text-sm leading-5'>Get extra 20</span>
                            <span className='inline-block text-gray text-[0.95rem] leading-5 relative top-[3px] mx-[2px]'><Coin/></span>
                            <span className='inline-block text-gray text-sm leading-5'>for first top-up</span>
                        </div>
                    </div>
                </header>
                <div className='flex justify-end items-center space-x-3'>
                    <AppButton
                        onClick={installPWA}
                        label='Download mobile app'
                        className='bg-primary-pastel text-primary !rounded-2xl font-semibold text-sm py-[9px] transition
                     hover:text-white hover:bg-primary focus:text-white focus:bg-primary'/>
                    <AppButton
                        onClick={handleClosePrompt}
                        label=''
                        className='!p-[7px] bg-white !rounded-full w-8 h-8 text-special-gray hover:text-primary focus:text-primary transition'>
                        <CrossIcon className='w-2 h-2 min-w-2'/>
                    </AppButton>
                </div>
            </div>
        </div>
    )
}

export default PromptInstallPwa
