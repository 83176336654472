import * as yup from "yup";

export const schemaAccountDetailsPartOne = yup.object({
    username: yup
        .string()
        .matches(/^[a-zA-Z0-9$_.-]*$/, "Use only letters, numbers, and special signs ($, _, .)")
        .required("Username is required"),

    birthDate: yup
        .date()
        .typeError("Invalid date format")
        .max(new Date(), "Date of birth must be in the past")
        .required("Date of birth is required"),

    roleId: yup
        .number()
        .required("Account type is required"),
}).required();

export const schemaAccountDetailsPartTwo = yup.object({
    contactNumber: yup
        .string()
        .matches(/^[0-9+() -]*$/, "Enter a valid phone number")
        .required("Phone number is required"),

    genderId: yup
        .number()
        .required("Gender is required"),

    firstname: yup
        .string()
        .min(3, "Name must be at least 3 characters")
        .max(255, "Name cannot exceed 255 characters")
        .required("Name is required"),

    lastname: yup
        .string()
        .min(3, "Surname must be at least 3 characters")
        .max(255, "Surname cannot exceed 255 characters")
        .required("Surname is required"),

    countryId: yup
        .number()
        .typeError('Country is required')
        .required("Country is required"),
}).required();
