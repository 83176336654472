import {FormProvider, useForm} from "react-hook-form";
import PasswordFormField from "../../common/components/forms/password-form-field";
import ChangePasswordApiService from "../../core/services/change-password-api-service";
import AppButton from "../../common/components/app-button";
import {yupResolver} from "@hookform/resolvers/yup";
import {SchemaChangePassword} from "../constants/schema-change-password";

type FormValues = {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
}

const ChangePasswordForm = () => {
    const methods = useForm<FormValues>({
        resolver: yupResolver(SchemaChangePassword),
        mode: "onSubmit"
    });
    const service = new ChangePasswordApiService();

    const sendRequest = async () => {
        return await methods.handleSubmit(async (values) => {
            const requestBody = {
                currentPassword: values.currentPassword,
                newPassword: values.newPassword
            };
            await service.submitChangePassword(requestBody)
        })();
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(sendRequest)} className='max-w-[400px] mx-auto space-y-5'>
                <PasswordFormField name="currentPassword" label="Current password"/>
                <PasswordFormField name="newPassword" label="New Password"/>
                <PasswordFormField name="confirmPassword" label="Confirm password"/>
                <AppButton
                    type='submit'
                    className='w-full bg-primary text-white text-sm font-semibold !rounded-2xl h-fit py-[9px]'
                    label='Change password'
                />
            </form>
        </FormProvider>
    );
}

export default ChangePasswordForm;
