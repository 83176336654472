import { ErrorHandler, ErrorHandlers, StatusCode } from "../../core/models/types/error-handler.type";
import { AxiosError } from "axios";
import { ValidationErrors } from "../models/interfaces/validation-errors";
import AuthService from "../../auth/services/auth.service";
import NotificationService from "./notification.service";
import { CustomAxiosRequestConfig } from "../../../axios/api.service";
import ValidationError from "./validation-error.service";

class ErrorHandling {
    public handlers: ErrorHandlers = {};
    public validationErrorHandler: ValidationError | null = null;

    constructor() {
        this.addDefaultHandlers();
    }

    public handle(error: AxiosError): void {
        const status = error.response?.status;
        if (status && this.shouldUseCustomHandler(status, error)) {
            this.handlers[status](error);
        } else {
            this.handleDefault(error);
        }
    }

    public handleDefault(error: AxiosError): void {
        const customMessage = (error.config as CustomAxiosRequestConfig)?.errorMessage;
        const defaultErrorMessage: string = customMessage || "An unexpected error occurred.";
        NotificationService.error(defaultErrorMessage, error);
        console.error('Default error handling:', error);
    }

    public shouldUseCustomHandler(status: StatusCode, error: AxiosError): boolean {
        const customHandlingConfig = {
            404: 'skipDefault404Handling',
            401: 'skipDefault401Handling'
        };

        const skipConfigKey = customHandlingConfig[status as keyof typeof customHandlingConfig];
        const shouldSkip = !!(error.response?.config as any)?.[skipConfigKey]
        return skipConfigKey ? !shouldSkip : false;
    }

    private addHandler(statusCode: StatusCode, handler: ErrorHandler): void {
        this.handlers[statusCode] = handler;
    }

    private addDefaultHandlers(): void {
        this.addHandler(401, this.handleUnauthorized);
        this.addHandler(404, this.handleNotFound);
        this.addHandler(422, this.handleValidationError);
    }

    private handleUnauthorized = (): void => {
        AuthService.logout();
        NotificationService.error("Session expired. Please log in again.");
    }

    private handleNotFound = (): void => {
        window.location.href = '/not-found';
    }

    private handleValidationError = (error: AxiosError): void => {
        if (this.validationErrorHandler && error.response) {
            const errors = error.response.data as ValidationErrors;
            this.setValidationErrorHandler(new ValidationError(errors));
        }
    }

    private setValidationErrorHandler(handler: ValidationError): void {
        this.validationErrorHandler = handler;
    }
}

const errorHandling = new ErrorHandling();
export default errorHandling;
