import React from "react";

interface CircleImgProps {
    img: string,
    className?: string
}

const CircleImg = (props: CircleImgProps) => {
    const {
        img,
        className = '',
    } = props;

    return (
        <div className={'w-[48px] h-[48px] rounded-[50%] border-[3px] border-white overflow-hidden inline-flex ' + className}>
            <img src={img} alt='img' className="object-fill "/>
        </div>
    );

}
export default CircleImg;