import {UserIcon} from "@heroicons/react/24/solid";
import React from "react";

const DefaultProfileImg = () => {
    return (
        <span className='w-full h-full bg-gray-300 flex justify-center items-center'>
            <UserIcon className='text-special-gray w-3/5'/>
        </span>
    )
}

export default DefaultProfileImg
