import React, {useEffect, useRef} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import AppWrapper from "./components/app-wrapper";
import AppSidebarWrapper from "./components/app-sidebar-wrapper";
import {SidebarProvider} from "./contexts/sidebar-context";
import {SidebarIds} from "./types/sidebar-ids.interface";
import AppMenu from "./app-menu";
import WalletPage from "../pages/wallet-page";
import HomePage from "../pages/home-page";
import AppTopBarAnnouncement from "./components/app-top-bar-announcement";
import ProfilePage from "../pages/profile-page";
import MessagesPage from "../pages/messages-page";
import CreatorToolsPage from "../pages/creator-tools-page";
import SettingsPage from "../pages/settings-page";
import {UserAuthority} from "../features/auth/models/enums/user-authority.enum";
import GuardRestrictedRoute from "../features/auth/components/guard-restricted-route";
import useAuth from "../features/auth/hooks/use-auth";
import StreamChatService from "../features/chat/services/stream-chat.service";


const MainLayer = () => {
    const {currentUser} = useAuth();
    const isConnectedRef = useRef(false);

    const handleInitChat = () => {
        if (!isConnectedRef.current) {
            StreamChatService.connectUser(currentUser!).then(() => {
                isConnectedRef.current = true;
            });
        }
    };

    const handleDisconnectChat = () => {
        if (isConnectedRef.current) {
            StreamChatService.disconnectUser().then(() => {
                isConnectedRef.current = false;
            });
        }
    };

    useEffect(() => {
        handleInitChat();
        return () => handleDisconnectChat()
    }, []);

    return (
        <SidebarProvider>
            <div className={'w-full bg-white h-svh flex flex-col'}>
                <AppTopBarAnnouncement/>
                <div className='w-full flex h-full'>
                    <AppSidebarWrapper id={SidebarIds.MENU} side='left' classNameSidebar='lg:max-w-sm'>
                        <AppMenu/>
                    </AppSidebarWrapper>
                    <AppWrapper className='w-full flex-grow flex flex-col overflow-y-auto h-full'>
                        <Routes>
                            <Route index element={<HomePage/>}/>
                            <Route path='/wallet/*' element={<WalletPage/>}/>
                            <Route path='/settings/*' element={<SettingsPage/>}/>
                            <Route path='/my-profile' element={<ProfilePage/>}/>
                            <Route path='/cc-tools/*' element={
                                <GuardRestrictedRoute requiredRoles={[UserAuthority.CONTENT_CREATOR]}>
                                    <CreatorToolsPage/>
                                </GuardRestrictedRoute>
                            }/>
                            <Route path='/messages' element={<MessagesPage/>}/>
                            <Route path="*" element={<Navigate to="/not-found"/>}/>
                        </Routes>
                    </AppWrapper>
                </div>
            </div>
        </SidebarProvider>
    )
}
export default MainLayer
