import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import EditContactPricesView from "../features/digital-products/views/edit-contact-prices-view";
import EditSubscriptionView from "../features/digital-products/views/edit-subscription-view";
import ListDigitalProductsView from "../features/digital-products/views/list-digital-products-view";
import {SessionCreatorToolsProvider} from "../features/digital-products/contexts/session-creator-tools-context";
import AppSidebarWrapper from "../layout/components/app-sidebar-wrapper";
import {SidebarIds} from "../layout/types/sidebar-ids.interface";
import FormDigitalProductView from "../features/digital-products/views/form-digital-product-view";
import {useSidebar} from "../layout/hooks/use-sidebar";
import DigitalProductDetailsView from "../features/digital-products/views/digital-product-details-view";

const CreatorToolsPage = () => {
    const {isOpen} = useSidebar();

    return (
        <SessionCreatorToolsProvider initFetch={true}>
            <Routes>
                <Route index element={<EditContactPricesView/>}/>
                <Route path='/subscription' element={<EditSubscriptionView/>}/>
                <Route path='/digital-products' element={<ListDigitalProductsView/>}/>
                <Route path='/new-product' element={<FormDigitalProductView mode='create'/>}/>
                <Route path='/edit-product/:aliasName' element={<FormDigitalProductView mode='edit'/>}/>
                <Route path='/digital-product/:aliasName' element={<DigitalProductDetailsView/>}/>
                <Route path="*" element={<Navigate to="/not-found"/>}/>
            </Routes>
            {isOpen(SidebarIds.CATEGORIES_DIGITAL_PRODUCTS) && (
                <AppSidebarWrapper id={SidebarIds.CATEGORIES_DIGITAL_PRODUCTS} side='right' forceMobile={true}
                                   appendTo={document.body}>
                    <div>
                        xd
                    </div>
                </AppSidebarWrapper>
            )}
        </SessionCreatorToolsProvider>
    )
}

export default CreatorToolsPage
