import {Benefit} from "../models/types/account-benefit.type";
import {ReactComponent as ChatIcon} from "@assets/icons/chat.svg";
import {ReactComponent as SubOutlinedIcon} from "@assets/icons/sub-outlined.svg";
import {ReactComponent as ProductsIcon} from "@assets/icons/products.svg";
import {ReactComponent as StatsIcon} from "@assets/icons/stats.svg";
import {ReactComponent as WalletIcon} from "@assets/icons/wallet.svg";

export const accBenefitsCreator: Array<Benefit> = [
    {
        text: "Make money by responding to fan’s messages",
        Icon: ChatIcon,
    },
    {
        text: "Manage your contact pricing & subscription to control income",
        Icon: SubOutlinedIcon,
    },
    {
        text: "Create digital products to increase fan’s interest",
        Icon: ProductsIcon,
    },
    {
        text: "Analyze your situation with advanced statistics",
        Icon: StatsIcon,
    },
    {
        text: "Your money in your pocket at 24h",
        Icon: WalletIcon,
    },
];
