import {EllipsisVerticalIcon} from "@heroicons/react/24/solid";
import React from "react";
import AppButton from "../../../common/components/app-button";

const MoreOptionsButton = () => {
    return (
        <AppButton label='' className='min-w-[40px] h-[40px] w-[40px] !p-0' disabled={true}>
            <EllipsisVerticalIcon className='w-4 min-w-4 h-4'/>
        </AppButton>
    )
}

export default MoreOptionsButton
