import ChangePasswordForm from "../../auth/components/change-password-form";
import SettingsHeader from "../components/settings-header";


const ChangePasswordView = () => {
    return (
        <>
            <SettingsHeader title='Change password' className='md:justify-center'/>
            <div className='px-4 md:px-0'>
                <ChangePasswordForm/>
            </div>
        </>
    )
}
export default ChangePasswordView;
