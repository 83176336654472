import React from 'react';
import '../styles/spinner-loader.css';

interface SpinnerLoaderProps {
    size?: string;  // Opcjonalnie rozmiar w jednostkach CSS (np. '48px' lub '2em')
    color?: string; // Opcjonalnie kolor (jeśli nie podany, dziedziczy z rodzica)
}

const SpinnerLoader: React.FC<SpinnerLoaderProps> = ({ size = '0.75em', color = 'inherit' }) => {
    return (
        <div
            className="spinner-loader"
            style={{ width: size, height: size, color: color }}
        ></div>
    );
};

export default SpinnerLoader;
