// StepFour.tsx
import React from 'react';
import {Link} from 'react-router-dom';
import {classNames} from "../../../utils/class-names";
import AppButton from "../../common/components/app-button";
import {extractDomain} from "../../../utils/extract-base-url";
import {environment} from "../../../environments/environment";
import ClipboardHelper from "../../../utils/clipboard-helper";
import NotificationService from "../../common/services/notification.service";
import wellDoneNowYouCanShareYourCreatorLinkToFans from "@assets/illustrations/well-done-now-you-can-share-your-creator-link-to-fans.png";
import StepDot, {StepDotState as DotState} from '../../common/components/app-dot';

interface StepFourProps {
    username:string;
}

const StepFour: React.FC<StepFourProps> = ({username}) => {
    const urlPublicProfile = `${extractDomain(environment.backendUrl, 3000)}/${username}`;

    const handleCopyLink = async (url: string) => {
        await ClipboardHelper.copyText(url,
            () => NotificationService.success('Link has been copied to clipboard!', "Success", 5000),
            (error) => NotificationService.error('Failed to copy link', error, 'Error', 5000)
        );
    };

    return (
        <>
            <img src={wellDoneNowYouCanShareYourCreatorLinkToFans} alt="Finish" className='h-[300px] mx-auto pb-3'/>

            <div className={classNames('space-y-5')}>
                <div>
                    <h1 className="font-bold text-4xl">Request Sent!</h1>
                </div>
                <div>
                    <h1 className="font-bold text-4xl">
                        Your application needs admin approval
                    </h1>
                </div>
                <div className="text-gray-500 font-bold">
                    Once approved, you can share your link to gain followers & subscribers.
                </div>
                <div className="text-color-gray-500 text-xs text-gray">
                    Please note that your request will be reviewed by our admin team. Once accepted, you’ll gain full
                    access to all features as a creator.
                </div>
            </div>

            <div className={classNames('flex space-x-5 my-6 justify-center items-center')}>
                <StepDot state={DotState.PREVIOUS}/>
                <StepDot state={DotState.PREVIOUS}/>
                <StepDot state={DotState.PREVIOUS}/>
                <StepDot state={DotState.ACTIVE}/>
            </div>

            <Link to="/">
                <AppButton
                    type="button"
                    className="bg-primary text-white w-full !rounded-3xl mt-4 font-semibold"
                    label='Go to home'
                />
            </Link>

            {/*<AppButton*/}
            {/*    type="button"*/}
            {/*    label=""*/}
            {/*    className="bg-primary text-white w-full !rounded-3xl mt-4 font-bold"*/}
            {/*    onClick={() => handleCopyLink(urlPublicProfile)}*/}
            {/*>*/}
            {/*    <div className="flex justify-center items-center">*/}
            {/*        <LinkIcon className="w-5 h-5 mr-1" />*/}
            {/*        <div>Copy link to profile</div>*/}
            {/*    </div>*/}
            {/*</AppButton>*/}
        </>
    );
}


export default StepFour;
