import React, {useEffect, useState} from 'react';
import AppModal from "../../common/components/app-modal";
import AppButton from "../../common/components/app-button";
import Coin from "../../core/components/ui/coin";
import {ModalIds} from "../../common/models/enums/modal-ids.enum";
import useModalWithData from "../../common/hooks/use-modal-with-data";
import {DigitalProduct} from "../../core/models/interfaces/digital-product.interface";
import {useDigitalProductApi} from "../../core/hooks/use-digital-product-api";
import {useNavigate} from "react-router-dom";

interface PurchaseProductModalProps {
    appendTo?: string | React.RefObject<HTMLElement> | null;
}

const PurchaseProductModal = ({appendTo}: PurchaseProductModalProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const {hideModal, data, setCloseCallback} = useModalWithData<DigitalProduct>(ModalIds.PURCHASE_PROMPT);
    const product: DigitalProduct | null = data;
    const {buyDigitalProduct} = useDigitalProductApi();
    const navigate = useNavigate();

    const handleCloseModal = () => {
        hideModal();
    };

    const handlePurchaseProduct = async () => {
        setLoading(true);
        try {
            if (product?.id) {
                await buyDigitalProduct(product.id);
            }
            handleCloseModal();
        } catch (err: any) {
            setError(err.response.data);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setCloseCallback(ModalIds.PURCHASE_PROMPT, () => setError(null))
    }, []);

    return (
        <AppModal appendTo={appendTo} key={ModalIds.PURCHASE_PROMPT} id={ModalIds.PURCHASE_PROMPT} disableScroll={true}>
            {product && (
                <div className="flex flex-col w-full sm:w-[360px] gap-1">
                    {error && (
                        <div className="bg-red-100 text-red-700 p-3 rounded-lg mb-4">
                            {error}
                        </div>
                    )}
                    <span className='text-primary font-semibold text-xxs uppercase leading-3 tracking-[1px]'>
                        {/*{DigitalProductCategoryService.getCategoryName(product.type)}*/}
                    </span>
                    <h2 className="text-xl font-semibold mb-4">{product.name}</h2>
                    <p className="text-gray mb-4">{product.description || 'No description available'}</p>
                    <div className="flex justify-end gap-4 mt-5">
                        {error ? (
                            <>
                                <AppButton
                                    onClick={handleCloseModal}
                                    className="text-primary-darken border-0 px-4 py-2 rounded-lg border-primary-darken hover:border transition"
                                    label='OK'
                                />
                                <AppButton
                                    onClick={() => navigate('/wallet')}
                                    disabled={loading}
                                    className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-primary-darken transition"
                                    label={'Buy more yCredits'}>
                                </AppButton>
                            </>
                        ) : (
                            <>
                                <AppButton
                                    onClick={handleCloseModal}
                                    className="text-primary-darken border-0 px-4 py-2 rounded-lg border-primary-darken hover:border transition"
                                    label='Cancel'
                                />
                                <AppButton
                                    onClick={handlePurchaseProduct}
                                    disabled={loading}
                                    className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-primary-darken transition"
                                    label={''}>
                                    <div className='flex flex-row-reverse items-center gap-2'>
                                        <span className="font-bold text-lg">{product.price}</span>
                                        <Coin/>
                                        <span>Buy now</span>
                                    </div>
                                </AppButton>
                            </>
                        )}
                    </div>
                </div>
            )}
        </AppModal>
    );
};

export default PurchaseProductModal;
