import React from 'react';
import {useNavigate} from "react-router-dom";
import AppButton from "../features/common/components/app-button";
import {ReactComponent as IllustrationLandingPage} from "@assets/illustrations/illustration-landing-page.svg"
import Logo from "../features/core/components/ui/logo";
import AppWrapper from "../layout/components/app-wrapper";
import useAuth from "../features/auth/hooks/use-auth";

const AppLandingPage: React.FC = () => {
    const navigate = useNavigate();
    const {isAuthenticated} = useAuth();

    if (isAuthenticated) navigate("/")

    const handleGoToLogin = (): void => {
        navigate("/auth/login");
    };

    const handleGoToRegister = (): void => {
        navigate("/auth/register");
    };

    return (
        <AppWrapper className='h-dvh'>
            <div className="flex flex-col h-full max-w-full">
                <div
                    className='bg-[#5E17EB12] absolute top-0 left-1/2 -translate-x-1/2 w-[200%] h-[200px] md:h-[300px] '
                    style={{borderRadius: "0 0 50% 50% / 0 0 200px 200px"}}>
                </div>

                <div
                    className="w-screen md:mb-8 flex flex-col-reverse md:flex-col gap-y-3 md:gap-y-12 justify-between items-center relative pt-16">
                    <Logo type='complete' className='w-[6rem]'/>
                    <IllustrationLandingPage/>
                </div>

                <div
                    className='p-4 flex flex-col justify-between md:justify-start max-w-md mx-auto h-full flex-1 flex-grow'>
                    <div className="flex flex-col mb-8 text-center">
                        <h1 className="text-[2.5rem] font-bold mb-2 text-black leading-[3rem]">
                            Welcome in Yurs
                        </h1>
                        <p className="font-medium text-base leading-6">
                            Check out this cool app! It’s super easy to use and will make your life a whole lot easier.
                        </p>
                    </div>

                    <div className="flex flex-col md:flex-row gap-4 w-full items-center">
                        <AppButton
                            type='button'
                            label='Sign in'
                            onClick={handleGoToLogin}
                            className='w-full text-special-gray focus:text-primary hover:text-primary border-special-gray
                            text-sm py-[9px] h-fit !rounded-2xl font-semibold bg-transparent whitespace-nowrap'/>
                        <AppButton
                            type='button'
                            label='Create an account'
                            onClick={handleGoToRegister}
                            className='w-full text-white text-sm py-[9px] h-fit !rounded-2xl font-semibold
                             bg-primary whitespace-nowrap'/>
                    </div>
                </div>
            </div>
        </AppWrapper>
    );
};

export default AppLandingPage;
