import OffersBuyCredits from "../components/containers/offers-buy-credits";
import {useLocation} from "react-router-dom";
import React, {useContext, useEffect} from "react";
import {useModal} from "../../common/hooks/use-modal";
import {ModalIds} from "../../common/models/enums/modal-ids.enum";
import AppHamburgerMenu from "../../../layout/components/app-hamburger-menu";
import Breadcrumbs from "../../../layout/components/breadcrumbs";
import WalletMenu from "../components/wallet-menu";
import YourBalanceWithWithdrawCard from "../components/your-balance-with-withdraw-card";
import AppHeader from "../../../layout/app-header";
import {WalletContext} from "../contexts/wallet-context";
import AppContentWrapper from "../../../layout/components/app-content-wrapper";
import PaymentSecurityInfo from "../components/ui/payment-security-info";


const WalletPurchaseView = () => {
    const location = useLocation();
    const {openModal} = useModal();
    const {balance} = useContext(WalletContext)!;

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const purchasedSuccess = queryParams.get('success');
        if (purchasedSuccess) openModal(ModalIds.SUCCESS_PURCHASE_CREDITS);
    }, []);

    return (
        <>
            <AppHeader customContent={true}>
                <div className='flex justify-between flex-wrap gap-5'>
                    <div className='flex flex-col gap-5'>
                        <div className='flex items-center w-fit'>
                            <div className='mr-3'><AppHamburgerMenu/></div>
                            <Breadcrumbs path='Wallet'/>
                        </div>
                        <WalletMenu/>
                    </div>
                    <YourBalanceWithWithdrawCard balance={balance || 0}/>
                </div>
            </AppHeader>
            <AppContentWrapper className='p-4 bg-white rounded-t-[1.5rem] pt-4 sm:pt-9'>
                <section className='h-full flex flex-col'>
                    <header className='flex gap-2 mb-4 flex-col gap-y-4 bg-gray-light sm:bg-transparent p-4 sm:p-0 rounded-3xl'>
                        <h3 className='font-bold text-xl leading-5'>
                            Buy yCredits
                        </h3>
                        <PaymentSecurityInfo/>
                    </header>
                    <OffersBuyCredits/>
                </section>
            </AppContentWrapper>
        </>
    )
}

export default WalletPurchaseView
