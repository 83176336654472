import {DigitalProductCategory} from "../models/interfaces/digital-product-category.interface";
import {DigitalProductCategoryType} from "../../core/models/enums/digital-product-category.enum";

export const DigitalProductCategories: Array<DigitalProductCategory> = [
    {
        name: "All",
        badge: null,
        value: DigitalProductCategoryType.ALL,
    },
    // { name: "Courses", count: 0 },
    // { name: "Videos", count: 0 },
    // { name: "Images", count: 0 },
    // { name: "Deleted", count: 0 },
];
