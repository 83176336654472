import StickyBottomActionBar from "../../common/components/sticky-bottom-action-bar";
import AppButton from "../../common/components/app-button";
import {useProfileMode} from "../hooks/use-profile-mode";
import {useProfileForm} from "../hooks/use-profile-form";
import ClipboardHelper from "../../../utils/clipboard-helper";
import NotificationService from "../../common/services/notification.service";
import {extractDomain} from "../../../utils/extract-base-url";
import {environment} from "../../../environments/environment";
import React, {useContext} from "react";
import useAuth from "../../auth/hooks/use-auth";
import {classNames} from "../../../utils/class-names";
import {UserAuthority} from "../../auth/models/enums/user-authority.enum";
import {UserProfileContext, UserProfileContextProps} from "../contexts/user-profile-context";
import {PublicUserProfile} from "../../core/models/interfaces/public-user-profile.interface";

interface ProfileActionsProps {
    parentRef: React.RefObject<any>;
}


const ProfileActions = (props: ProfileActionsProps) => {
    const {isPreviewMode, setPreviewMode} = useProfileMode();
    const {currentUser, hasAnyAuthorities} = useAuth();
    const {handleUpdateProfile, methods} = useProfileForm();
    const urlPublicProfile = `${extractDomain(environment.backendUrl, 3000)}/${currentUser?.username}`
    const {forceReload} = useContext(UserProfileContext) as UserProfileContextProps<PublicUserProfile>

    const handleCopyLink = async (url: string) => {
        await ClipboardHelper.copyText(url,
            () => NotificationService.success('Link has been copied to clipboard!', "Success", 5000),
            (error) => NotificationService.error('Failed to copy link', error, 'Error', 5000)
        );
    };

    const handleGoToPreviewProfile = async () => {
        return await forceReload().then(() => {
            setPreviewMode(true)
        });
    }

    return (
        <StickyBottomActionBar parentRef={props.parentRef} forceReload={isPreviewMode}>
            <div className='flex gap-2 justify-center flex-col-reverse md:flex-row'>
                {!isPreviewMode && (
                    <AppButton
                        type='submit'
                        onClick={handleUpdateProfile}
                        label='Save changes'
                        disabled={methods.formState.disabled || !methods.formState.isValid || !methods.formState.isDirty}
                        className='!rounded-2xl min-w-fit h-fit bg-primary text-white font-semibold !text-sm'
                        disabledClassName='!bg-gray-200'
                    />
                )}
                <div className={classNames(
                    'flex gap-2 justify-center w-full',
                    isPreviewMode && "flex-col-reverse sm:flex-row"
                )}>
                    {isPreviewMode ? (
                        <AppButton
                            type='submit'
                            onClick={() => setPreviewMode(false)}
                            label='Back to edit'
                            className='!rounded-2xl min-w-fit w-full h-fit bg-primary-pastel text-primary font-semibold !text-sm'
                            disabledClassName='!bg-gray-200'
                        />
                    ) : (
                        <AppButton
                            onClick={handleGoToPreviewProfile}
                            disabled={methods.formState.isSubmitting}
                            label='Profile preview'
                            loaderColor='var(--text-primary)'
                            className='!rounded-2xl h-fit w-full min-w-fit bg-white hover:text-primary-darken border border-special-gray text-gray font-semibold !text-sm'
                        />
                    )}
                    {hasAnyAuthorities([UserAuthority.CONTENT_CREATOR]) && (
                        <AppButton
                            onClick={() => handleCopyLink(urlPublicProfile)}
                            label='Copy link to profile'
                            className='!rounded-2xl h-fit w-full min-w-fit bg-white hover:text-primary-darken border border-special-gray text-gray font-semibold !text-sm'
                        />
                    )}

                </div>
            </div>
        </StickyBottomActionBar>
    )
}

export default ProfileActions
