import * as yup from "yup";
import {TempProductFile} from "../models/interfaces/product-file.interface";

export const schemaAddProduct = yup.object().shape({
    name: yup
        .string()
        .max(100, 'Max length is 100 characters')
        .required('Name is required'),

    aliasName: yup
        .string()
        .required('aliasName is required'),

    description: yup
        .string()
        .nullable()
        .max(255, 'Max length is 255 characters'),

    price: yup
        .number()
        .nullable()
        .typeError('Price must be a number')
        .required('Price is required')
        .min(0, 'Price must be a positive number'),

    productPicture: yup
        .mixed()
        .nullable()
        .test("fileType", "Only jpg, svg, png, or jpeg files are allowed", (file: any) => {
            if (!file) return true;
            const allowedTypes = ["jpg", "jpeg", "png", "svg"];
            const fileType = file.name?.split('.').pop().toLowerCase();
            return allowedTypes.includes(fileType);
        })
        .test("fileSize", "Image must be under 5MB", (file: any) => {
            if (!file) return true;
            return file.size <= 5 * 1024 * 1024;
        }),

    files: yup
        .array()
        .of(
            yup.mixed().nullable().required("File is required")
        )
        .test("totalSize", (files, context) => {
            if (!files || files.length === 0) {
                return context.createError({
                    message: "At least one file is required",
                });
            }
            const totalSize = (files as TempProductFile[]).reduce((sum, file) => sum + (file?.file.size || 0), 0);

            if (totalSize > 50 * 1024 * 1024) {
                const totalSizeMb = (totalSize / (1024 * 1024)).toFixed(2);
                return context.createError({
                    message: `Total size of all files is ${totalSizeMb}MB, but must be under 50MB.`,
                });
            }

            return true;
        })
        .min(1, "At least one file is required")
        .required("Files are required"),
}).required();
