import AppWrapper from "../layout/components/app-wrapper";
import useAuth from "../features/auth/hooks/use-auth";
import {Case, Switch} from "../features/common/components/switch-case";
import {UserAuthority} from "../features/auth/models/enums/user-authority.enum";
import FanOnBoarding from "../features/on-boarding/views/fan-on-boarding";
import CCOnBoarding from "../features/on-boarding/views/cc-on-boarding";
import useGuardOnBoarding from "../features/core/hooks/use-guard-on-boarding";
import React, {useEffect} from "react";
import {Navigate} from "react-router-dom";

const OnBoardingPage = () => {
    const {currentUser} = useAuth();
    const hasCompletedBoarding: boolean = useGuardOnBoarding(currentUser);

    useEffect(() => {
    }, [hasCompletedBoarding]);


    if (hasCompletedBoarding) {
        return <Navigate to="/" replace/>;
    }

    return (
        <AppWrapper>
            <Switch value={currentUser?.authority}>
                <Case case={UserAuthority.FAN}><FanOnBoarding/></Case>
                <Case case={UserAuthority.CONTENT_CREATOR}><CCOnBoarding/></Case>
            </Switch>
        </AppWrapper>
    )
}

export default OnBoardingPage
