import React, {ButtonHTMLAttributes, useEffect, useState} from 'react';
import SpinnerLoader from "./spinner-loader";
import {classNames} from "../../../utils/class-names";

interface ButtonProps extends ButtonHTMLAttributes<any> {
    label?: string;
    onClick?: () => void | Promise<void>;
    className?: string;
    classNameContentWrapper?: string;
    loadingClassName?: string;
    disabledClassName?: string;
    loaderColor?: string;
    disabled?: boolean;
    loading?: boolean;
}

const AppButton = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    const [isLoading, setIsLoading] = useState(false);
    const {
        label = "",
        onClick,
        className = '',
        loadingClassName = '',
        disabledClassName = '',
        loaderColor = 'white',
        disabled = false,
        type,
        children,
        classNameContentWrapper
    } = props;

    const handleClick = async () => {
        if (disabled || isLoading) return;
        setIsLoading(true);
        try {
            if (onClick) await onClick();
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setIsLoading(!!props.loading);
    }, [props.loading]);

    const buttonClass: string = `${className} ${isLoading ? loadingClassName : ''} ${disabled && !isLoading ? disabledClassName : ''}`;

    return (
        <button
            ref={ref}
            type={type}
            className={classNames(
                'py-2 px-4 border rounded-2xl transition-colors text-sm ' + buttonClass,
                disabled && !isLoading && "!bg-gray-light !text-special-gray border-gray-light cursor-not-allowed"
            )}
            onClick={handleClick}
            disabled={disabled || isLoading}
        >
            <div className={classNames(
                "flex justify-center items-center gap-2 text-inherit",
                classNameContentWrapper
            )}>
                {isLoading && <SpinnerLoader color={loaderColor}/>}
                {label && <span>{label}</span>}
                {children && <span>{children}</span>}
            </div>
        </button>
    );
});

export default AppButton;
