import ApiService from "../../../axios/api.service";
import NotificationService from "../../common/services/notification.service";
import {UserProfile} from "../models/interfaces/user-profile.interface";
import {PublicUserProfile} from "../models/interfaces/public-user-profile.interface";
import {UserAuthority} from "../../auth/models/enums/user-authority.enum";

class ProfileApiService extends ApiService {
    // Pobieranie profilu użytkownika
    public async getUserProfile(): Promise<UserProfile> {
        return await this.get<UserProfile>(`/profile`,{
            skipDefault404Handling: true
        })
            .catch((error) => {
                NotificationService.error("Error loading user profile");
                throw error;
            });
    }

    // Pobieranie publicznego profilu
    public async getPublicCCUserProfile(username: string): Promise<PublicUserProfile> {
        return await this.get<PublicUserProfile>(`/profile/${username}`)
            .catch((error) => {
                NotificationService.error("Error loading public profile");
                throw error;
            });
    }

    // Sprawdzenie typu użytkownika - CC lub FAN
    public async getUserType(username: string): Promise<UserAuthority> {
        return await this.get<UserAuthority>(`/profile/${username}`,{
            skipDefaultErrorHandling: true,
            skipDefault404Handling: true,
        })
            .then(() => UserAuthority.CONTENT_CREATOR)
            .catch((error) => {
                return UserAuthority.FAN;
            });
    }

    // Aktualizacja profilu użytkownika
    public async updateProfile(data: FormData): Promise<void> {
        return await this.put<void>(`/profile/update`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(() => NotificationService.success("Profile updated successfully"))
            .catch((error) => {
                NotificationService.error("Error updating profile");
                throw error;
            });
    }

    // Śledzenie użytkownika
    public async followCreator(username: string): Promise<void> {
        return await this.post<void>(`/profile/follow/${username}`, {})
            .then(() => NotificationService.success(`Started following ${username}`))
            .catch((error) => {
                NotificationService.error("Error following creator");
                throw error;
            });
    }

    // Odśledzenie użytkownika
    public async unfollowCreator(username: string): Promise<void> {
        return await this.post<void>(`/profile/unfollow/${username}`, {})
            .then(() => NotificationService.success(`Stopped following ${username}`))
            .catch((error) => {
                NotificationService.error("Error unfollowing creator");
                throw error;
            });
    }

    // Sprawdzenie, czy użytkownik jest śledzony
    public async isCreatorFollowed(username: string): Promise<boolean> {
        return await this.get<boolean>(`/profile/followed/${username}`,{
            skipDefault404Handling: true
        })
            .catch((error) => {
                NotificationService.error("Error checking follow status");
                throw error;
            });
    }

    public async isContentCreatorOnline(username: string): Promise<boolean> {
        return await this.get<boolean>(`/profile/status/${username}`,{
            skipDefault404Handling: true
        })
            .catch((error) => {
                NotificationService.error("Error checking online status");
                throw error;
            });
    }

    public async visitProfile(username: string): Promise<void> {
        return await this.put<void>(`/profile/visit-counter/${username}`, {},{
            skipDefault404Handling: true
        })
            .catch((error) => {
                NotificationService.error("Error visiting profile");
                throw error;
            });
    }
}

export default new ProfileApiService()
