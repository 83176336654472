import {IDBPDatabase} from "idb";

export const images_02 = (db: IDBPDatabase, transaction: IDBTransaction) => {
    console.log("Running migration for version 2");
    if (!db.objectStoreNames.contains('profileImage')) {
        db.createObjectStore('profileImage', { keyPath: 'id', autoIncrement: true });
        console.log(`Migration 02 - store: profileImage has been created.`)
    }
    if (!db.objectStoreNames.contains('profileBackgroundImage')) {
        db.createObjectStore('profileBackgroundImage', { keyPath: 'id', autoIncrement: true });
        console.log(`Migration 02 - store: profileBackgroundImage has been created.`)
    }
}
