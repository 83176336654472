import {useContext} from "react";
import {ActiveChatContext} from "../contexts/active-chat-context";

export const useActiveChat = () => {
    const context = useContext(ActiveChatContext);
    if (!context) {
        throw new Error("useActiveChat must be used within an ActiveChatProvider");
    }
    return context;
};
