import { useEffect } from 'react';

const useClickOutside = (
    onClickOutside: () => void,
    excludedRefs: React.RefObject<HTMLElement>[]
) => {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (excludedRefs.every(ref => ref.current && !ref.current.contains(event.target as Node))) {
                onClickOutside();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClickOutside, excludedRefs]);
};

export default useClickOutside;
