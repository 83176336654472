import {useState} from 'react';
import WalletApiService from "../services/wallet-api.service";
import {CreateWithdrawRequestBody} from "../models/interfaces/create-withdraw-request-body.interface";
import {Transaction} from "../models/interfaces/transaction.interface";
import {PaymentMethodType} from "../models/enums/payment-method-type.enum";

export const useWalletApi = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    // Pobranie salda użytkownika
    const getUserBalance = async (): Promise<number> => {
        setLoading(true);
        setError(null);
        try {
            return await WalletApiService.getBalance();
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    // Pobranie cen
    const getPrices = async (): Promise<any> => {
        setLoading(true);
        setError(null);
        try {
            return await WalletApiService.getPrices();
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    // Utworzenie żądania wypłaty
    const createWithdrawRequest = async (body: CreateWithdrawRequestBody): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            return await WalletApiService.createWithdrawRequest(body);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    // Utworzenie sesji płatności
    const createCheckoutSession = async (priceId: string, paymentMethod: PaymentMethodType): Promise<{
        checkoutUrl: string
    }> => {
        setLoading(true);
        setError(null);
        try {
            return await WalletApiService.createCheckoutSession(priceId, paymentMethod);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    // Pobranie transakcji użytkownika
    const getUserTransactions = async (page: number): Promise<Transaction[]> => {
        setLoading(true);
        setError(null);
        try {
            return await WalletApiService.getUserTransactions(page);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return {
        loading,
        error,
        getUserBalance,
        getPrices,
        createWithdrawRequest,
        createCheckoutSession,
        getUserTransactions,
    };
};
