import React from 'react';
import {useChatContext} from 'stream-chat-react';
import {useChatPartnerInfo} from "../hooks/use-chat-partner-info";
import {Channel} from "stream-chat";
import DefaultProfileImg from "../../common/components/default-profile-img";

interface CustomChatItemProps {
    channel: Channel;
    onClick: () => void;
}

const CustomChatItem: React.FC<CustomChatItemProps> = ({channel, onClick}) => {
    const {client} = useChatContext();
    const {lastActiveAt} = useChatPartnerInfo(channel);
    const members = Object.values(channel.state.members).filter(
        (member) => member.user?.id !== client.userID
    );

    const user = members[0]?.user;
    const lastMessage = channel.state.messages[channel.state.messages.length - 1];

    return (
        <div
            className="flex items-center p-4 hover:bg-gray-100 cursor-pointer rounded-lg"
            onClick={onClick}
        >
            {user?.image ? (
                <img
                    src={user?.image + '' || 'https://via.placeholder.com/50'}
                    alt={`${user?.name} avatar`}
                    className="w-12 h-12 rounded-full"
                />
            ): (
                <div
                    className="min-w-12 w-12 h-12 rounded-full object-cover overflow-hidden">
                    <DefaultProfileImg/>
                </div>
            )}

            <div className="ml-4 flex-1">
                <div className="flex items-center justify-between">
                    <h4 className="text-base font-semibold">{user?.name || 'Unknown User'}</h4>
                    <span className="text-sm text-gray-500">{lastActiveAt}</span>
                </div>
                <p className="text-sm text-gray-500 truncate">
                    {lastMessage?.text || 'No messages yet'}
                </p>
            </div>
            <div className="ml-4 flex items-center space-x-2">
                {/* Optional points or status icon */}
                {/*{user?.points && (*/}
                {/*    <span className="text-yellow-500 font-semibold">{user.points}</span>*/}
                {/*)}*/}
                {/*{channel?.state?.read[channel.id] && (*/}
                {/*    <AiOutlineCheckCircle className="text-green-500"/>*/}
                {/*)}*/}
            </div>
        </div>
    );
};

export default CustomChatItem;
