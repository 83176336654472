import React, {createContext, ReactNode, useEffect, useState} from 'react';
import {PublicUserProfile} from "../../core/models/interfaces/public-user-profile.interface";
import {UserProfile} from "../../core/models/interfaces/user-profile.interface";
import useAuth from "../../auth/hooks/use-auth";
import {useUserProfileApi} from "../../core/hooks/use-user-profile-api";
import {useDigitalProductApi} from "../../core/hooks/use-digital-product-api";
import {DigitalProduct} from "../../core/models/interfaces/digital-product.interface";
import {switchMap} from "../../../axios/switch-map";
import {forkJoin} from "../../../axios/fork-join";
import {useWalletApi} from "../../core/hooks/use-wallet-api";
import {useChatApi} from "../../core/hooks/use-chat-api";
import {useNavigate} from "react-router-dom";

export interface UserProfileContextProps<T> {
    userProfile: T | null;
    balance: number | null;
    digitalProducts: DigitalProduct[];
    isFollowed: boolean;
    isOnline: boolean;
    loading: boolean;
    error: string | null;
    followCreator: () => Promise<void>;
    unfollowCreator: () => Promise<void>;
    visitProfile: () => Promise<T>;
    fetchProfile: () => Promise<T>;
    fetchFollowStatus: () => Promise<void>;
    fetchOnlineStatus: () => Promise<void>;
    forceReload: () => Promise<void>;
}

interface UserProfileProviderProps {
    children: ReactNode;
    username?: string;
    initFetch?: boolean;
}

export const UserProfileContext = createContext<UserProfileContextProps<any> | undefined>(undefined);

export const UserProfileProvider = <T extends UserProfile | PublicUserProfile>(props: UserProfileProviderProps) => {
    const [userProfile, setUserProfile] = useState<T | null>(null);
    const [digitalProducts, setDigitalProducts] = useState<DigitalProduct[]>([]);
    const [isFollowed, setIsFollowed] = useState(false);
    const [balance, setBalance] = useState<number | null>(null);
    const [isOnline, setIsOnline] = useState(false);
    const [loading, setLoading] = useState(false);
    const {isAuthenticated} = useAuth();
    const navigate = useNavigate();
    const {
        children,
        username,
        initFetch = false
    } = props;
    const {
        getUserProfile,
        getPublicUserProfile,
        followCreator,
        unfollowCreator,
        isCreatorFollowed,
        isContentCreatorOnline,
        visitProfile,
        error,
        setError
    } = useUserProfileApi();
    const {createChannel} = useChatApi();
    const {getOwnerDigitalProducts, getPublicProfileDigitalProducts} = useDigitalProductApi();
    const {getUserBalance} = useWalletApi();

    const fetchProfile = async () => {
        setLoading(true);
        setError(null);
        try {
            if (username) {
                const profile = await getPublicUserProfile(username) as T;
                setUserProfile(profile);
                return profile
            } else {
                const profile = await getUserProfile() as T;
                setUserProfile(profile);
                return profile
            }
        } catch (err: any) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchFollowStatus = async () => {
        setError(null);
        try {
            if (username) {
                const followed = await isCreatorFollowed(username);
                setIsFollowed(followed);
            }
        } catch (err: any) {
            setError(err.message);
        }
    };

    const fetchUserBalance = async () => {
        setError(null);
        try {
            if (isAuthenticated) {
                const balance: number = await getUserBalance();
                setBalance(balance);
            }
        } catch (err: any) {
            setError(err.message);
        }
    };

    const fetchOnlineStatus = async () => {
        setError(null);
        try {
            if (username) {
                const onlineStatus = await isContentCreatorOnline(username);
                setIsOnline(onlineStatus);
            }
        } catch (err: any) {
            setError(err.message);
        }
    };

    const fetchDigitalProducts = async (userId: string) => {
        setError(null);
        try {
            if (userId) {
                const products = await getOwnerDigitalProducts(userId);
                setDigitalProducts(products);
            }
        } catch (err: any) {
            setError(err.message);
        }
    };

    const fetchDigitalProductsPublicProfile = async (userId: string) => {
        setError(null);
        try {
            if (userId) {
                const products = await getPublicProfileDigitalProducts(userId);
                console.log(products)

                setDigitalProducts(products);
            }
        } catch (err: any) {
            setError(err.message);
        }
    };

    const unfollowCreatorHandler = async (username: string) => {
        if (username) {
            try {
                await unfollowCreator(username);
                setIsFollowed(false);
            } catch (error: any) {
                setError(error);
            }
        }
    };

    const followCreatorAndCreateChat = async (username: string) => {
        if (username) {
            try {
                await followCreator(username);
                const channelId = await createChannel(username);
                navigate(`/app/messages`, {state: {channelId}});
            } catch (error: any) {
                setError(error);
            }
        }
    };

    const fetchData = async () => {
        setLoading(true);
        setError(null);
        try {
            await switchMap(
                () => fetchProfile(),
                (profileData: any) => {
                    return forkJoin({
                        onlineStatus: fetchOnlineStatus(),
                        balance: fetchUserBalance(),
                        visitProfile: username ? visitProfile(username) : Promise.resolve(null),
                        digitalProducts: userProfile as UserProfile ? fetchDigitalProducts(profileData.id) :fetchDigitalProductsPublicProfile(profileData.id),
                        followStatus: isAuthenticated ? fetchFollowStatus() : Promise.resolve(null),
                    });
                }
            );
        } catch (err: any) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (initFetch) {
            fetchData();
        }
    }, [username, initFetch, isAuthenticated]);

    const forceReload = async () => {
        return await fetchData();
    }

    return (
        <UserProfileContext.Provider
            value={{
                userProfile,
                digitalProducts,
                isFollowed,
                isOnline,
                loading,
                error,
                balance,
                followCreator: () => followCreatorAndCreateChat(username!),
                unfollowCreator: () => unfollowCreatorHandler(username!),
                visitProfile: () => visitProfile(username!),
                fetchProfile,
                fetchFollowStatus,
                fetchOnlineStatus,
                forceReload,
            }}
        >
            {children}
        </UserProfileContext.Provider>
    );
};
