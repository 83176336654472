import * as yup from "yup";

export const SchemaChangePassword = yup.object({
    currentPassword: yup
        .string()
        .required("Current password is required"),

    newPassword: yup
        .string()
        .required("New password is required")
        .min(8, 'Password must be at least 8 characters long')
        .max(255, 'Password must not exceed 255 characters')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[0-9]/, 'Password must contain at least one digit')
        .matches(/[@$!%*?&#]/, 'Password must contain at least one special character'),

    confirmPassword: yup
        .string()
        .oneOf([yup.ref('newPassword'), undefined], 'Passwords must match')
        .required("Confirm password is required"),
}).required();
