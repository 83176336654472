import useAuth from "../hooks/use-auth";

const LogoutButton = () => {
    const {logout} = useAuth();

    return (
        <button
            className='text-special-gray font-semibold text-xs leading-[0.9rem] border underline rounded-3xl px-4 py-3 h-fit border-transparent bg-transparent
            transition ml-auto hover:border-primary-pastel'
            onClick={logout}>
            Log out
        </button>
    )
}

export default LogoutButton
