import {useContext} from "react";
import {ProfileModeContext, ProfileModeContextProps} from "../contexts/profile-mode-context";

export const useProfileMode = (): ProfileModeContextProps => {
    const context = useContext(ProfileModeContext);
    if (!context) {
        throw new Error('useProfileMode must be used within a ProfileModeProvider');
    }
    return context;
};
