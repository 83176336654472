import React from "react";
import {Draggable} from "@hello-pangea/dnd";
import {ReactComponent as XMarkIcon} from "@assets/icons/cross.svg";
import {ReactComponent as DragNDropIcon} from "@assets/icons/drag-n-drop.svg";
import AppButton from "../../common/components/app-button";
import FileHelper from "../../../utils/file-helper";
import {TempProductFile} from "../models/interfaces/product-file.interface";


interface FileItemProps {
    fileItem: TempProductFile,
    index: number,
    isSubmitting: boolean,
    handleRemoveFile: (fileToRemove: TempProductFile) => void,
}

const FileItem = (props: FileItemProps) => {
    const {
        index,
        fileItem,
        handleRemoveFile,
        isSubmitting
    } = props;

    return (
        <Draggable key={fileItem.uuid} draggableId={fileItem.uuid!} index={index}>
            {(provided) => (
                <li
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="border bg-white border-special-gray flex items-center justify-between gap-3 rounded-2xl p-2"
                >
                    <div className="flex items-center gap-3 truncate">
                        <div className="h-[0.625rem] w-fit min-w-2 cursor-grab text-special-gray">
                            <DragNDropIcon className="h-[0.625rem] w-fit min-w-fit"/>
                        </div>
                        <div className="flex flex-col truncate">
                        <span className="text-sm font-semibold truncate">
                            {FileHelper.getFileNameWithoutExtension(fileItem.file)}
                        </span>
                            <div className="text-xs text-gray space-x-1">
                            <span>
                                {FileHelper.getReadableFileSize(fileItem.file)},
                            </span>
                                <span>
                                {FileHelper.getFileExtension(fileItem.file)?.toUpperCase()}
                            </span>
                            </div>
                        </div>
                    </div>
                    <AppButton
                        label=""
                        disabled={isSubmitting}
                        onClick={() => handleRemoveFile(fileItem)}
                        className="rounded-lg border-none hover:text-primary-darken text-special-gray h-8 w-8 min-w-8"
                    >
                        <XMarkIcon className="h-2 w-2"/>
                    </AppButton>
                </li>
            )}
        </Draggable>
    )
}


export default FileItem
