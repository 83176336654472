// StepTwo.tsx
import React from 'react';
import {ReactComponent as ChevronLeftIcon} from "@assets/icons/chevron-left.svg";
import {ReactComponent as VideoCameraIcon} from "@assets/icons/video.svg";
import {ReactComponent as InformationCircleIcon} from "@assets/icons/info.svg";
import {classNames} from "../../../utils/class-names";
import AppButton from "../../common/components/app-button";
import SwitcherFormField from "../../common/components/forms/switcher-form-field";
import NumberFormField from "../../common/components/forms/number-form-field";
import StepDot, {StepDotState as DotState} from '../../common/components/app-dot';
import {ReactComponent as AudioIcon} from '@assets/icons/audio.svg';
import {ReactComponent as ChatIcon} from '@assets/icons/chat.svg';

interface StepTwoProps {
    prevStep: () => void;
    nextStep: () => void;
    messageDisabled: boolean;
    voiceDisabled: boolean;
    videoDisabled: boolean;
}

const StepTwo: React.FC<StepTwoProps> = ({ prevStep, nextStep, messageDisabled, voiceDisabled, videoDisabled }) => (
    <div className='max-w-[450px] md:ml-auto md:mr-auto'>
        <div className={classNames('space-y-3')}>
            <AppButton
                type="button"
                label=""
                className="bg-gray-light text-gray w-10 h-10 !rounded-2xl border-0"
                onClick={prevStep}
            >
                <ChevronLeftIcon className="w-2 h-3"/>
            </AppButton>
            <h1 className="md:max-w-[282px] font-bold text-4xl">Set your contact pricing</h1>
            <div className="text-gray">Lorem Ipsum is simply dummy text of the printing and typesetting industry</div>
        </div>

        <div className="flex justify-start space-x-2 mt-5 mb-5">
            <ChatIcon className="w-5 h-5" />
            <div>Text messages</div>
            <SwitcherFormField className="float-right" name="isTextMessages" />
        </div>

        <NumberFormField
            min={0}
            showArrows={true}
            disabled={messageDisabled}
            name="textMessagePrice"
            label="One message block price"
        />
        <div className="text-color-gray-500 text-xs text-gray flex justify-start">
            <InformationCircleIcon className="w-4 h-4" />
            <div>You'll earn $4.00 for every message block</div>
        </div>

        <div className="flex justify-start space-x-2 mt-5 mb-5">
            <AudioIcon className="w-5 h-5" />
            <div>Voice messages</div>
            <SwitcherFormField className="float-right" name="isVoiceMessages" />
        </div>

        <NumberFormField
            min={0}
            showArrows={true}
            disabled={voiceDisabled}
            name="voiceMessagePrice"
            label="One minute price"
        />

        <div className="text-color-gray-500 text-xs text-gray flex justify-start">
            <InformationCircleIcon className="w-4 h-4" />
            <div>You'll earn $8.00 for every minute</div>
        </div>

        <div className="flex justify-start space-x-2 mt-5 mb-5">
            <VideoCameraIcon className="w-5 h-5" />
            <div>Video messages</div>
            <SwitcherFormField className="float-right" name="isVideoMessages" />
        </div>

        <NumberFormField
            min={0}
            showArrows={true}
            disabled={videoDisabled}
            name="videoMessagePrice"
            label="One minute price"
        />

        <div className="text-color-gray-500 text-xs text-gray flex justify-start">
            <InformationCircleIcon className="w-4 h-4" />
            <div>You'll earn $12.00 for every minute</div>
        </div>

        <div className={classNames('flex space-x-5 my-6 justify-center items-center')}>
            <StepDot state={DotState.PREVIOUS}/>
            <StepDot state={DotState.ACTIVE}/>
            <StepDot state={DotState.NEXT}/>
            <StepDot state={DotState.NEXT}/>
        </div>

        <AppButton
            type="button"
            label="Next"
            className="bg-primary text-white w-full !rounded-3xl"
            onClick={nextStep}
        />
    </div>
);

export default StepTwo;
