
import {AuthenticatedUser} from "../../auth/models/interfaces/authenticated-user.interface";
import {RegistrationSteps} from "../models/enums/registration-steps.enum";

const useGuardRegistrationSteps = (currentUser: AuthenticatedUser | undefined): boolean => {
    const maxStep = Math.max(...Object.values(RegistrationSteps).filter(step => step < RegistrationSteps.END_BREAKPOINT));
    return currentUser?.registrationStep! > maxStep;
};

export default useGuardRegistrationSteps;
