import React, {createContext} from 'react';
import usePwaInstallHandler from "../hooks/use-pwa-install-handler";
import {PwaInstallContextProps} from "../models/interfaces/pwa-install-context-props.interface";
import {PwaInstallProviderProps} from "../models/interfaces/pwa-install-provider-props.interface";

export const PwaInstallContext = createContext<PwaInstallContextProps | undefined>(undefined);

/**
 * PwaInstallProvider component
 *
 * `PwaInstallProvider` is an application-level context provider that enables access to `usePwaInstallHandler`.
 * This provider is required to share the PWA installation logic from `usePwaInstallHandler` throughout the app.
 *
 * The provider initializes `usePwaInstallHandler` and stores its returned values, including:
 * - `installPWA`: Function to trigger the installation prompt.
 * - `manualInstructions`: Fallback instructions for browsers that do not support the native PWA prompt.
 * - `isInstalled`: A flag indicating if the app has already been installed.
 * - `hideInstructions`: Function to hide the manual instructions.
 *
 * `PwaInstallProvider` should be placed at the highest level of the app tree to make installation logic available globally.
 *
 * ### Example Usage
 *
 * ```typescript
 * <PwaInstallProvider>
 *     <App />
 * </PwaInstallProvider>
 * ```
 */
export const PwaInstallProvider: React.FC<PwaInstallProviderProps> = ({children}) => {
    const {
        installPWA,
        manualInstructions,
        setManualInstructions,
        isInstalled
    } = usePwaInstallHandler();

    const hideInstructions = () => {
        setManualInstructions(null);
    }

    return (
        <PwaInstallContext.Provider
            value={{
                hideInstructions,
                instructions: manualInstructions,
                installPWA,
                isInstalled
            }}
        >
            {children}
        </PwaInstallContext.Provider>
    );
};
