import {UserAuthority} from "../../auth/models/enums/user-authority.enum";
import {DictionaryRoles} from "../models/interfaces/dictionary-roles.interface";

export const getDictionaryRoles = (): DictionaryRoles => {
    return [
        {
            name: "Fan",
            value: UserAuthority.FAN
        },
        {
            name: "Creator",
            value: UserAuthority.CONTENT_CREATOR
        }
    ]
}
