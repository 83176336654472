import * as yup from "yup";

export const schemaForgotPassword = yup.object({
    email: yup.string()
        .test('email-or-username', 'Invalid email format', (value) => {
            if (!value) return true;
            if (value.includes('@')) {
                return yup.string().email().isValidSync(value);
            }
            return true;
        })
        .required('Username or e-mail is required'),
}).required();
