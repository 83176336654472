import {FormProvider, useForm} from "react-hook-form";
import TextFormField from "../../common/components/forms/text-form-field";
import Tooltip from "../../common/components/tooltip";
import AppButton from "../../common/components/app-button";
import React, {useEffect, useRef, useState} from "react";
import useAuth from "../hooks/use-auth";
import {yupResolver} from "@hookform/resolvers/yup";
import {schemaVerifyEmail} from "../constants/schema-verify-email";
import NotificationService from "../../common/services/notification.service";
import LocalStorageService from "../../common/services/local-storage.service";
import {LocalStorageKeys} from "../../common/models/enums/local-storage-keys.enum";
import {UniversalClickHandler} from "../../common/models/types/universal-click-handler.type";
import LogoutButton from "./logout-button";

interface VerifyEmailFormProps {
    onSuccess?: UniversalClickHandler
}

const VerifyEmailForm = ({onSuccess}: VerifyEmailFormProps) => {
    const {sendEmailConfirmationToken, activateAccount, currentUser} = useAuth();
    const formRef = useRef<HTMLFormElement>(null);
    const methods = useForm<{ token: string }>({
        resolver: yupResolver(schemaVerifyEmail),
        mode: 'onSubmit',
    });
    const [resendCountdown, setResendCountdown] = useState(() => {
        const savedCountdown = LocalStorageService.get(LocalStorageKeys.RESEND_COUNT_DOWN_EMAIL_TOKEN);
        return savedCountdown ? Math.max(Number(savedCountdown) - Math.floor(Date.now() / 1000), 0) : 0;
    });

    const handleResendToken = async () => {
        if (resendCountdown === 0) {
            try {
                await sendEmailConfirmationToken(currentUser?.email!);
                setResendCountdown(60);
                NotificationService.success(`Verification code resent on ${currentUser?.email} successfully`)
            } catch (error) {
                console.error("Failed to resend verification code:", error);
            }
        }
    };

    const handleVerifyEmail = async () => {
        const isValid = await methods.trigger();
        if (!isValid) return;

        const {token} = methods.getValues();
        try {
            await activateAccount(token);
            onSuccess && await onSuccess();
        } catch (error) {
            console.error("Email verification failed:", error);
        }
    };

    useEffect(() => {
        if (resendCountdown > 0) {
            LocalStorageService.save(LocalStorageKeys.RESEND_COUNT_DOWN_EMAIL_TOKEN, String(Math.floor(Date.now() / 1000) + resendCountdown))
        } else {
            LocalStorageService.remove(LocalStorageKeys.RESEND_COUNT_DOWN_EMAIL_TOKEN)
        }
    }, [resendCountdown]);

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (resendCountdown > 0) {
            timer = setInterval(() => setResendCountdown((prev) => prev - 1), 1000);
        }
        return () => clearInterval(timer);
    }, [resendCountdown]);

    return (
        <FormProvider {...methods}>
            <form ref={formRef} className="flex flex-col gap-y-6 p-4 max-w-md mx-auto h-full min-h-fit">
                <div className='flex-grow md:flex-grow-0 space-y-4'>
                    <div className="flex items-center gap-3 justify-between">
                        <h2 className="text-[1.75rem] md:text-[2.5rem] font-bold">Verify e-mail</h2>
                        <span className='inline-block md:hidden'><LogoutButton/></span>
                    </div>
                    <p className='font-semibold text-lg md:text-[1.375rem] leading-7'>
                        Now you need to check out your e-mail ({currentUser?.email}) and click on the link to verify it
                        or paste a verification code below:
                    </p>

                    <TextFormField name="token" maxLength={6} label="Verfication code" placeholder="Write"/>

                    <div className='flex text-special-gray text-xs leading-3 gap-1'>
                        <Tooltip/>
                        <span>
                           Didn’t receive a code?
                            <AppButton
                                onClick={handleResendToken}
                                type='submit'
                                label=''
                                loaderColor='var(--primary)'
                                className='!p-0 border-none underline ml-1 cursor-pointer transition hover:text-primary focus:text-primary'>
                                Resend a code {!!resendCountdown && `(${resendCountdown})`}
                            </AppButton>
                        </span>
                    </div>
                </div>

                <AppButton
                    type='button'
                    onClick={methods.handleSubmit(handleVerifyEmail)}
                    label='Verify e-mail'
                    className='w-full text-white text-sm py-[9px] h-fit rounded-lg font-semibold bg-primary'/>
            </form>
        </FormProvider>
    );
}

export default VerifyEmailForm
