import ApiService from "../../../axios/api.service";
import NotificationService from "../../common/services/notification.service";
import {CreateDigitalProductRequest} from "../models/interfaces/create-digital-product-request.interface";
import {ActiveDigitalProductPreview} from "../models/interfaces/active-digital-product-preview.interface";
import {DigitalProduct} from "../models/interfaces/digital-product.interface";
import {DigitalProductDTO} from "../models/interfaces/digital-product-dto.interface";
import {Pricing} from "../models/interfaces/pricing.interface";
import {ContactType} from "../models/enums/communications-type.enum";
import {UpdateDigitalProductRequest} from "../models/interfaces/update-digital-product-request.interface";
import {ProductFile, TempProductFile} from "../../digital-products/models/interfaces/product-file.interface";
import {DigitalProductDetails} from "../models/interfaces/digital-product-details.interface";

class DigitalProductApiService extends ApiService {

    public async createDigitalProduct(data: CreateDigitalProductRequest, productPicture: File | null, files: ProductFile[]): Promise<DigitalProductDTO> {
        const formData = new FormData();

        formData.append("request", new Blob([JSON.stringify(data)], {type: "application/json"}));
        if (productPicture) formData.append("productPicture", productPicture, productPicture.name);
        if (files && files.length > 0) {
            files.forEach((file, index) => {
                formData.append(`files`, file.file, file.file.name);
            });
        }

        return await this.post<DigitalProductDTO>("/digital-product", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            successMessage: "Successfully added new product!",
            errorMessage: "Error creating digital product"
        }).catch((error) => {
            throw error;
        });
    }

    public async getOwnerDigitalProducts(userId: string): Promise<DigitalProduct[]> {
        return await this.get<DigitalProduct[]>(`/digital-product/owner/${userId}`, {
            skipDefault404Handling: true
        })
            .catch((error) => {
                NotificationService.error("Error loading digital products");
                throw error;
            });
    }

    public async getPublicProfileDigitalProducts(userId: string): Promise<DigitalProduct[]> {
        return await this.get<DigitalProduct[]>(`/digital-product/fan/${userId}`, {
            skipDefault404Handling: true
        })
            .catch((error) => {
                NotificationService.error("Error loading digital products");
                throw error;
            });
    }

    public async existsProduct(aliasName: string, userName: string): Promise<boolean> {
        return await this.get<DigitalProductDetails>(`/digital-product/${userName}/${aliasName}`, {
            skipDefault404Handling: true,
            skipDefaultErrorHandling: true,
        })
            .then(() => true)
            .catch(() => false);
    }

    public async getDigitalProduct(aliasName: string, userName: string): Promise<DigitalProductDetails> {
        return await this.get<DigitalProductDetails>(`/digital-product/${userName}/${aliasName}`, {
            skipDefault404Handling: true
        })
            .catch((error) => {
                NotificationService.error("Error loading digital product details");
                throw error;
            });
    }

    public async getActiveDigitalProducts(): Promise<ActiveDigitalProductPreview[]> {
        return await this.get<ActiveDigitalProductPreview[]>(`/personal-experience/active`)
            .catch((error) => {
                NotificationService.error("Error loading active digital products");
                throw error;
            });
    }

    public async updateDigitalProduct(id: string, data: UpdateDigitalProductRequest, productPicture?: File | null, files?: TempProductFile[]): Promise<void> {
        const formData = new FormData();

        const mappedData: UpdateDigitalProductRequest = {...data};

        files?.forEach((el, index) => {
            formData.append("newFiles", el.file, el.file.name);

            const mapping = mappedData.fileMappings.find((mapping) => mapping.uuid === el.uuid);
            if (mapping) mapping.indexMultipart = index;
        });

        formData.append("request", new Blob([JSON.stringify(mappedData)], {type: "application/json"}));
        if (productPicture) formData.append("productPicture", productPicture, productPicture.name);

        return await this.put<void>(`/digital-product/${id}`, formData, {
            successMessage: "Product updated successfully",
            headers: {
                "Content-Type": "multipart/form-data"
            },
            errorMessage: "Error updating digital product",
        }).catch((error) => {
            throw error;
        });
    }

    public async deleteDigitalProduct(id: string): Promise<void> {
        return await this.delete<void>(`/digital-product/${id}`, {
            successMessage: "Digital product deleted successfully",
        }).catch((error) => {
            NotificationService.error("Error deleting digital product");
            throw error;
        });
    }

    public async buyDigitalProduct(productId: string): Promise<void> {
        return await this.post<void>(`/personal-experience/buy/${productId}`, {}, {
            successMessage: "Purchase completed successfully! Your digital product is now available.",
        }).catch((error) => {
            NotificationService.error("Error purchasing digital product");
            throw error;
        });
    }

    public async getContactPrices(): Promise<Pricing> {
        return await this.get<Pricing>(`/profile/pricing`)
            .catch((error) => {
                NotificationService.error("Error loading pricing");
                throw error;
            });
    }

    public async changeContactPrice(yCredits: number, typeContact: ContactType): Promise<void> {
        switch (typeContact) {
            case ContactType.CALL:
                return await this.put<void>(`/profile/call-price/${yCredits}`, {}, {
                    skipDefault404Handling: true,
                })
                    .catch((error) => {
                        NotificationService.error("Error updating call price");
                        throw error;
                    });
            case ContactType.CHAT:
                return await this.put<void>(`/profile/chat-price/${yCredits}`, {}, {
                    skipDefault404Handling: true,
                })
                    .catch((error) => {
                        NotificationService.error("Error updating chat price");
                        throw error;
                    });
            case ContactType.VIDEO_CALL:
                return await this.put<void>(`/profile/video-call-price/${yCredits}`, {}, {
                    skipDefault404Handling: true,
                })
                    .catch((error) => {
                        NotificationService.error("Error updating video call price");
                        throw error;
                    });
            default:
                console.error("Not unknow contact type.", typeContact)
        }

    }

}

export default new DigitalProductApiService();
