import {ProfileImageDto} from "../models/dto/profileImageDto";
import {ProfileBackgroundImageRepository} from "../repositories/profile-background-image-repository";

export class ProfileBackgroundImageService {

    private repo = new ProfileBackgroundImageRepository()


    public async saveImage(value: string) {
        const val: Omit<ProfileImageDto, 'id'> = {
            imageValue: value
        }
        await this.repo.createEntity(val);
    }

    public async getImage() {
        return await this.repo.getAllEntities().then(res => res.length ? res[0] : null);
    }

    public async updateImage(value: string) {
        const val: ProfileImageDto = {
            id: 1,
            imageValue: value
        }
        await this.repo.updateEntity(val);
    }

    public async deleteImage() {
        const image = await this.repo.getAllEntities().then(res => res.length ? res[0] : null);
        if (image){
            await this.repo.deleteEntity(image.id)
        }
    }
}

export default new ProfileBackgroundImageService()
