import React from "react";
import {FileDetails} from "../../../core/models/interfaces/digital-product-details.interface";
import FileHelper from "../../../../utils/file-helper";

interface FilesProductSectionProps {
    files: FileDetails[]
}

const FilesProductSection = (props: FilesProductSectionProps) => {
    const {files} = props;
    return (
        <section className="space-y-4">
            <h2 className="text-lg font-semibold text-black leading-[1.35rem]">Files</h2>
            <ul className="space-y-1">
                {files.map((file: FileDetails, index: number) => (
                    <li key={index} className="flex flex-col gap-1 p-2 bg-gray-light rounded-2xl">
                        <p className="font-semibold text-base leading-[1.2rem] text-black first-letter:uppercase">
                            {file.fileName}
                        </p>
                        <span className="text-xs leading-[0.9rem] font-medium text-special-gray">
                            {FileHelper.getReadableFileSize(file.fileSize)}, {file.fileFormat}
                        </span>
                    </li>
                ))}
            </ul>
        </section>
    )
}

export default FilesProductSection
