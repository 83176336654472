/**
 * Helper class for copying text to the clipboard. It provides a unified
 * approach that supports both modern Clipboard API and a fallback method
 * using a hidden textarea.
 */
class ClipboardHelper {
    /**
     * Copies the given text to the clipboard. It first tries to use the
     * Clipboard API, and if that fails or is unavailable, it falls back
     * to using a hidden textarea.
     *
     * @param text - The text to copy to the clipboard.
     * @param onSuccess - Optional callback function to be called on success.
     * @param onError - Optional callback function to be called on failure.
     * @returns A promise that resolves when the copy operation is complete.
     */
    static async copyText(
        text: string,
        onSuccess?: () => void,
        onError?: (error: any) => void
    ): Promise<void> {
        if (navigator.clipboard && window.isSecureContext) {
            try {
                await navigator.clipboard.writeText(text);
                if (onSuccess) {
                    onSuccess();
                }
            } catch (err) {
                console.error('Failed to copy using Clipboard API:', err);
                if (onError) {
                    onError(err);
                }
            }
        } else {
            this.fallbackCopyText(text, onSuccess, onError);
        }
    }

    /**
     * Fallback method for copying text to the clipboard using a hidden textarea.
     * This approach is used when the Clipboard API is not available.
     *
     * @param text - The text to copy to the clipboard.
     * @param onSuccess - Optional callback function to be called on success.
     * @param onError - Optional callback function to be called on failure.
     *
     * This method creates a hidden textarea element, sets its value to the
     * provided text, selects the text, and then executes the copy command.
     * If the copy operation is successful, the onSuccess callback is invoked.
     * Otherwise, the onError callback is called.
     */
    private static fallbackCopyText(
        text: string,
        onSuccess?: () => void,
        onError?: (error: any) => void
    ): void {
        const textArea = document.createElement("textarea");
        textArea.value = text;

        textArea.style.position = "absolute";
        textArea.style.opacity = "0";
        textArea.style.left = "-9999px";
        document.body.appendChild(textArea);

        textArea.select();
        try {
            const success = document.execCommand('copy');
            if (success) {
                if (onSuccess) {
                    onSuccess();
                }
            } else {
                if (onError) {
                    onError(new Error('Copy command failed'));
                }
            }
        } catch (err) {
            console.error('Failed to copy using execCommand:', err);
            if (onError) {
                onError(err);
            }
        } finally {
            // Clean up the created textarea element
            document.body.removeChild(textArea);
        }
    }
}

export default ClipboardHelper;
