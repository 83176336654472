import React, {useRef} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import WalletPurchaseView from "../features/purchases/views/wallet-purchase-view";
import TransactionsHistoryView from "../features/purchases/views/transactions-history-view";
import WithdrawPanelView from "../features/purchases/views/withdraw-panel-view";
import {WalletProvider} from "../features/purchases/contexts/wallet-context";
import SuccessPurchasedCreditsModal from "../features/purchases/components/success-purchased-credits-modal";
import GuardRestrictedRoute from "../features/auth/components/guard-restricted-route";
import {UserAuthority} from "../features/auth/models/enums/user-authority.enum";

const WalletPage = () => {
    const contentWrapperRef = useRef<HTMLDivElement>(null);

    return (
        <div ref={contentWrapperRef}>
            <WalletProvider initFetch={true}>
                <Routes>
                    <Route index element={<WalletPurchaseView/>}/>
                    <Route path='/history' element={<TransactionsHistoryView/>}/>
                    <Route path='/withdraw'
                           element={
                               <GuardRestrictedRoute requiredRoles={[UserAuthority.CONTENT_CREATOR]}>
                                   <WithdrawPanelView/>
                               </GuardRestrictedRoute>
                           }
                    />
                    <Route path="*" element={<Navigate to="/not-found"/>}/>
                </Routes>
                {/*TODO creditsy powinny przychodzic z backendu, wraz z parametrem success*/}
                <SuccessPurchasedCreditsModal creditsAmount={10} appendTo={contentWrapperRef}/>
            </WalletProvider>
        </div>
    )
}

export default WalletPage
