import React, {createContext, ReactNode, useState} from 'react';
import {SidebarIds} from "../types/sidebar-ids.interface";

interface SidebarContextProps {
    openSidebar: (id: SidebarIds) => void;
    closeSidebar: () => void;
    toggleSidebar: (id: SidebarIds) => void;
    isOpen: (id: SidebarIds) => boolean;
    registerSidebar: (id: SidebarIds) => void; // Funkcja do rejestrowania Sidebarów
    registeredSidebars: SidebarIds[]; // Lista zarejestrowanych Sidebarów
}

export const SidebarContext = createContext<SidebarContextProps | undefined>(undefined);

interface SidebarProviderProps {
    children: ReactNode;
}

export const SidebarProvider: React.FC<SidebarProviderProps> = ({ children }) => {
    const [openSidebarId, setOpenSidebarId] = useState<SidebarIds | null>();
    const [registeredSidebars, setRegisteredSidebars] = useState<SidebarIds[]>([]);

    const registerSidebar = (id: SidebarIds) => {
        // @ts-ignore
        setRegisteredSidebars((prev) => [...new Set([...prev, id])]);
    };

    const openSidebar = (id: SidebarIds) => {
        setOpenSidebarId(id); // Otwieramy tylko jeden Sidebar, zamykamy resztę
    };

    const closeSidebar = () => {
        setOpenSidebarId(null); // Zamyka wszystkie Sidebary
    };

    const toggleSidebar = (id: SidebarIds) => {
        if (openSidebarId === id) {
            setOpenSidebarId(null); // Zamyka Sidebar, jeśli jest otwarty
        } else {
            setOpenSidebarId(id); // Otwiera jeden, zamyka pozostałe
        }
    };

    const isOpen = (id: SidebarIds) => openSidebarId === id;

    return (
        <SidebarContext.Provider
            value={{ openSidebar, closeSidebar, toggleSidebar, isOpen, registerSidebar, registeredSidebars }}
        >
            {children}
        </SidebarContext.Provider>
    );
};
