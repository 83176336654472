import {classNames} from "../../../../utils/class-names";
import RenderPaymentMethodIcon from "./render-payment-method-icon";
import {PaymentMethodType} from "../../../core/models/enums/payment-method-type.enum";

interface P24Props {
    isSelected?: boolean;
}

const Transfers24 = ({isSelected}: P24Props) => {
    return (
        <div className="flex flex-col gap-2 items-center justify-center">
            <RenderPaymentMethodIcon option={PaymentMethodType.P24}/>
            <span
                className={classNames(
                    "font-medium text-[0.8125rem] leading-[0.975rem]",
                    isSelected ? "text-black" : "text-special-gray"
                )}
            >
                Przelewy24
            </span>
        </div>
    );
};

export default Transfers24;
