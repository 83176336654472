import {ReactComponent as VisaCardIcon} from "@assets/icons/payments/visa-card.svg";
import {classNames} from "../../../../utils/class-names";

interface VisaCardProps {
    isSelected?: boolean,
}

const VisaCard = ({isSelected}: VisaCardProps) => {
    return (
        <div className='flex flex-col gap-2 items-center justify-center'>
            <VisaCardIcon/>
            <span
                className={classNames(
                    "font-medium text-[0.8125rem] leading-[0.975rem]",
                    isSelected ? "text-black" : "text-special-gray"
                )}>
                Card
            </span>
        </div>
    )
}

export default VisaCard
