import AppButton from "../../common/components/app-button";
import React, {ReactNode} from "react";
import {UniversalClickHandler} from "../../common/models/types/universal-click-handler.type";

interface BuyButtonProps {
    onClick: UniversalClickHandler,
    price: number | string,
    label?: string | ReactNode,
    disabled?: boolean,
}

const BuyButton = (props: BuyButtonProps) => {
    const {
        onClick,
        price,
        label = "Buy:",
        disabled
    } = props;

    const handlePurchaseEvent = async () => {
        onClick && await onClick();
    }

    return (
        <AppButton
            label=''
            disabled={disabled}
            onClick={handlePurchaseEvent}
            className="bg-primary text-white w-full h-[40px] rounded-2xl font-semibold hover:bg-primary-darken focus:bg-primary-darken transition"
        >
            <div className="flex items-center justify-center gap-1 font-normal text-sm leading-4">
                <span>{label}</span>
                <span>{price}</span>
            </div>
        </AppButton>
    )
}

export default BuyButton
