import {classNames} from "../../../utils/class-names";
import {PaymentMethodType} from "../../core/models/enums/payment-method-type.enum";
import React from "react";
import {UniversalClickHandler} from "../../common/models/types/universal-click-handler.type";
import RenderPaymentMethodIcon from "./ui/render-payment-method-icon";

interface PreviewOtherPaymentMethodProps {
    onSelect: UniversalClickHandler,
    option: PaymentMethodType,
    isSelected: boolean,
}

const PreviewOtherPaymentMethod = (props: PreviewOtherPaymentMethodProps) => {
    const {onSelect, option, isSelected} = props
    return (
        <div
            onClick={onSelect}
            key={option} className='flex items-center gap-2 cursor-pointer justify-between'>
            <label className="flex items-center gap-2 cursor-pointer justify-between">
                <input
                    type="radio"
                    name="other-payment-method"
                    value={option}
                    checked={isSelected}
                    onChange={onSelect}
                    className="hidden peer"
                />
                <div
                    className="w-6 h-6 rounded-full border border-gray-400 flex items-center justify-center peer-checked:bg-primary-pastel peer-checked:border-primary">
                    <div className={classNames(
                        "w-2 h-2 bg-primary rounded-full scale-0",
                        isSelected && "scale-100"
                    )}></div>
                </div>
                <span className='lowercase first-letter:uppercase'>{option}</span>
            </label>
            <RenderPaymentMethodIcon option={option}/>
        </div>
    )
}

export default PreviewOtherPaymentMethod
