import {DigitalProductCategory} from "../models/interfaces/digital-product-category.interface";
import {DigitalProduct} from "../../core/models/interfaces/digital-product.interface";
import {DigitalProductCategoryType} from "../../core/models/enums/digital-product-category.enum";

export function mapCategoriesWithBadges(
    categories: DigitalProductCategory[],
    products: DigitalProduct[]
): DigitalProductCategory[] {
    return categories.map((category) => {
        let counter = 0;

        switch (category.value) {
            case DigitalProductCategoryType.ALL:
                counter = products.length;
                break;
                //TODO add more counters
            // case DigitalProductCategoryType.COURSE:
            //     counter = products.filter((product) => product.type === DigitalProductCategoryType.COURSE).length;
            //     break;
            // case DigitalProductCategoryType.VIDEO:
            //     counter = products.filter((product) => product.type === DigitalProductCategoryType.VIDEO).length;
            //     break;
            // case DigitalProductCategoryType.IMAGE:
            //     counter = products.filter((product) => product.type === DigitalProductCategoryType.IMAGE).length;
            //     break;
            // case DigitalProductCategoryType.DELETED:
            //     counter = products.filter((product) => product.isDeleted).length;
            //     break;
            default:
                break;
        }

        return {
            ...category,
            badge: counter > 0 ? counter : null,
        };
    });
}
