import React, {ReactNode} from 'react';
import AppHamburgerMenu from "./components/app-hamburger-menu";
import {useLocation} from 'react-router-dom';
import headerBackground from '../assets/images/header-background.svg';
import AppContentWrapper from "./components/app-content-wrapper";
import {useMediaQuery} from "react-responsive";
import Breadcrumbs from "./components/breadcrumbs";
import {classNames} from "../utils/class-names";

interface HeaderLayerProps {
    children?: ReactNode;
    customContent?: boolean;
    className?: string;
    classNameBgDesktop?: string;
}

const HeaderLayer: React.FC<HeaderLayerProps> = (props: HeaderLayerProps) => {
    const {
        children,
        customContent,
        className,
        classNameBgDesktop
    } = props
    const location = useLocation();
    const isMobile = useMediaQuery({maxWidth: '640px'});

    return (
        <div className={classNames(
            "lg:p-4",
            className,
        )}>
            <div
                style={
                    !isMobile
                        ? {background: `radial-gradient(circle, #EBE5FF 100%, #EBE5FF 0%)`}
                        : {background: "transparent"}
                }
                className={classNames(
                    "relative flex flex-col gap-3 bg-white p-4 bg-cover bg-center lg:rounded-3xl md:overflow-clip",
                    classNameBgDesktop
                )}
            >
                {isMobile && (
                    <div className='absolute top-0 left-0 w-full h-[calc(100%+50px)]'
                         style={{background: `radial-gradient(circle, #EBE5FF 100%, #EBE5FF 0%)`}}>

                    </div>
                )}
                <AppContentWrapper>
                    {customContent
                        ? children
                        : (
                            <>
                                <div className='flex items-center gap-3 w-fit'>
                                    <AppHamburgerMenu/>
                                    <Breadcrumbs path={location.pathname}/>
                                </div>
                                {children && (
                                    <div className='w-full py-5'>
                                        {children}
                                    </div>
                                )}
                            </>
                        )}
                </AppContentWrapper>
                <img
                    className='hidden sm:block absolute left-0 top-0 object-cover w-[360px] h-[500px] aspect-square opacity-70'
                    src={headerBackground}
                    alt="Left SVG"
                    style={{maskImage: "linear-gradient(to right, black 0%, transparent 100%)"}}
                />

                <img
                    className='absolute top-[-30px] right-0 sm:top-0 object-cover w-[360px] h-[500px] aspect-square opacity-100 sm:opacity-70 rotate-45 sm:rotate-0'
                    src={headerBackground}
                    alt="Right SVG"
                    style={{maskImage: "linear-gradient(to left, black 0, transparent 100%)"}}
                />
            </div>
        </div>
    );
};

export default HeaderLayer;
