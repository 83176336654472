import DigitalProductDetails from "../components/digital-product-details";
import React, {useEffect, useState} from "react";
import {useFormDigitalProduct} from "../hooks/use-form-digital-product.hook";
import {
    DigitalProductDetails as IDigitalProductDetails
} from "../../core/models/interfaces/digital-product-details.interface";
import useAuth from "../../auth/hooks/use-auth";
import FileHelper from "../../../utils/file-helper";


const PreviewDigitalProductDetailsView = () => {
    const {currentUser} = useAuth();
    const {setPreviewModeOn, previewModeOn, methods} = useFormDigitalProduct();
    const [product, setProduct] = useState<IDigitalProductDetails | null>(null);

    const goBackHandler = () => {
        setPreviewModeOn(false);
    }

    const mapFormDataToPreviewProductDetails = async (): Promise<IDigitalProductDetails> => {
        const formBody = methods.getValues();
        const imageBase64 =  formBody.productPicture ? await FileHelper.convertFileToBase64(formBody.productPicture) : ""

        return {
            edited: "empty",
            description: formBody.description || "Empty description",
            price: formBody.price,
            creatorName: currentUser?.firstname + " " + currentUser?.lastname,
            aliasName: formBody.aliasName,
            name: formBody.name || "Empty name",
            fileFormats: "",
            productSize: 0,
            files: formBody.files ? formBody.files.map((el, index) => ({
                id: index,
                uuid: "",
                ordinalNumber: index,
                fileFormat: FileHelper.getFileExtension(el.file)!,
                fileName: FileHelper.getFileNameWithoutExtension(el.file),
                fileSize: el.file.size,
            })) : [],
            id: "",
            productPicture: {
                base64: imageBase64,
                fileSize: 0,
                fileFormat: "",
                fileName: ""
            },
        }
    }

    useEffect(() => {
        mapFormDataToPreviewProductDetails().then(res => setProduct(res))
    }, [previewModeOn]);

    if (!previewModeOn) return null;

    return (
        <div className='pb-0 md:pb-8'>
            <DigitalProductDetails
                product={product}
                backEventPath=""
                customEventBack={goBackHandler}
            />
        </div>
    )
}

export default PreviewDigitalProductDetailsView
