import React, { ReactNode } from "react";

interface SwitchProps<T> {
    value: T;
    children: ReactNode;
}

interface CaseProps<T> {
    case: T;
    children: ReactNode;
}

interface DefaultProps {
    children: ReactNode;
}

const Switch = <T,>({ value, children }: SwitchProps<T>) => {
    const childrenArray = React.Children.toArray(children) as React.ReactElement<CaseProps<T> | DefaultProps>[];

    const match = childrenArray.find(
        (child) =>
            React.isValidElement(child) &&
            "case" in child.props &&
            (Array.isArray(child.props.case)
                ? child.props.case.includes(value)
                : child.props.case === value)
    );

    if (match) {
        return match;
    }
    return childrenArray.find((child) => React.isValidElement(child) && !("case" in child.props)) || null;
};


const Case = <T,>({ children }: CaseProps<T>) => <>{children}</>;

const Default = ({ children }: DefaultProps) => <>{children}</>;

export { Switch, Case, Default };
