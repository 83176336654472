import React from 'react';
import ForgotPasswordForm from "../../components/forgot-password-form";

interface ForgotPasswordViewProps {
    onSubmit: (emailOrUsername: string) => Promise<void>
}

const ForgotPasswordView: React.FC<ForgotPasswordViewProps> = (props: ForgotPasswordViewProps) => {
    const {onSubmit} = props;

    return (
        <div className='max-h-full flex flex-col h-full md:h-fit'>
            <p className="mb-4 text-sm text-special-gray">
                Forgot your password? No worries! Our password recovery
                feature makes it simple to regain access to your account
                in just a few easy steps.
            </p>
            <ForgotPasswordForm onSubmit={onSubmit}/>
        </div>
    );
};

export default ForgotPasswordView;
