import ApiService from "../../../axios/api.service";
import NotificationService from "../../common/services/notification.service";

class ChatApiService extends ApiService {
    public async createChannel(creatorUsername: string): Promise<{ channelId: string }> {
        return await this.post<{ channelId: string }>(`/chat/create/${creatorUsername}`,{},{
            skipDefault404Handling: true
        })
            .then(response => {
                NotificationService.success("Channel created successfully");
                return response;
            })
            .catch(error => {
                NotificationService.error("Error creating channel");
                throw error;
            });
    }

    public async beforeMessageSend(payload: string): Promise<void> {
        return await this.post<void>(`/api/chat/before-message-send`, payload, {
            headers: {
                "x-signature": true,
            },
        })
            .then(() => NotificationService.success("Message processed successfully"))
            .catch(error => {
                NotificationService.error("Error processing message");
                throw error;
            });
    }
}

export default new ChatApiService();
