import * as yup from "yup";

export const schemaRegister = yup.object({
    // firstname: yup.string()
    //     .required('Firstname is required'),
    //
    // lastname: yup.string()
    //     .required('Lastname is required'),
    //
    // genderId: yup.number()
    //     .typeError('Gender is required')
    //     .required('Gender is required'),

    email: yup.string()
        .email('Invalid email format')
        .required('Email is required'),

    password: yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .max(255, 'Password must be less than 255 characters')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[0-9]/, 'Password must contain at least one number')
        .matches(/[@$!%*?&#]/, 'Password must contain at least one special character'),

    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), undefined], 'Passwords must match')
        .required("Confirm password is required"),

    // contactNumber: yup.string()
    //     .min(9,'Phone number is not correct')
    //     .required('Phone number is required'),
    //
    // isAdult: yup.boolean()
    //     .required('You must be over 18'),
    //
    // emailNotifications: yup.boolean()
    //     .required('You must be over 18')
}).required();
