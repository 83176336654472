import * as yup from "yup";

export const schemaLogin = yup.object({
    email: yup.string()
        .required('Email or username is required'),

    password: yup.string()
        .required('Password is required'),

}).required();
