import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import TextFormField from '../../common/components/forms/text-form-field';
import useAuth from "../hooks/use-auth";
import AppButton from "../../common/components/app-button";
import {ReactComponent as ChevronLeftIcon} from "@assets/icons/chevron-left.svg";
import PasswordFormField from "../../common/components/forms/password-form-field";
import {useRedirect} from "../hooks/use-redirect";
import {schemaLogin} from "../constants/schema-login";


const LoginForm = () => {
    const {login, loading} = useAuth();
    const navigate = useNavigate();
    const {intendedRoute, setIntendedRoute} = useRedirect();

    const methods = useForm({
        resolver: yupResolver(schemaLogin),
        mode: 'onSubmit',
    });

    const handleLogin = async () => {
        await methods.trigger();

        if (!methods.formState.isValid) {
            return;
        }

        const body = methods.getValues();
        try {
            await login(body.email, body.password);
            const redirectTo = intendedRoute || '/';
            setIntendedRoute(null);
            navigate(redirectTo);
        } catch (error) {
            console.error("Login failed:", error);
        }
    };

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={methods.handleSubmit(handleLogin)}
                className="flex flex-col gap-y-6 p-4 max-w-md mx-auto h-full min-h-fit">

                <div className='flex-grow md:flex-grow-0 space-y-4'>
                    <div className="flex items-center gap-4">
                        <button
                            type='button'
                            className='p-3 w-10 h-10 bg-gray-light flex items-center justify-center rounded-2xl text-special-gray'
                            onClick={() => navigate("/welcome")}>
                            <ChevronLeftIcon className='w-2 h-3'/>
                        </button>
                        <h2 className="text-3xl font-bold">Sign in</h2>
                    </div>

                    {/*<div className='flex flex-col gap-y-[2px]'>*/}
                    {/*    <ButtonGoogle/>*/}
                    {/*    <ButtonFacebook/>*/}
                    {/*    <ButtonInstagram/>*/}
                    {/*</div>*/}

                    {/*<div className='flex gap-3 items-center'>*/}
                    {/*    <div className='w-full border-b border-gray'></div>*/}
                    {/*    <span className='font-semibold text-special-gray text-xs'>OR</span>*/}
                    {/*    <div className='w-full border-b border-gray'></div>*/}
                    {/*</div>*/}

                    <TextFormField
                        name="email"
                        label="Username or e-mail"
                        placeholder="Write username or e-mail..."
                    />

                    <div>
                        <PasswordFormField
                            name="password"
                            label="Password"
                            placeholder="Write password..."
                            showPasswordToggle={true}
                        />

                        <div
                            className="flex justify-end text-xs text-special-gray hover:text-primary focus:text-primary underline mt-2">
                            <Link to="/auth/forgot-password">Forgot password?</Link>
                        </div>
                    </div>
                </div>

                <AppButton
                    type='submit'
                    loading={loading}
                    label={'Sign In'}
                    className='w-full text-white text-sm py-[9px] h-fit !rounded-2xl font-semibold bg-primary '/>
            </form>
        </FormProvider>
    );
};

export default LoginForm;

