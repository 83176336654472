import "../../styles/coin.css";

const Coin = () => {
    return (
        <div className="coin">
            {/*<div className="coin-ring-inner"></div>*/}
            <div className="coin-inner">
                <span className="coin-text y">Y</span>
                <span className="coin-text c">C</span>
            </div>
        </div>
    );
}

export default Coin;
