import AppButton from "../../../common/components/app-button";
import React from "react";
import {ReactComponent as VideoIcon} from "@assets/icons/video.svg"

const VideoCallButton = () => {
    return (
        <AppButton label=''
                   className='text-special-gray w-full md:w-fit h-fit text-sm font-semibold min-h-[40px]'
                   disabled={true}>
            <div className='flex gap-1 items-center'>
                <VideoIcon className="block w-3 min-w-3 h-3 text-special-gray"/>
                <span className='whitespace-nowrap'>Video call</span>
            </div>
        </AppButton>
    )
}

export default VideoCallButton
