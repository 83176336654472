import React from 'react';

interface TextStatusUserProps {
    isOnline: boolean;
}

const TextStatusUser: React.FC<TextStatusUserProps> = ({ isOnline }) => {
    return (
        <div>
            {isOnline ? 'Online' : 'Offline'}
        </div>
    );
}

export default TextStatusUser;
