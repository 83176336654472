import React from "react";
import {Navigate} from "react-router-dom";
import useAuth from "../hooks/use-auth";
import {UserAuthority} from "../models/enums/user-authority.enum";

interface RestrictedRouteProps {
    requiredRoles: UserAuthority[];
    children: React.ReactElement;
}


const GuardRestrictedRoute: React.FC<RestrictedRouteProps> = (props: RestrictedRouteProps) => {
    const {hasAnyAuthorities} = useAuth();
    const {requiredRoles, children} = props;
    const userHasRequiredRole = hasAnyAuthorities(requiredRoles)

    if (!userHasRequiredRole) {
        return <Navigate to="/not-found"/>;
    }

    return children;
}

export default GuardRestrictedRoute
