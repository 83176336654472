import React, {createContext, ReactNode, useState} from 'react';

export interface ProfileModeContextProps {
    isPreviewMode: boolean;
    setPreviewMode: (isPreview: boolean) => void;
}

export const ProfileModeContext = createContext<ProfileModeContextProps | undefined>(undefined);

export const ProfileModeProvider = ({children}: { children: ReactNode }) => {
    const [isPreviewMode, setPreviewMode] = useState<boolean>(false);

    return (
        <ProfileModeContext.Provider value={{isPreviewMode, setPreviewMode}}>
            {children}
        </ProfileModeContext.Provider>
    );
};

