import { StreamChat } from 'stream-chat';
import { AuthenticatedUser } from '../../auth/models/interfaces/authenticated-user.interface';

class StreamChatService {
    private static instance: StreamChatService;
    private readonly _client: StreamChat;
    private _clientReady: boolean = false;
    private clientReadyCallbacks: (() => void)[] = [];

    private constructor() {
        this._client = StreamChat.getInstance(process.env.REACT_APP_STREAM_API_KEY!);
    }

    public static getInstance(): StreamChatService {
        if (!StreamChatService.instance) {
            StreamChatService.instance = new StreamChatService();
        }
        return StreamChatService.instance;
    }

    public connectUser = async (user: AuthenticatedUser) => {
        if (this._client.userID) return;

        const getStreamUser = {
            id: user.id,
            name: user.username ? user.username : `${user.firstname} ${user.lastname}`,
        };

        try {
            await this._client.connectUser(getStreamUser, user.chatToken);
            this.setClientReady(true);
        } catch (error) {
            console.error("Failed to connect user:", error);
            throw error;
        }
    };

    public disconnectUser = async () => {
        if (this._client.userID) {
            await this._client.disconnectUser();
            this.setClientReady(false);
        }
    };

    private setClientReady(isReady: boolean) {
        this._clientReady = isReady;
        if (isReady) {
            this.clientReadyCallbacks.forEach(callback => callback());
            this.clientReadyCallbacks = [];
        }
    }

    public onClientReady(callback: () => void) {
        if (this._clientReady) {
            callback();
        } else {
            this.clientReadyCallbacks.push(callback);
        }
    }

    public getChannels = async (filters: any, sort: any, options: any) => {
        if (!this._clientReady) {
            await new Promise<void>((resolve) => this.onClientReady(resolve));
        }
        return await this._client.queryChannels(filters, sort, options);
    };

    public get client() {
        return this._client;
    }
}

export default StreamChatService.getInstance();
