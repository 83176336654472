import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import SettingsMenu from "../features/settings/components/settings-bar";
import AppContentWrapper from "../layout/components/app-content-wrapper";
import {classNames} from "../utils/class-names";
import SupportView from "../features/settings/views/support-view";
import ChangePasswordView from "../features/settings/views/change-password-view";
import TermsView from "../features/settings/views/terms-view";
import NotificationsView from "../features/settings/views/notifications-view";

const SettingsPage = () => {
    const location = useLocation();
    const isRoot = location.pathname === '/app/settings';

    return (
        <Routes>
            <Route path="/*" element={
                <div className='@container md:p-4 md:space-x-4 h-full flex flex-col md:flex-row w-full overflow-x-clip
                    overflow-y-auto md:overflow-y-clip'>
                    <SettingsMenu/>

                    {!isRoot ? (
                        <AppContentWrapper
                            className={classNames(
                                "px-0 p-4 md:px-4 md:!pt-12 bg-white rounded-t-[1.5rem] md:rounded-[1.5rem] " +
                                "md:!block flex-grow w-full border-none md:border-solid md:border-[30px] " +
                                "border-stone-100 md:min-h-full md:h-full md:overflow-y-auto max-w-full"
                            )}
                        >
                            <Routes>
                                <Route path='/support/*' element={<SupportView/>}/>
                                <Route path='/change-password' element={<ChangePasswordView/>}/>
                                <Route path='/terms' element={<TermsView/>}/>
                                <Route path='/notifications' element={<NotificationsView/>}/>
                                <Route path="/*" element={<Navigate to="/not-found"/>}/>
                            </Routes>
                        </AppContentWrapper>
                    ) : null}
                </div>
            }/>
        </Routes>
    );
}

export default SettingsPage
