import {ComponentType} from "../models/types/component.type";
import {classNames} from "../../../utils/class-names";


interface WrapperCardProps extends React.HTMLAttributes<HTMLDivElement> {
    children: ComponentType,
    className?: string,
}

const WrapperCard = (props: WrapperCardProps) => {
    const {children, className} = props;

    return (
        <div
            onClick={props.onClick}
            className={classNames(
            'w-fit h-fit rounded-3xl border border-special-gray p-4',
            className
        )}>
            {children}
        </div>
    )
}

export default WrapperCard
