import {useChatContext} from 'stream-chat-react';
import {DateHelper} from "../../../utils/date-helper";
import {Channel} from "stream-chat";
import {useActiveChat} from "./use-active-chat";

/**
 * Hook `useChatPartnerInfo` returns information about the chat partner (another user in the channel) based on the provided channel.
 * If no channel (`channel`) is passed as an argument, the hook defaults to using the currently active channel from the context.
 *
 * @param channel - (optional) The channel object from which to fetch information. If not provided, the hook will use the active channel from `useChannelStateContext`.
 * @returns An object containing:
 * - `lastActiveAt` - The last active time of the chat partner, formatted as "time ago". Returns an empty string if no data is available.
 * - `userName` - The chat partner's name. Defaults to "Unknown User" if the name is not available.
 * - `userAvatar` - The URL of the chat partner's avatar. Defaults to an empty string if the avatar is not available.
 */
export const useChatPartnerInfo = (channel?: Channel) => {
    const {client} = useChatContext();
    const {activeChannel: activeChannelContext} = useActiveChat();

    const activeChannel = channel || activeChannelContext;

    const members = activeChannel?.state.members || {};
    const chatPartner = Object.values(members).find(
        (member) => member.user?.id !== client.userID
    );

    const lastActiveAt = chatPartner?.user?.last_active
        ? DateHelper.timeAgo(new Date(chatPartner.user.last_active))
        : '';
    const userName = chatPartner?.user?.name ?? 'Unknown User';
    const userAvatar = chatPartner?.user?.image + '' ?? '';

    return {lastActiveAt, userName, userAvatar};
};
