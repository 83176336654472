import {CustomAxiosRequestConfig} from "./api.service";

export interface RequestOptions extends Omit<CustomAxiosRequestConfig,"url">  {
    path: string
}

export enum RequestMethods {
    GET = "get",
    POST = "post",
    PUT = "put",
    DELETE = "delete",
}
