import {extractDomain} from "../../../utils/extract-base-url";
import {environment} from "../../../environments/environment";
import ClipboardHelper from "../../../utils/clipboard-helper";
import NotificationService from "../../common/services/notification.service";
import StickyBottomActionBar from "../../common/components/sticky-bottom-action-bar";
import AppButton from "../../common/components/app-button";
import React from "react";
import {DigitalProductDetails} from "../../core/models/interfaces/digital-product-details.interface";
import Coin from "../../core/components/ui/coin";
import useModalWithData from "../../common/hooks/use-modal-with-data";
import {ModalIds} from "../../common/models/enums/modal-ids.enum";
import useRequiredLogin from "../../auth/hooks/use-required-login";
import {useModal} from "../../common/hooks/use-modal";
import useRequiredFollow from "../../core/hooks/use-required-follow";

interface PublicProductActionsProps {
    product: DigitalProductDetails | null;
    parentRef?: React.RefObject<any>;
    creatorUserName?: string;
}

const PublicProductActions = ({product, parentRef, creatorUserName}: PublicProductActionsProps) => {
    const aliasName = product?.aliasName;
    const urlPublicProduct = `${extractDomain(environment.backendUrl, 3000)}/${creatorUserName}/${aliasName}`
    const {showModal} = useModalWithData<DigitalProductDetails>(ModalIds.PURCHASE_PROMPT);
    const {openModal} = useModal();
    const checkLogin = useRequiredLogin({onLoginRequired: openModal.bind(this, ModalIds.LOGIN_REQUIRED)});
    const checkFollow = useRequiredFollow(creatorUserName!, {
        onFollowRequired: openModal.bind(this, ModalIds.FOLLOW_REQUIRED)
    });

    const copyLinkToProductHandler = async (url: string) => {
        if (aliasName) {
            await ClipboardHelper.copyText(url,
                () => NotificationService.success('Link has been copied to clipboard!', "Success", 5000),
                (error) => NotificationService.error('Failed to copy link', error, 'Error', 5000)
            );
        } else {
            NotificationService.warning('Unable to create a link. Please check the alias name or try again.', "Warning", 5000)
        }
    }

    const handleBuyDigitalProduct = async (event?: React.MouseEvent) => {
        if (!checkLogin()) return;
        const isAllowed = await checkFollow(event);
        if (!isAllowed) return;
        if (product) {
            showModal(product);
        }
    };

    return (
        <StickyBottomActionBar parentRef={parentRef}>
            <div className='@container md:@container-normal'>
                <div className='flex gap-2 flex-wrap md:flex-nowrap @[260px]:flex-nowrap'>
                    <AppButton
                        type='submit'
                        onClick={handleBuyDigitalProduct}
                        label=''
                        className='!w-[250px] !text-sm font-semibold !bg-primary text-white !rounded-2xl hover:brightness-110 focus:brightness-110 whitespace-nowrap'
                    >
                        <div className='flex gap-1 items-center'>
                            Buy: {product?.price} <Coin/>
                        </div>
                    </AppButton>
                    <AppButton
                        onClick={() => copyLinkToProductHandler(urlPublicProduct)}
                        label='Copy link to product'
                        type='button'
                        className='font-semibold w-fit !rounded-2xl !text-special-gray hover:!text-primary-darken whitespace-nowrap bg-white'/>
                </div>
            </div>
        </StickyBottomActionBar>
    )
}

export default PublicProductActions
