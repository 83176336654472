import React from 'react';
import {ChannelHeaderProps} from 'stream-chat-react';
import VoiceCallButton from "./ui/voice-call-button";
import VideoCallButton from "./ui/video-call-button";
import MoreOptionsButton from "./ui/more-options-button";
import {useChatPartnerInfo} from "../hooks/use-chat-partner-info";
import {useActiveChat} from "../hooks/use-active-chat";
import {classNames} from "../../../utils/class-names";
import {useMediaQuery} from "react-responsive";
import {ReactComponent as ChevronLeftIcon} from "@assets/icons/chevron-left.svg"
import DefaultProfileImg from "../../common/components/default-profile-img";

const CustomChannelHeader = (props: ChannelHeaderProps) => {
    const {userAvatar, userName, lastActiveAt} = useChatPartnerInfo();
    const {isChatWithCC, setActiveChannel} = useActiveChat();
    const isDesktop = useMediaQuery({minWidth: 768});

    const goToCCProfile = () => {
        if (isChatWithCC) window.open(`/${userName}`, '_blank');
    }

    const goBackOnMobileHandler = () => setActiveChannel(null)


    return (
        <div className="flex items-center justify-between p-4 bg-white flex-wrap gap-3">
            <div className="flex w-full md:w-fit justify-between items-center">
                <div className="flex items-center">
                    <div onClick={goBackOnMobileHandler}
                         className='flex md:hidden w-4 h-4 items-center justify-center rounded-lg p-3 mr-2 cursor-pointer
                        hover:shadow-[0_0_0_1px_rgba(128,128,128,0.5)] focus:shadow-[0_0_0_1px_rgba(128,128,128,0.5)]'>
                        <ChevronLeftIcon className='text-special-gray min-w-2 w-2 h-3'/>
                    </div>
                    {userAvatar !== "undefined" ? (
                        <img
                            onClick={goToCCProfile}
                            className={classNames(
                                "h-[72px] w-[72px] lg:w-[48px] lg:h-[48px] rounded-2xl object-cover bg-primary-pastel p-[2px] mr-4",
                                isChatWithCC && "cursor-pointer"
                            )}
                            src={userAvatar}
                            alt={`${userName} avatar`}
                        />
                    ) : (
                        <div
                            onClick={goToCCProfile}
                            className={classNames(
                                "h-[72px] w-[72px] lg:w-[48px] lg:h-[48px] p-[2px] mr-4 rounded-2xl object-cover overflow-hidden",
                                isChatWithCC && "cursor-pointer"
                            )}>
                            <DefaultProfileImg/>
                        </div>
                    )}

                    <div className='flex flex-col'>
                        <span className="text-xl font-bold leading-[1.5rem]">{userName}</span>
                        <span className="text-special-gray text-xs leading-[0.9rem]">Last seen: {lastActiveAt}</span>
                    </div>
                </div>
                {!isDesktop && <MoreOptionsButton/>}
            </div>
            <div className="flex items-center space-x-2 w-full md:w-fit md:ml-auto">
                <VoiceCallButton/>
                <VideoCallButton/>
                {isDesktop && <MoreOptionsButton/>}
            </div>
        </div>
    );
};

export default CustomChannelHeader;
