import './App.css';
import AppRoutes from "./app-routes";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import NotificationPrompt from "./features/common/components/notification/notification-prompt";
import {AuthProvider} from "./features/auth/contexts/auth-context";
import {RedirectProvider} from "./features/auth/contexts/redirect-context";
import LoginRequiredModal from "./features/auth/components/login-required-modal";
import {ModalProvider} from "./features/common/contexts/modal-context";
import FollowRequiredModal from "./features/common/components/follow-required-modal";
import InstructionsInstallPwa from "./features/core/components/instructions-install-pwa";
import { DatabaseService } from './features/core/db/indexedDB.service';

const router = createBrowserRouter([
    {
        path: '*',
        element:
            <>
                <AuthProvider>
                    <RedirectProvider>
                        <ModalProvider>
                            <AppRoutes/>
                            <LoginRequiredModal/>
                            <FollowRequiredModal/>
                        </ModalProvider>
                        <InstructionsInstallPwa/>
                        <NotificationPrompt/>
                    </RedirectProvider>
                </AuthProvider>
            </>,
    },
]);

function App() {
    const databaseService = DatabaseService;

    return <RouterProvider router={router}/>;
}

export default App;
