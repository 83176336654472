import AppButton from "../../common/components/app-button";
import StickyBottomActionBar from "../../common/components/sticky-bottom-action-bar";
import React, {useContext} from "react";
import {useFormDigitalProduct} from "../hooks/use-form-digital-product.hook";
import ClipboardHelper from "../../../utils/clipboard-helper";
import NotificationService from "../../common/services/notification.service";
import {extractDomain} from "../../../utils/extract-base-url";
import {environment} from "../../../environments/environment";
import useAuth from "../../auth/hooks/use-auth";
import Popover from "../../common/components/popover";
import ButtonMoreActions from "../../core/components/ui/button-more-actions";
import {ReactComponent as LinkIcon} from "@assets/icons/link.svg";
import {ReactComponent as TrashIcon} from "@assets/icons/trash.svg";
import {useNavigate} from "react-router-dom";
import {SessionCreatorToolsContext} from "../contexts/session-creator-tools-context";

interface CreatorToolsActionsProps {
    parentRef: React.RefObject<any>;
}

const CreatorToolsActions = ({parentRef}: CreatorToolsActionsProps) => {
    const {handleSubmitProduct, mode, methods, previewModeOn, setPreviewModeOn, productId} = useFormDigitalProduct();
    const {formState: {disabled, isValid, isDirty}} = methods;
    const {currentUser} = useAuth();
    const aliasName = methods.getValues('aliasName');
    const urlPublicProduct = `${extractDomain(environment.backendUrl, 3000)}/${currentUser?.username}/${aliasName}`
    const navigate = useNavigate();
    const {deleteProduct} = useContext(SessionCreatorToolsContext)!;

    const copyLinkToProductHandler = async (url: string) => {
        if (aliasName) {
            await ClipboardHelper.copyText(url,
                () => NotificationService.success('Link has been copied to clipboard!', "Success", 5000),
                (error) => NotificationService.error('Failed to copy link', error, 'Error', 5000)
            );
        } else {
            NotificationService.warning('Unable to create a link. Please check the alias name or try again.', "Warning", 5000)
        }
    }

    const deleteDigitalProduct = async () => {
        if (productId) {
            try {
                await deleteProduct(productId);
                navigate("../digital-products");
            } catch (error: any) {
                console.error(error);
            }
        }
    }

    return (
        <StickyBottomActionBar parentRef={parentRef} forceReload={previewModeOn}>
            <div className='@container md:@container-normal'>
                {!previewModeOn ? (
                    <div className='flex gap-2 flex-wrap md:flex-nowrap @[260px]:flex-nowrap'>
                        <AppButton
                            type='submit'
                            disabled={disabled || !isValid || !isDirty}
                            onClick={handleSubmitProduct}
                            label={mode === "create" ? 'Add product' : "Edit product"}
                            className='!text-sm font-semibold !bg-primary text-white !rounded-2xl w-full hover:brightness-110 focus:brightness-110 whitespace-nowrap'
                            disabledClassName='disabled:!bg-gray-200'
                        />
                        <AppButton
                            onClick={() => setPreviewModeOn(true)}
                            label='Preview'
                            type='button'
                            className='font-semibold w-full @[260px]:w-fit !rounded-2xl !text-special-gray hover:!text-primary-darken whitespace-nowrap bg-white'/>
                        {mode === "edit" && (
                            <Popover
                                trigger={<ButtonMoreActions/>}
                                preferredPositionY="top"
                                preferredPositionX="left"
                            >
                                <AppButton
                                    onClick={() => copyLinkToProductHandler(urlPublicProduct)}
                                    type='button'
                                    classNameContentWrapper="!justify-start"
                                    className='block w-full !p-0 border-0 hover:text-primary !items-start'>
                                    <div className='flex gap-3 items-center'>
                                        <LinkIcon className='w-[1.125rem] h-[1.125rem]'/>
                                        <span className=''>Copy link to product</span>
                                    </div>
                                </AppButton>
                                <AppButton
                                    onClick={deleteDigitalProduct}
                                    type='button'
                                    classNameContentWrapper="!justify-start"
                                    className='block w-full !p-0 border-0 hover:text-primary !items-start'>
                                    <div className='flex gap-3 items-center'>
                                        <TrashIcon className='w-[1.125rem] h-[1.125rem]'/>
                                        <span className=''>Remove product</span>
                                    </div>
                                </AppButton>
                            </Popover>
                        )}
                    </div>
                ) : (
                    <div className='flex gap-2 flex-wrap md:flex-nowrap @[260px]:flex-nowrap'>
                        <AppButton
                            type='button'
                            onClick={() => setPreviewModeOn(false)}
                            label="Back to edit"
                            className='font-semibold !bg-primary-pastel text-primary !rounded-2xl whitespace-nowrap w-full hover:!bg-primary focus:!bg-primary hover:!text-white focus:!text-white'
                        />
                        <AppButton
                            onClick={() => copyLinkToProductHandler(urlPublicProduct)}
                            label='Copy link to product'
                            type='button'
                            className='font-semibold w-full @[260px]:w-fit !rounded-2xl !text-special-gray hover:!text-primary-darken whitespace-nowrap bg-white'/>
                    </div>
                )}
            </div>
        </StickyBottomActionBar>
    )
}

export default CreatorToolsActions
