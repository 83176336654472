import AppButton from "../../common/components/app-button";
import {UniversalClickHandler} from "../../common/models/types/universal-click-handler.type";

interface SkipStepButtonProps {
    onSkip: UniversalClickHandler
}

const SkipOnboarding = ({onSkip}: SkipStepButtonProps) => {
    return (
        <AppButton
            onClick={onSkip}
            loaderColor='text-black'
            label='Skip onboarding'
            className='text-special-gray font-semibold text-xs leading-[0.9rem] underline !rounded-3xl !py-3 h-fit border-transparent bg-transparent
            hover:text-primary-darken focus:text-primary-darken transition ml-auto hover:border-primary-pastel'
        />
    )
}

export default SkipOnboarding
