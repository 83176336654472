import React, {useEffect, useState} from 'react';
import {Base64Prefix} from "../../common/constats/base64-prefix";
import defaultBackground from "@assets/images/header-background.svg";
import UploadButton from "@rpldy/upload-button";
import {useBatchAddListener} from "@rpldy/uploady";
import AppButton from "../../common/components/app-button";
import {ArrowUturnLeftIcon} from "@heroicons/react/24/outline";
import SpinnerLoader from "../../common/components/spinner-loader";
import AppHamburgerMenu from "../../../layout/components/app-hamburger-menu";
import useAuth from "../../auth/hooks/use-auth";
import CircleImg from '../../common/components/circle-img';
import ForestMountainPic from '@assets/images/forest_mountain.png';
import OceanPic from '@assets/images/ocean.png';
import ScandinavianForestPic from '@assets/images/scandinavian_forest.png';
import {ReactComponent as EditIcon} from '@assets/icons/edit.svg';
import {useMediaQuery} from "react-responsive";
import {classNames} from "../../../utils/class-names";

interface ProfileBackgroundProps {
    backgroundImage?: string;
    className?: string;
    isEditable?: boolean;
    onChange?: (imageSrc: string) => void;
    canShowMenuHamburger?: boolean;
}

const ProfileBackground: React.FC<ProfileBackgroundProps> = (props: ProfileBackgroundProps) => {
    const [originalBackground, setOriginalBackground] = useState<string | undefined>(props.backgroundImage ?? '');
    const {isAuthenticated} = useAuth();
    const [tempBackground, setTempBackground] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const isDesktop = useMediaQuery({minWidth: 1024});
    const {
        backgroundImage,
        className,
        isEditable = false,
        onChange,
        canShowMenuHamburger = false,
    } = props;

    const handleBackgroundUpload = (file: File) => {
        setLoading(true);
        const reader = new FileReader();

        reader.onloadend = () => {
            setTempBackground(reader.result as string);
            onChange && onChange(reader.result as string);
            setLoading(false);
        };

        reader.readAsDataURL(file);
    };

    const handleCancel = () => {
        setTempBackground('');
        onChange && onChange(originalBackground ?? '');
    };

    if (isEditable) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useBatchAddListener((batch) => {
            if (batch) {
                handleBackgroundUpload(batch.items[0].file as File);
            }
        });
    }

    useEffect(() => {
        if (backgroundImage) {
            setOriginalBackground(backgroundImage)
            setTempBackground('');
        }
    }, [backgroundImage]);

    return (
        <div className="relative w-full h-full md:overflow-clip">
            <div
                // className={classNames(
                //     "w-full h-full bg-cover bg-center md:rounded-3xl ",
                //     className && className
                // )}
                style={
                    backgroundImage || tempBackground
                        ? {backgroundImage: `url(${tempBackground ? tempBackground : backgroundImage ? Base64Prefix + backgroundImage : defaultBackground})`}
                        // : {background: `radial-gradient(circle, #EBE5FF 100%, #EBE5FF 0%)`}
                        : {background: `#F5F7F9`}
                }
                className="relative flex flex-col h-full gap-3 bg-white p-4 bg-cover bg-center lg:rounded-3xl md:overflow-clip"
                // style={{
                //     backgroundImage: `url(${tempBackground ? tempBackground : backgroundImage ? Base64Prefix + backgroundImage : defaultBackground})`
                // }}
            >
                <div className='flex justify-between self-center w-full max-w-[832px] lg:max-w-full p-4 lg:!p-0'>
                    {(canShowMenuHamburger || (isAuthenticated && isEditable)) &&
                        <AppHamburgerMenu className={classNames(
                            !isDesktop ? '!bg-[rgba(255,255,255,0.2)] !text-white hover:!text-black' : '',
                            (!originalBackground && !tempBackground) && '!bg-black hover:!bg-black !text-white'
                        )}/>
                    }

                    {isEditable && (
                        <div className="relative z-[3] lg:absolute top-0 right-0 flex space-x-2 flex-grow justify-end">
                            {tempBackground ? (
                                <AppButton
                                    label=''
                                    onClick={handleCancel}
                                    className='relative lg:top-[20px] lg:right-[20px] text-white bg-[rgba(255,255,255,0.2)] lg:bg-black rounded-xl w-10 h-10 p-1 border-0'>
                                    <ArrowUturnLeftIcon className='w-5' strokeWidth={2}/>
                                </AppButton>
                            ) : (
                                <UploadButton
                                    className={classNames(
                                        'relative lg:top-[20px] lg:right-[20px] flex justify-center text-white ' +
                                        'items-center text-gray rounded-xl w-10 h-10 p-1 border-0',
                                        (!originalBackground && !tempBackground) ? 'bg-black' : 'bg-[rgba(255,255,255,0.2)] lg:bg-black'
                                    )}>
                                    <EditIcon className='w-3' strokeWidth={2}/>
                                </UploadButton>
                            )}
                        </div>
                    )}
                </div>
                {/* <img
                    className='hidden sm:block absolute left-0 top-0 object-cover w-[360px] h-[500px] aspect-square opacity-70'
                    src={headerBackground}
                    alt="Left SVG"
                    style={{maskImage: "linear-gradient(to right, black 0%, transparent 100%)"}}
                />

                <img
                    className='absolute top-[-30px] right-0 sm:top-0 object-cover w-[360px] h-[500px] aspect-square opacity-100 sm:opacity-70 rotate-45 sm:rotate-0'
                    src={headerBackground}
                    alt="Right SVG"
                    style={{maskImage: "linear-gradient(to left, black 0, transparent 100%)"}}
                /> */}

                {!originalBackground && (
                    <div className='flex absolute left-[50%] bottom-[50%] translate-x-[-50%] translate-y-[20%]'>
                        <CircleImg img={ScandinavianForestPic} className='relative left-[15px] z-[1]'/>
                        <CircleImg img={OceanPic} className='relative z-[2]'/>
                        <CircleImg img={ForestMountainPic} className='relative right-[15px] z-[3]'/>
                    </div>
                )}
            </div>

            {loading && (
                <div
                    className="absolute inset-0 flex justify-center items-center bg-gray-700 bg-opacity-50 md:rounded-3xl">
                    <SpinnerLoader color="var(--primary)" size="16px"/>
                </div>
            )}
        </div>
    );
};

export default ProfileBackground;
