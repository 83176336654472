import ForgotPasswordView from "../features/auth/views/forgot-password/forgot-password";
import React, {useState} from "react";
import SendTokenResetPasswordView from "../features/auth/views/send-token-reset-password/send-token-reset-password";
import useAuth from "../features/auth/hooks/use-auth";
import {useNavigate} from "react-router-dom";
import {ReactComponent as ChevronLeftIcon} from "@assets/icons/chevron-left.svg";
import AppWrapper from "../layout/components/app-wrapper";
import Logo from "../features/core/components/ui/logo";

const ForgotPasswordPage = () => {
    const [step, setStep] = useState(1);
    const {getTokenToResetPassword, resetPassword} = useAuth();
    const navigate = useNavigate();

    const handleUsernameOrEmailSubmit = async (emailOrUsername: string) => {
        await getTokenToResetPassword(emailOrUsername)
            .then(() => setStep(2))
            .catch(() => {
            })
    };

    const handleVerificationCodeSubmit = async (code: string) => {
        await getTokenToResetPassword(code)
            .then(() => setStep(3))
            .catch(() => {
            })
            .finally()
    };

    const handleNewPasswordSubmit = async (token: string, newPassword: string) => {
        await resetPassword(token, newPassword)
            .then(() => navigate('/auth/login'))
            .catch(() => {
            })
            .finally()
    };

    const goToPrevStep = () => {
        if (step === 1) navigate('/auth/login');
        else setStep(prev => prev - 1);
    }

    return (
        <AppWrapper className='h-dvh overflow-clip flex flex-col'>
            <div className='hidden md:block p-6 pb-0'>
                <Logo type='complete' className='w-[6rem]'/>
            </div>
            <div className='overflow-y-auto h-full '>
                <div className="flex flex-col gap-y-6 p-4 max-w-md mx-auto h-full">
                    <div className="flex items-center gap-4">
                        <button
                            type='button'
                            className='p-3 w-10 h-10 bg-gray-light flex items-center justify-center rounded-2xl text-special-gray'
                            onClick={goToPrevStep}>
                            <ChevronLeftIcon className='w-2 h-3'/>
                        </button>
                        <h2 className="text-3xl font-bold">{step < 2 ? 'Forgot password' : 'Set new password'}</h2>
                    </div>
                    {step === 1 && <ForgotPasswordView onSubmit={handleUsernameOrEmailSubmit}/>}
                    {step === 2 && <SendTokenResetPasswordView onSubmit={handleNewPasswordSubmit}/>}
                    {/*{step === 3 && <ResetPasswordView onSubmit={handleNewPasswordSubmit}/>}*/}
                </div>
            </div>
        </AppWrapper>
    )
}

export default ForgotPasswordPage
