import {useState} from 'react';
import DictionaryService from "../services/dictionary.service";
import {DictionaryEntry} from "../models/interfaces/dictionary-entry.interface";

export const useDictionaryApi = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const getCountries = async (name?: string): Promise<DictionaryEntry[]> => {
        setLoading(true);
        setError(null);
        try {
            return await DictionaryService.getCountries(name);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const getGenders = async (): Promise<DictionaryEntry[]> => {
        setLoading(true);
        setError(null);
        try {
            return await DictionaryService.getGenders();
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return {
        loading,
        error,
        getCountries,
        getGenders
    };
};
