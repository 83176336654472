import { ComponentType } from "react";

interface TitleSectionProps {
    Icon: ComponentType<any>,
    title: string,
    iconClassName?: string,
    titleClassName?: string,
}

const TitleSection = (props: TitleSectionProps) => {
    const { title, Icon, iconClassName, titleClassName } = props;

    return (
        <header className="flex items-center font-bold text-lg leading-[1.35rem] gap-x-3 h-fit">
            <Icon className={"w-[1.25rem] h-fit "+iconClassName} />
            <h3 className={titleClassName}>{title}</h3>
        </header>
    );
};

export default TitleSection;
