import {FormProvider, useForm} from "react-hook-form";
import React from "react";
import {yupResolver} from "@hookform/resolvers/yup";
import AppButton from "../../common/components/app-button";
import NumberFormField from "../../common/components/forms/number-form-field";
import useAuth from "../hooks/use-auth";
import PasswordFormField from "../../common/components/forms/password-form-field";
import {ResetPasswordFormBody} from "../models/interfaces/reset-email-body.interface";
import {schemaSendTokenResetPassword} from "../constants/schema-send-token-reset-password";


interface SendTokenResetPasswordFormProps {
    onSubmit: (token: string, newPassword: string) => Promise<void>;
}

const SendTokenResetPasswordForm = ({onSubmit}: SendTokenResetPasswordFormProps) => {
    const {loading} = useAuth();

    const methods = useForm<ResetPasswordFormBody>({
        resolver: yupResolver(schemaSendTokenResetPassword),
        mode: "onBlur",
        delayError: 100,
    });

    const handleCodeSubmit = (data: ResetPasswordFormBody) => {
        onSubmit(data.token, data.newPassword)
            .then()
            .catch(() => {
            })
            .finally();
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleCodeSubmit)}
                  className='flex flex-col justify-between gap-y-5 flex-grow'>
                <div className='space-y-4'>
                    <NumberFormField maxLength={6} name="token" label="Verification code" placeholder="Write"/>
                    <PasswordFormField name='newPassword' label='New password' placeholder='Write new password...'/>
                    <PasswordFormField name='confirmPassword' label='Repeat new password'
                                       placeholder='Repeat new password...'/>
                    {/*<p className="text-gray text-xs font-medium flex gap-1 items-center">*/}
                    {/*    <InformationCircleIcon className='w-4 h-4 cursor-pointer'/>*/}
                    {/*    Didn’t receive a code? <span className='underline cursor-pointer'>Resend a code (47)</span>*/}
                    {/*</p>*/}
                </div>
                <AppButton
                    type="submit"
                    loading={loading}
                    label="Reset password"
                    className="w-full text-white text-sm py-[9px] h-fit !rounded-2xl font-semibold bg-primary"
                />
            </form>
        </FormProvider>
    );
};

export default SendTokenResetPasswordForm;
