import {getDictionaryRoles} from "../helpers/dictionary-roles";
import AppButton from "../../common/components/app-button";
import {UserAuthority} from "../../auth/models/enums/user-authority.enum";
import {classNames} from "../../../utils/class-names";
import Tooltip from "../../common/components/tooltip";
import {Case, Default, Switch} from "../../common/components/switch-case";
import BenefitsAccount from "./ui/benefits-account";
import {accBenefitsFan} from "../constants/acc-benefits-fan";
import {accBenefitsCreator} from "../constants/acc-benefits-creator";

interface SelectorUserRoleProps {
    selectedRole: UserAuthority,
    onSelect: (role: UserAuthority) => void;
}

const SelectorUserRole = (props: SelectorUserRoleProps) => {
    const roles = getDictionaryRoles();
    const {selectedRole, onSelect} = props;

    const handleOnSelect = (role: UserAuthority) => {
        onSelect(role);
    }

    return (
        <div className='flex flex-col gap-1'>
            <span className='flex gap-1'>
                <p className='font-semibold text-base'>Select type of account</p>
                <Tooltip classNameIcon='text-xs text-special-gray relative top-[6px]'/>
            </span>
            <div className='w-full p-1 flex bg-gray-light max-h-[40px] rounded-2xl h-[40px] mb-4 gap-1'>
                {roles.map(role =>
                    <AppButton
                        key={role.value}
                        type='button'
                        onClick={() => handleOnSelect(role.value)}
                        className={classNames(
                            'w-full border-none h-full !p-0 text-special-gray text-[0.8135rem] focus:outline-none ' +
                            'focus:ring-2 focus:ring-primary',
                            (selectedRole === role.value) && "bg-white font-semibold !text-black drop-shadow-xl"
                        )}
                        label={role.name}/>
                )}
            </div>
            <Switch value={selectedRole} >
                <Case case={UserAuthority.FAN}><BenefitsAccount benefits={accBenefitsFan} /></Case>
                <Case case={UserAuthority.CONTENT_CREATOR}><BenefitsAccount benefits={accBenefitsCreator} /></Case>
                <Default><></></Default>
            </Switch>
        </div>
    )
}

export default SelectorUserRole
