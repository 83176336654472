import { FormProvider, useForm } from "react-hook-form"
import { AppCCFormValues, AppFanFormValues, EmailCCFormValues, EmailFanFormValues, NotificationType } from "../views/notifications-view"
import { useEffect } from "react";
import { CCAppNotificationDto, CCEmailNotificationDto, FanAppNotificationDto, FanEmailNotificationDto } from "../service/notification-dto";
import notificationsService from "../service/notifications-service";
import { ReactComponent as AppIcon } from "@assets/icons/mobile-push.svg";
import { ReactComponent as MessageIcon } from "@assets/icons/mail.svg"
import NotificationsCCCard from "./notifications-cc-card";



const NotificationsCC = () => {


    const methodsApp = useForm<AppCCFormValues>({
        defaultValues: {
            isNotificationsApp: false,
            isTextMessagesApp: false,
            isVoiceMessagesApp: false,
            isVideoMessagesApp: false,
            isNewFanApp: false,
            isNewSubscriptionApp: false,
            isPurchasePeApp: false
        },
        mode: "all"
      });
    
      const methodsEmail = useForm<EmailCCFormValues>({
        defaultValues: { 
            isNotificationsEmail: false,
            isTextMessagesEmail: false,
            isVoiceMessagesEmail: false,
            isVideoMessagesEmail: false,
            isNewFanEmail: false,
            isNewSubscriptionEmail: false,
            isPurchasePeEmail: false
         },
      });


      const handleToggleApp = async (checked: boolean, name: any) => {
            const oldChecked = methodsApp.getValues(name);
                try {
                    methodsApp.setValue(name, checked);
                    const body : CCAppNotificationDto =  {
                        appNotification: methodsApp.getValues("isNotificationsApp"),
                        newTextMessage: methodsApp.getValues("isTextMessagesApp"),
                        newVoiceMessage: methodsApp.getValues("isVoiceMessagesApp"),
                        newVideoMessage: methodsApp.getValues("isVideoMessagesApp"),
                        newFan: methodsApp.getValues("isNewFanApp"),
                        newSubscription: methodsApp.getValues("isNewSubscriptionApp"),
                        newPurchasePe: methodsApp.getValues("isPurchasePeApp")
                    }
                    
                  await notificationsService.updateCCAppNotifications(body).then(e=> {  
                  })
                } catch (error) {
                methodsApp.setValue(name, oldChecked)
                  console.error('Error updating data:', error);
                }
          };

        const handleToggleEmail = async (checked: boolean, name: any) => {
            const oldChecked = methodsApp.getValues(name);
                try {
                    methodsEmail.setValue(name, checked);
                    const body : CCEmailNotificationDto =  {
                        emailNotification: methodsEmail.getValues("isNotificationsEmail"),
                        newTextMessage: methodsEmail.getValues("isTextMessagesEmail"),
                        newVoiceMessage: methodsEmail.getValues("isVoiceMessagesEmail"),
                        newVideoMessage: methodsEmail.getValues("isVideoMessagesEmail"),
                        newFan: methodsEmail.getValues("isNewFanEmail"),
                        newSubscription: methodsEmail.getValues("isNewSubscriptionEmail"),
                        newPurchasePe: methodsEmail.getValues("isPurchasePeEmail")
                    }
                    
                    await notificationsService.updateCCEmailNotifications(body).then(e=> {  
                  })
                } catch (error) {
                methodsEmail.setValue(name, oldChecked)
                  console.error('Error updating data:', error);
                }
          };

        useEffect(() => {
            async function fetchAppData() {
                await notificationsService.getCCAppNotifications().then(e=> {
                    console.log(e)
                    methodsApp.reset(
                        {   
                            isNotificationsApp: e.appNotification,
                            isTextMessagesApp: e.newTextMessage,
                            isVoiceMessagesApp: e.newVoiceMessage,
                            isVideoMessagesApp: e.newVideoMessage,
                            isNewFanApp: e.newFan,
                            isNewSubscriptionApp: e.newSubscription,
                            isPurchasePeApp: e.newPurchasePe
                        }
                    )
                })
            }

            async function fetchEmailData() {
                await notificationsService.getCCEmailNotifications().then(e=> {
                    console.log(e)
                    methodsEmail.reset(
                        {   
                            isNotificationsEmail: e.emailNotification,
                            isTextMessagesEmail: e.newTextMessage,
                            isVoiceMessagesEmail: e.newVoiceMessage,
                            isVideoMessagesEmail: e.newVideoMessage,
                            isNewFanEmail: e.newFan,
                            isNewSubscriptionEmail: e.newSubscription,
                            isPurchasePeEmail: e.newPurchasePe
                        }
                    )
                })
            }

            fetchAppData();
            fetchEmailData();
    }, []);

    return (
        <>
            <FormProvider {...methodsApp}>
                <NotificationsCCCard type="App" icon={AppIcon} name="Mobile App" onToggle={handleToggleApp} methods={methodsApp}/>
            </FormProvider>
            <div className="mb-4 mt-4 border border-gray w-full md:mb-0 md:absolute md:border-0 md:m-0"></div>
            <FormProvider {...methodsEmail}>
                <NotificationsCCCard type="Email" icon={MessageIcon} name="Email" onToggle={handleToggleEmail} methods={methodsEmail}/>
            </FormProvider>
        </>
    )
}
export default NotificationsCC;