import React from 'react';
import {useFormContext} from "react-hook-form";
import SkeletonFormField from "./skeleton-form-field";
import {classNames} from "../../../../utils/class-names";
import { CogIcon } from '@heroicons/react/24/solid';

interface TextFormFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label: string;
    type?: string;
    className?: string;
    isLoading?: boolean,
    isRequired?: boolean,
    forceDisabled?: boolean,
    icon?: React.ElementType
}

const TextFormField: React.FC<TextFormFieldProps> = (props: TextFormFieldProps) => {
    const {register, formState: {errors, isSubmitting,disabled}} = useFormContext();
    const {
        name,
        label,
        type = 'text',
        className = '',
        isLoading,
        isRequired = false,
        forceDisabled = false,
        icon : Icon,
        ...rest
    } = props

    return (
        <div className='w-full'>
            
            <div className={`relative w-full h-fit`}>
                    <label htmlFor={name} className={classNames(
                        "absolute block text-xs font-medium top-2 left-[14px]",
                        (forceDisabled || disabled) && "text-gray",
                        (Icon) && "left-[4rem]"
                    )}>
                        {label}
                        {isRequired && <span className='text-red-500'>*</span>}
                    </label>
                    {Icon? <Icon className='w-[40px] h-[40px] top-[10px] left-[15px] absolute'/> : ''}
                    {isLoading
                        ? <div className='pt-6 px-3'><SkeletonFormField/></div>
                        : <input
                            type={type}
                            id={name}
                            {...register(name)}
                            placeholder={'Write'}
                            className={classNames(
                                `border-2 border-gray-light rounded-xl px-3 py-2 pt-6 appearance-none w-full focus:outline-none focus:ring-2 focus:ring-primary ${errors[name] ? 'border border-red-400 focus:ring-red-400' : 'border-gray-300 focus:ring-primary_400'} ${className}`,
                                (forceDisabled || disabled) && "bg-gray-light text-gray",
                                (Icon) && "pl-[4rem]"
                            )}
                            disabled={isSubmitting || forceDisabled || disabled}
                            {...rest}
                        />}

            </div>
            {errors[name] && <p className="text-red-500 text-xs">{'' + errors[name]?.message}</p>}
        </div>
    );
};

export default TextFormField;
