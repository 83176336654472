import React from 'react';
import {useNavigate} from 'react-router-dom';
import AppModal from "../../common/components/app-modal";
import {ModalIds} from "../../common/models/enums/modal-ids.enum";
import {useModal} from "../../common/hooks/use-modal";
import AppButton from "../../common/components/app-button";

const LoginRequiredModal: React.FC = () => {
    const {closeModal} = useModal();
    const navigate = useNavigate();

    const handleCloseModal = () => closeModal(ModalIds.LOGIN_REQUIRED);

    const handleLoginClick = () => {
        handleCloseModal();
        navigate('/auth/login');
    };

    return (
        <AppModal key={ModalIds.LOGIN_REQUIRED} id={ModalIds.LOGIN_REQUIRED} disableScroll={true}>
            <div>
                <h2 className="text-xl font-semibold mb-4">Login Required</h2>
                <p className="text-gray mb-6">You need to be logged in to perform this action.</p>
                <div className="flex justify-end gap-4">
                    <AppButton
                        onClick={handleCloseModal}
                        className="text-primary-darken border-0 px-4 py-2 rounded-lg border-primary-darken hover:border transition"
                        label='Cancel'
                    />
                    <AppButton
                        onClick={handleLoginClick}
                        className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-primary-darken transition"
                        label='Go to Login'
                    />
                </div>
            </div>
        </AppModal>
    );
};

export default LoginRequiredModal;
