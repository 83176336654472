import databaseService from "../indexedDB.service";
import {IndexeddbData} from "../models/indexeddb-data.interface";

export class BaseRepository<EntityDTO> {
    protected readonly storeName: string;

    constructor(storeName: string) {
        this.storeName = storeName;
    }

    async getAllEntities(): Promise<EntityDTO[]> {
        const res = await databaseService.getAll<EntityDTO>(this.storeName)
        return res.data
    }

    async getEntityById(id: number | string): Promise<EntityDTO | undefined> {
        const res = await databaseService.getByKeyPath<EntityDTO>(this.storeName, id);
        return res.data
    }

    async createEntity(data: Omit<EntityDTO, 'id'>): Promise<IDBValidKey> {
        return databaseService.add<Omit<EntityDTO, 'id'>>(this.storeName, data);
    }

    async updateEntity(data: EntityDTO): Promise<void> {
        await databaseService.update<EntityDTO>(this.storeName, data);
    }

    async deleteEntity(id: number | string): Promise<void> {
        await databaseService.delete(this.storeName, id);
    }
}
