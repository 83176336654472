import React, {cloneElement, useRef, useState} from "react";
import {useDynamicPosition} from "../hooks/use-dynamic-position";
import useClickOutside from "../hooks/use-click-outside";
import ReactDOM from "react-dom";

interface PopoverProps {
    trigger: React.ReactElement;
    children: React.ReactNode;
    preferredPositionY?: "top" | "bottom";
    preferredPositionX?: "left" | "right";
    popoverClassName?: string;
}

const Popover = (props: PopoverProps) => {
    const [isVisible, setIsVisible] = useState(false);
    const popoverRef = useRef<HTMLDivElement>(null);
    const triggerRef = useRef<HTMLElement>(null);

    const {
        trigger,
        children,
        preferredPositionY = "bottom",
        preferredPositionX = "right",
        popoverClassName,
    } = props;

    const hidePopover = () => setIsVisible(false);
    const togglePopover = () => setIsVisible((prev) => !prev);

    const position = useDynamicPosition(isVisible, triggerRef, popoverRef, {
        preferredPositionY,
        preferredPositionX,
        offset: 8,
        shift: 40,
    });

    useClickOutside(hidePopover, [popoverRef, triggerRef]);

    const popoverContent = () => {
        return ReactDOM.createPortal(
            <div
                ref={popoverRef}
                className={`absolute z-10 p-4 flex flex-col gap-4 text-sm bg-white text-black rounded-2xl shadow-popover ${popoverClassName}`}
                style={{top: position.top, left: position.left}}
            >
                {children}
            </div>,
            document.body
        );
    }

    const triggerWithRef = cloneElement(trigger, {
        ref: triggerRef,
        onClick: togglePopover,
        isActive: isVisible,
    });

    return (
        <>
            {triggerWithRef}
            {isVisible && popoverContent()}
        </>
    );
};

export default Popover;
