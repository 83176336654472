import React from "react";
import {ReactComponent as Check} from '@assets/icons/check.svg';
import SettingsHeader from "../components/settings-header";

const TermsView = () => {

    return (<>
        <div className=" ml-[10px] mr-[10px] md:ml-auto md:mr-auto md:w-[756px] text-[16px] font-medium gap-[16px]">
                {/* <h1 className="text-[32px] font-bold">Terms</h1>
                 */}
                <SettingsHeader title='Terms'/>

                <div>
                    <h1 className="text-[32px] font-bold">Heading 1</h1>
                    <span className="text-[22px] leading-[29px] font-semibold"><br className="leading-[16px]"/>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                    
                    <br className="leading-[16px]"/>
                    <br className="leading-[16px]"/>
                    <span className="leading-[24px]">
                        Dummy text ever since 1500s, when an uknown printer took a galley of type and scralbled it to make a type specimen.
                        <br/><br/>

                        There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration i some form, by injected humour, or randomised words whic don't look even slightly velievable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarassing hidden in the middle of text.
                    </span>
                </div>

                <br/>

                <div className="w-[328px] leading-[22px]">
                    <h2 className="text-[28px] leading-[46px] font-bold">Heading 2</h2>
                    
                    Randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum.
                    <br/><br/>
                    <ol className="list-inside leading-[22px]">
                        <li className="mb-1 before:text-[14px] before:leading-[21px] before:content-['01.'] before:text-primary"> Lorem ipsum doloer sit amet consectet</li>
                        <li className="mb-1 before:text-right before:text-[14px] before:leading-[21px] before:content-['02.'] before:text-primary"> Dolor sit amet consectetur. Amet mollis mollis at ornare et</li>
                        <li className="mb-1 before:text-[14px] before:leading-[21px] before:content-['03.'] before:text-primary"> Figma ipsum component variant</li> 
                    </ol>
                </div>
                <br/>
                <div className="leading-[32px]">
                    <h3 className="text-[24px] leading-[28.8px] font-bold">Heading 3</h3>

                    There are many variations of passages of Lorem Ipsum available:

                    <ul className="leading-[22px]">
                        <li className="mb-1"><Check className="text-primary w-[10px] min-w-[10px] h-[7.14px] inline-block"/> Lorem Ipsum dolor sit amet consectet</li>
                        <li className="mb-1"><Check className="text-primary w-[10px] min-w-[10px] h-[7.14px] inline-block"/> Dolor sit amet consectetur. Amet mollis mollis at ornare et</li>
                        <li className="mb-1"><Check className="text-primary w-[10px] min-w-[10px] h-[7.14px] inline-block"/> Figma ipsum component variant</li>
                    </ul>
                </div>
        </div>
        
    </>)
}
export default TermsView;

