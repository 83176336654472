export class DateHelper {
    /**
     * Formats a given Date object into a string based on the specified format.
     * @param date - The date to format.
     * @param format - The format string (e.g., 'YYYY-MM-DD', 'DD-MM-YYYY', 'MM/DD/YYYY').
     * @returns A formatted date string.
     */
    static formatDate(date: Date, format: string): string {
        const map: { [key: string]: string | number } = {
            YYYY: date.getFullYear(),
            MM: ('0' + (date.getMonth() + 1)).slice(-2),
            DD: ('0' + date.getDate()).slice(-2),
            HH: ('0' + date.getHours()).slice(-2),
            mm: ('0' + date.getMinutes()).slice(-2),
            ss: ('0' + date.getSeconds()).slice(-2),
        };

        return format.replace(/YYYY|MM|DD|HH|mm|ss/g, (matched) => map[matched].toString());
    }

    /**
     * Maps a date string from one format to another.
     * @param dateStr - The date string to map (e.g., '2024-08-14').
     * @param fromFormat - The original format of the date string (e.g., 'YYYY-MM-DD').
     * @param toFormat - The target format to map the date to (e.g., 'DD-MM-YYYY').
     * @returns A formatted date string in the target format.
     * @throws Error if the date string or format is invalid.
     */
    static mapDate(dateStr: string, fromFormat: string, toFormat: string): string {
        const parts: { [key: string]: number | null } = {};
        const fromFormatRegex = fromFormat.replace(/YYYY|MM|DD|HH|mm|ss/g, (matched) => {
            parts[matched] = null;
            return `(${matched === 'YYYY' ? '\\d{4}' : '\\d{2}'})`;
        });

        const match = new RegExp(fromFormatRegex).exec(dateStr);

        if (match) {
            Object.keys(parts).forEach((key, index) => {
                parts[key] = parseInt(match[index + 1], 10);
            });

            const date = new Date(
                parts['YYYY'] || 0,
                (parts['MM'] || 1) - 1,
                parts['DD'] || 1,
                parts['HH'] || 0,
                parts['mm'] || 0,
                parts['ss'] || 0
            );

            return this.formatDate(date, toFormat);
        }

        throw new Error('Invalid date or format');
    }

    /**
     * Adds a specified number of days to a given date.
     * @param date - The date to add days to.
     * @param days - The number of days to add.
     * @returns A new Date object with the added days.
     */
    static addDays(date: Date, days: number): Date {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    /**
     * Adds a specified number of months to a given date.
     * @param date - The date to add months to.
     * @param months - The number of months to add.
     * @returns A new Date object with the added months.
     */
    static addMonths(date: Date, months: number): Date {
        const result = new Date(date);
        result.setMonth(result.getMonth() + months);
        return result;
    }

    /**
     * Checks if two dates are equal, ignoring the time component.
     * @param date1 - The first date.
     * @param date2 - The second date.
     * @returns true if the dates are equal (ignoring time), false otherwise.
     */
    static areDatesEqual(date1: Date, date2: Date): boolean {
        return (
            date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate()
        );
    }

    /**
     * Zwraca tekst wskazujący, ile czasu minęło od podanej daty.
     * @param date - Data, z którą porównujemy aktualny czas.
     * @returns Tekst reprezentujący upływ czasu (np. "1 minutę temu", "3 dni temu").
     */
    static timeAgo(date: Date): string {
        const now = new Date();
        const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

        const intervals = [
            { label: 'year', seconds: 31536000, singular: 'year', plural: 'years', pluralMany: 'years' },
            { label: 'month', seconds: 2592000, singular: 'month', plural: 'months', pluralMany: 'months' },
            { label: 'week', seconds: 604800, singular: 'week', plural: 'weeks', pluralMany: 'weeks' },
            { label: 'day', seconds: 86400, singular: 'day', plural: 'days', pluralMany: 'days' },
            { label: 'hour', seconds: 3600, singular: 'hour', plural: 'hours', pluralMany: 'hours' },
            { label: 'minute', seconds: 60, singular: 'minute', plural: 'minutes', pluralMany: 'minutes' },
            { label: 'second', seconds: 1, singular: 'second', plural: 'seconds', pluralMany: 'seconds' },
        ];

        for (const interval of intervals) {
            const count = Math.floor(seconds / interval.seconds);
            if (count >= 1) {
                if (interval.label === 'day' && count > 3) {
                    return this.formatDate(date, 'MM.DD.YYYY HH:mm');
                }
                const label = this.getEnglishLabel(interval, count);
                return `${count} ${label} ago`;
            }
        }

        return 'Just now';
    }


    /**
     * Returns the appropriate form of the time unit label in English.
     * @param interval - Object containing data about the time unit.
     * @param count - Number of time units.
     * @returns Correct singular or plural form in English.
     */
    private static getEnglishLabel(interval: { singular: string, plural: string, pluralMany: string }, count: number): string {
        return count === 1 ? interval.singular : interval.plural;
    }
}
