import AppButton from "../../../common/components/app-button";
import React from "react";
import {ReactComponent as AudioIcon} from "@assets/icons/audio.svg"

const VoiceCallButton = () => {
    return (
        <AppButton label=''
                   className='text-special-gray w-full md:w-fit h-fit text-sm font-semibold min-h-[40px]'
                   disabled={true}>
            <div className='flex gap-1 items-center'>
                <AudioIcon className="block w-4 min-w-4 h-4 text-special-gray"/>
                <span className='whitespace-nowrap'>Voice call</span>
            </div>
        </AppButton>
    )
}

export default VoiceCallButton
