import 'stream-chat-react/dist/css/v2/index.css';
import React from "react";
import ChatList from "./components/containers/chat-list";
import ActiveChat from "./components/containers/active-chat";

const AppChat = () => {
    return (
        <div className="min-w-full block md:flex gap-4 h-full max-h-full overflow-clip md:overflow-auto ">
            <ChatList/>
            <ActiveChat/>
        </div>
    )
};

export default AppChat;
