import React, {useEffect, useRef, useState} from "react";
import {ReactComponent as InfoIcon} from "@assets/icons/info.svg";
import {ReactComponent as XMarkIcon} from "@assets/icons/cross.svg";
import {ReactComponent as EditIcon} from "@assets/icons/edit.svg";
import AppButton from "../../common/components/app-button";
import NotificationService from "../../common/services/notification.service";
import FileHelper from "../../../utils/file-helper";

interface ProductImageUploaderProps {
    label?: string;
    description?: string;
    maxFileSizeInMb?: number;
    allowedFormats?: string[];
    onImageChange?: (file: File | null) => void;
    imageFile?: File | null;
    isSubmittingSuccessful: boolean;
    isSubmitting: boolean;
}

const ProductImageUploader: React.FC<ProductImageUploaderProps> = (props: ProductImageUploaderProps) => {
    const [base64Image, setBase64Image] = useState<string | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const {
        label = "Product cover",
        description = "Max. 10mb in .jpg, .jpeg, .png, .svg format",
        maxFileSizeInMb = 10,
        allowedFormats = ["jpeg", "jpg", "png", "svg"],
        onImageChange,
        isSubmittingSuccessful,
        isSubmitting,
        imageFile
    } = props;
    const inputRef = useRef<HTMLInputElement>(null);

    const convertFileToBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const uploadedFile = event.target.files?.[0];

        if (uploadedFile) {
            const fileExtension = uploadedFile.name.split(".").pop()?.toLowerCase();
            const isAllowed = allowedFormats.includes(fileExtension || "");
            const isUnderSizeLimit = uploadedFile.size <= maxFileSizeInMb * 1024 * 1024;

            if (!isAllowed) {
                NotificationService.warning(`Invalid file format. Allowed formats: ${allowedFormats.join(", ")}`, '', 5000);
            } else if (!isUnderSizeLimit) {
                NotificationService.warning(`File size exceeded. Maximum allowed size is ${maxFileSizeInMb}MB.`, '', 5000);
            } else {
                const base64 = await convertFileToBase64(uploadedFile);
                setBase64Image(base64);
                setPreviewUrl(URL.createObjectURL(uploadedFile));
                onImageChange && onImageChange(uploadedFile);
            }
        }
    };

    const handleRemoveFile = () => {
        setBase64Image(null);
        setPreviewUrl(null);
        onImageChange && onImageChange(null);
    };

    const handleOpenExplorerFiles = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    useEffect(() => {
        if (isSubmittingSuccessful) handleRemoveFile();
    }, [isSubmittingSuccessful]);

    useEffect(() => {
        const setImageBase64 = async (imgFile: File) => {
            const fileToImageBase64 = await FileHelper.convertFileToBase64(imgFile)
            if (fileToImageBase64 !== base64Image) {
                setBase64Image(fileToImageBase64 || null);
                setPreviewUrl(fileToImageBase64 || null);
            }
        }
        imageFile && setImageBase64(imageFile);
    }, [imageFile]);

    return (
        <div className="border-2 border-dashed border-gray-light rounded-xl p-4 space-y-1">
            <div className="text-lg font-semibold leading-5">{label}</div>
            <div className="flex items-center mb-3">
                <InfoIcon className="h-3 w-3 min-w-3 inline-block mr-1"/>
                <p className="text-special-gray font-medium text-xs inline-block leading-[0.9rem]">
                    {description}
                </p>
            </div>
            {!base64Image ? (
                <AppButton
                    onClick={handleOpenExplorerFiles}
                    label="+ Add cover"
                    disabled={isSubmitting}
                    className="!mt-4 w-full !rounded-2xl hover:bg-primary hover:text-white bg-primary-pastel text-primary font-semibold"
                />
            ) : (
                <div className="relative group bg-gray-light w-full h-[300px] max-h-[300px] rounded-2xl !mt-4">
                    <div className="mx-auto w-full h-full overflow-hidden rounded-2xl">
                        <img
                            src={previewUrl || ""}
                            alt="Uploaded cover"
                            className="w-fit h-full object-cover object-center max-h-full mx-auto rounded-2xl"
                        />
                    </div>

                    <div className="absolute top-2 right-2 flex space-x-1">
                        <AppButton
                            label=""
                            onClick={handleOpenExplorerFiles}
                            className="bg-black rounded-xl text-white h-8 w-8 border-black"
                        >
                            <EditIcon className="w-[0.625rem] h-[0.625rem]"/>
                        </AppButton>
                        <AppButton
                            label=""
                            onClick={handleRemoveFile}
                            className="bg-black rounded-xl text-white h-8 w-8 border-black"
                        >
                            <XMarkIcon className="w-2 h-2"/>
                        </AppButton>
                    </div>
                </div>
            )}
            <input
                ref={inputRef}
                id="product-cover-upload"
                type="file"
                accept={allowedFormats.map((ext) => `.${ext}`).join(",")}
                className="hidden"
                onChange={handleFileUpload}
            />
        </div>
    );
};

export default ProductImageUploader;
