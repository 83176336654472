import {DictionaryGenders} from "../models/interfaces/dictionary-genders.interface";
import {GenderEnum} from "../models/enums/gender.enum";

export const getDictionaryGenders = (): DictionaryGenders => {
    return [
        {
            name: "Male",
            value: GenderEnum.MALE
        },
        {
            name: "Female",
            value: GenderEnum.FEMALE
        },
        {
            name: "Non binary",
            value: GenderEnum.NON_BINARY
        }
    ]
}
