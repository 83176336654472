import React, {useRef} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import AppButton from "../../common/components/app-button";
import {UniversalClickHandler} from "../../common/models/types/universal-click-handler.type";
import {schemaAccountDetailsPartTwo} from "../constants/schema-account-details";
import {useUserApi} from "../hooks/use-user-api";
import {AdditionalAccountDetails} from "../models/interfaces/additional-account-details.interface";
import NumberFormField from "../../common/components/forms/number-form-field";
import SelectorGender from "./selector-gender";
import {GenderEnum} from "../models/enums/gender.enum";
import TextFormField from "../../common/components/forms/text-form-field";
import SelectFormField from "../../common/components/forms/select-form-field";
import {useDictionaryApi} from "../hooks/use-dictionary-api";
import SkipStepVerification from "./skip-step-verification";
import {AuthenticatedUser} from "../../auth/models/interfaces/authenticated-user.interface";
import useAuth from "../../auth/hooks/use-auth";

interface AdditionalAccountDetailsFormProps {
    onSuccess?: UniversalClickHandler
}

const AdditionalAccountDetailsForm = ({onSuccess}: AdditionalAccountDetailsFormProps) => {
    const {sendAdditionalInfo, loading} = useUserApi();
    const {currentUser, updateAuthenticationField} = useAuth();
    const {getCountries} = useDictionaryApi();
    const formRef = useRef<HTMLFormElement>(null);
    const methods = useForm<AdditionalAccountDetails>({
        resolver: yupResolver(schemaAccountDetailsPartTwo),
        mode: 'onSubmit',
        defaultValues: {
            lastname: currentUser?.lastname ?? "",
            firstname: currentUser?.firstname ?? "",
            genderId: currentUser?.genderId ?? GenderEnum.MALE,
            contactNumber: currentUser?.phoneNumber ?? "",
        }
    });

    const handleSendBasicInfo = async () => {
        const isValid = await methods.trigger();
        if (!isValid) return;

        const data: AdditionalAccountDetails = methods.getValues();
        try {
            await sendAdditionalInfo(data);
            const updatedUser: AuthenticatedUser = {
                ...currentUser!,
                firstname: data.firstname,
                lastname: data.lastname,
            }
            updateAuthenticationField("user", updatedUser);
            onSuccess && await onSuccess();
        } catch (error) {
            console.error("Send additional data user failed:", error);
        }
    };

    const selectGender = async (gender: GenderEnum) => {
        methods.setValue('genderId', gender)
        await methods.trigger('genderId')
    }
    const getSelectedGender = () => methods.getValues('genderId')

    return (
        <FormProvider {...methods}>
            <form ref={formRef} onSubmit={methods.handleSubmit(handleSendBasicInfo)}
                  className="flex flex-col gap-y-6 p-4 max-w-md mx-auto h-full min-h-fit">

                <div className='flex-grow md:flex-grow-0 space-y-4'>
                    <div className="flex items-center gap-3 justify-between">
                        <h2 className="text-[1.75rem] md:text-[2.5rem] font-bold">Get free 20 yC</h2>
                        <span className='inline-block md:hidden'><SkipStepVerification onClick={onSuccess}/></span>
                    </div>

                    <span className='text-special-gray text-sm md:text-base'>
                        Fill the fields below to get free yCredits.
                    </span>

                    <NumberFormField name="contactNumber" label="Phone number" placeholder="Write" maxLength={9}/>
                    <SelectorGender selectedGender={getSelectedGender()} onSelect={selectGender}/>
                    <TextFormField name="firstname" label="Name" placeholder="ex. John"/>
                    <TextFormField name="lastname" label="Surname" placeholder="ex. Doe"/>
                    <SelectFormField name="countryId" label="Country" labelKey='name' valueKey='id'
                                     fetchOptions={getCountries} parentWrapperRef={formRef} searchable={true}/>
                </div>

                <AppButton
                    type='submit'
                    loading={loading}
                    label='Get free 20 yC'
                    className='w-full text-white text-sm py-[9px] h-fit rounded-lg font-semibold bg-primary'/>
            </form>
        </FormProvider>
    );
}

export default AdditionalAccountDetailsForm
