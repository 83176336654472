import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {NavigationCCProfile} from "../constants/navigation-c-c-profile";

const ProfileCcMenu = () => {
    const [active, setActive] = useState<string>(NavigationCCProfile[0].href);

    const handleTabClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, tabHref: string) => {
        event.preventDefault();
        setActive(tabHref)
        const element = document.getElementById(tabHref);
        if (element) {
            element.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
    };

    return (
        <nav className="flex px-4 py-3 overflow-x-auto overflow-y-clip h-fit">
            {NavigationCCProfile.map((item: any) => (
                    <Link
                        key={item.name}
                        to={`${item.href}`}
                        className={
                            active === item.href
                                ? 'border-b-2 border-black text-black text-sm font-semibold px-4 py-2 text-nowrap'
                                : 'text-special-gray border-b border-special-gray text-sm font-semibold px-3 py-2 text-nowrap'
                        }
                        onClick={(event) => handleTabClick(event, item.href)}
                    >
                        {item.name}
                    </Link>
                )
            )}
        </nav>
    );
};

export default ProfileCcMenu;
