import React, {useState} from 'react';
import {useFormContext} from "react-hook-form";
import SkeletonFormField from "./skeleton-form-field";
import {ReactComponent as EyeIcon} from "@assets/icons/eye-1.svg";
import {ReactComponent as EyeSlashIcon} from "@assets/icons/eye-2.svg";

interface PasswordFormFieldProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
    name: string;
    label: string;
    className?: string;
    isLoading?: boolean;
    showPasswordToggle?: boolean;
}

const PasswordFormField: React.FC<PasswordFormFieldProps> = (props: PasswordFormFieldProps) => {
    const {register, formState: {errors, isSubmitting}} = useFormContext();
    const {
        name,
        label,
        className = '',
        isLoading,
        showPasswordToggle = true,
        ...rest
    } = props;

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className='w-full'>
            <div className={`relative w-full h-fit`}>
                <label htmlFor={name} className="absolute block text-xs font-medium top-2 left-[14px]">
                    {label}
                </label>
                {isLoading
                    ? <div className='pt-6 px-3'><SkeletonFormField/></div>
                    : <>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            id={name}
                            {...register(name)}
                            placeholder="Write"
                            className={`border-2 border-gray-light rounded-xl px-3 py-2 pr-14 pt-6 appearance-none w-full focus:outline-none focus:ring-2 focus:ring-primary ${errors[name] ? 'border border-red-400 focus:ring-red-400' : 'border-gray-300 focus:ring-primary_400'} ${className}`}
                            disabled={isSubmitting}
                            {...rest}
                        />
                        {showPasswordToggle && (
                            <div
                                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                                onClick={togglePasswordVisibility}
                            >
                                {showPassword ? (
                                    <div className='bg-gray-light rounded-2xl w-10 h-10 flex items-center justify-center'>
                                        <EyeSlashIcon
                                            className={`h-fit w-[0.875rem]  ${errors[name] ? 'text-red-500' : 'text-special-gray'}`}
                                        />
                                    </div>
                                ) : (
                                    <div className='bg-gray-light rounded-2xl w-10 h-10 flex items-center justify-center'>
                                        <EyeIcon
                                            className={`h-fit w-[0.875rem] ${errors[name] ? 'text-red-500' : 'text-special-gray'}`}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                }
            </div>
            {errors[name] && <p className="text-red-500 text-xs">{'' + errors[name]?.message}</p>}
        </div>
    );
};

export default PasswordFormField;
