import {Link} from "react-router-dom";
import React from "react";
import AppButton from "../../common/components/app-button";
import {ReactComponent as ExternalIcon} from '@assets/icons/external.svg';
import {classNames} from "../../../utils/class-names";


interface SupportCardProps {
    icon: React.ElementType;
    label: string;
    description: string;
    title: string;
    textButton: string;
    linkUrl: string;
    className?: string;
    classNameButton?: string;
    classNameLabel?: string;
    classNameDescription?: string;
    classNameTitle?: string;
};

const SupportCard = (props: SupportCardProps) => {
    const {className, textButton, classNameDescription, classNameTitle, classNameLabel, classNameButton, icon: Icon, label, description, title, linkUrl} = props

    return (
        <div className={classNames(
            "h-full border-stone-200 border-2 rounded-3xl p-8 flex flex-col justify-between gap-4",
            className
        )}>
            <div>
                <div className={classNames(
                    "flex items-center text-special-gray text-sm",
                    classNameLabel
                )}>
                    <Icon className="w-[0.875rem] min-w-[0.875rem] h-[0.875rem] mr-2"/>
                    <span className='font-semibold text-[0.625rem] uppercase tracking-wider'>
                        {label}
                    </span>
                </div>
                <h2 className={classNames(
                    "font-bold text-xl mt-2 max-w-[270px]",
                    classNameTitle
                )}>
                    {title}
                </h2>
                <p className={classNames(
                    "text-sm text-special-gray break-words mt-2 max-w-[250px]",
                    classNameDescription
                )}>
                    {description}
                </p>
            </div>
            <Link to={linkUrl} target='_blank'>
                <AppButton
                    label=''
                    className={classNames(
                        "bg-primary-pastel text-sm text-primary hover:bg-violet-300 font-bold py-[9px] px-4 " +
                        "!rounded-2xl whitespace-nowrap",
                        classNameButton
                    )}>
                    <span className='inline-block'>{textButton}</span>
                    <ExternalIcon className='w-[0.625rem] min-w-[0.625rem] h-[0.625rem] inline-block ml-2 mb-[2px]'/>
                </AppButton>
            </Link>
        </div>
    );
}

export default SupportCard;
