import React, {useContext, useState} from 'react';
import {WalletContext} from "../../contexts/wallet-context";
import ElementTransactionHistory from "../element-transaction-history";
import {mapTransactionToUI} from "../../helpers/map-transaction-dto-to-ui";
import AppButton from "../../../common/components/app-button";

const ListTransactionHistory: React.FC = () => {
    const {transactions, loadMoreTransactions, showMoreTransactions} = useContext(WalletContext)!;
    const [page, setPage] = useState(0);

    const handleLoadMore = async () => {
        if (showMoreTransactions) {
            return await loadMoreTransactions(page + 1)
                .then(() => setPage(prevState => prevState + 1))
        }
    }

    return (
        <div className='flex flex-col h-full gap-4 px-2 items-end'>
            {transactions.map((section) => (
                <div key={section.label} className='flex flex-col gap-3 w-full'>
                    <div className='flex items-center gap-2'>
                        <h3 className="text-gray text-xs leading-[0.9rem] font-medium">{section.label}</h3>
                        <span className='block flex-grow border-b-[1px] border-special-gray'></span>
                    </div>
                    {section.items.map((transaction, index) => (
                        <ElementTransactionHistory key={index} transaction={mapTransactionToUI(transaction, index)}/>
                    ))}
                </div>
            ))}
            {showMoreTransactions && (
                <AppButton
                    label='Load more'
                    className='bg-primary-pastel text-primary-darken font-semibold h-fit w-full sm:w-fit text-sm'
                    onClick={handleLoadMore}/>
            )}
        </div>
    );
};

export default ListTransactionHistory;
