import React from 'react';
import {DirectionOfTransaction} from "../../core/models/enums/direction-of-transaction.enum";
import {classNames} from "../../../utils/class-names";
import {
    BanknotesIcon,
    Bars3CenterLeftIcon,
    ChatBubbleBottomCenterIcon,
    DocumentIcon,
    PhoneIcon,
    VideoCameraIcon
} from "@heroicons/react/24/outline";
import {TransactionSubjectType} from "../../core/models/enums/transaction-subject-type.enum";

//TODO change this 'type' on other enums
interface IconTypeTransactionProps {
    type: TransactionSubjectType;
    direction: DirectionOfTransaction;
}

const iconMapper = {
    [TransactionSubjectType.TEXT]: Bars3CenterLeftIcon,
    [TransactionSubjectType.PRIVATE_CHAT]: ChatBubbleBottomCenterIcon,
    [TransactionSubjectType.FILE]: DocumentIcon,
    [TransactionSubjectType.PAYMENT]: BanknotesIcon,
    [TransactionSubjectType.PRIVATE_VOICE_CALL]: PhoneIcon,
    [TransactionSubjectType.PRIVATE_VIDEO_CALL]: VideoCameraIcon,
    [TransactionSubjectType.CUSTOM_TRANSACTION]: BanknotesIcon,
};

const IconTypeTransaction: React.FC<IconTypeTransactionProps> = (props: IconTypeTransactionProps) => {
    const {type, direction} = props
    const IconComponent = iconMapper[type] || BanknotesIcon;

    return (
        <div className={classNames(
            "w-[54px] h-[54px] rounded-2xl flex items-center justify-center",
            direction === DirectionOfTransaction.OUTGOING ? "bg-semantic-danger-pastel text-semantic-danger" : "bg-semantic-success-pastel text-semantic-success",
        )}>
            <IconComponent className="h-6 w-6"/>
        </div>
    )
};

export default IconTypeTransaction;
