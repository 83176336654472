class Base64Helper {

    /**
     * Removes the Base64 prefix if it exists.
     * @param base64String - The Base64 string containing the prefix.
     * @returns A Base64 string without the prefix.
     */
    public removeBase64Prefix(base64String?: string | null): string {
        if (!base64String) return ""
        const base64PrefixPattern = /^data:image\/[a-zA-Z]+;base64,/;
        return base64String.replace(base64PrefixPattern, '');
    }

    /**
     * Adds the Base64 prefix if it does not exist.
     * @param base64String - The Base64 string without the prefix.
     * @param mimeType - The MIME type (e.g., 'image/png', 'image/jpeg').
     * @returns A Base64 string with the correct prefix.
     */
    public addBase64Prefix(base64String?: string | null, mimeType: string = 'image/png'): string {
        if (!base64String) return "";
        if (this.hasPrefix(base64String)) return base64String;
        else return `data:${mimeType};base64,${base64String}`;
    }

    /**
     * Converts a Base64 string to a Blob object.
     * @param base64String - The Base64 string to convert.
     * @param mimeType - The MIME type (e.g., 'image/png', 'image/jpeg').
     * @returns A Blob object created from the Base64 string.
     */
    public base64ToBlob(base64String: string, mimeType: string = 'image/png'): Blob | null {
        try {
            const byteString = atob(this.removeBase64Prefix(base64String));
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);

            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            return new Blob([ab], {type: mimeType});
        } catch (error) {
            console.error('Error converting Base64 to Blob:', error);
            return null;
        }
    }

    private hasPrefix(base64String: string): boolean {
        const base64PrefixPattern = /^data:image\/[a-zA-Z]+;base64,/;
        return base64PrefixPattern.test(base64String);
    }
}

export default new Base64Helper();
