import {useContext} from "react";
import {PaymentMethodContext} from "../contexts/payment-method-context";

export const usePaymentMethod = () => {
    const context = useContext(PaymentMethodContext);
    if (!context) {
        throw new Error("usePaymentMethod must be used within a PaymentMethodProvider");
    }
    return context;
};
