import {FormProvider, useForm} from "react-hook-form";
import React from "react";
import useAuth from "../hooks/use-auth";
import {yupResolver} from "@hookform/resolvers/yup";
import {GetTokenResetPasswordBodyRequest} from "../models/interfaces/reset-email-body.interface";
import TextFormField from "../../common/components/forms/text-form-field";
import AppButton from "../../common/components/app-button";
import {schemaForgotPassword} from "../constants/schema-forgot-password";

interface ForgotPasswordFormProps {
    onSubmit: (emailOrUsername: string) => Promise<void>
}

const ForgotPasswordForm = ({onSubmit}: ForgotPasswordFormProps) => {
    const {loading} = useAuth();

    const methods = useForm<GetTokenResetPasswordBodyRequest>({
        resolver: yupResolver(schemaForgotPassword),
        mode: "onBlur",
        delayError: 100,
    });

    const handleEmailSubmit = async (data: GetTokenResetPasswordBodyRequest): Promise<void> => {
        await onSubmit(data.email).catch(() => {})
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleEmailSubmit)}
                  className='flex flex-col justify-between gap-y-5 flex-grow '>
                <TextFormField name='email' label='Username or e-mail' placeholder='Write username or e-mail...'/>
                <AppButton
                    type='submit'
                    loading={loading}
                    label='Reset password'
                    className='w-full text-white text-sm py-[9px] h-fit !rounded-2xl font-semibold bg-primary'/>
            </form>
        </FormProvider>
    )
}

export default ForgotPasswordForm
