import {useEffect, useState} from 'react';
import {isChrome, isDesktop, isEdge, isIOS, isMobile, isOpera, isSafari} from 'react-device-detect';
import {environment} from "../../../environments/environment";
import {InstallInstruction} from "../models/interfaces/install-pwa-instruction.interface";
import {BeforeInstallPromptEvent} from "../models/interfaces/before-install-prompt-event.interface";
import LocalStorageService from "../services/local-storage.service";
import {LocalStorageKeys} from "../models/enums/local-storage-keys.enum";


/**
 * usePwaInstallHandler hook
 *
 * `usePwaInstallHandler` manages the entire logic of installing a Progressive Web App (PWA).
 * This hook handles:
 * - Checking if the app is ready for installation (`beforeinstallprompt` event),
 * - Storing the `deferredPrompt` for later installation prompt,
 * - Executing the installation process (`installPWA`),
 * - Providing fallback manual installation instructions if the native prompt is unsupported.
 *
 * ### **Usage Note**
 * **To ensure correct functionality, `usePwaInstallHandler` must be initialized at the very beginning of the app, ideally within a top-level component or context, to capture the `beforeinstallprompt` event.**
 * If this hook is not activated early, the event may not be available later.
 *
 * ### State Variables and Functions
 * - `installPWA`: A function that triggers the installation prompt.
 * - `manualInstructions`: Provides alternative installation instructions for unsupported browsers.
 * - `isInstalled`: A boolean indicating if the PWA is already installed.
 *
 * ### Example Usage
 *
 * ```typescript
 * const {installPWA, manualInstructions, isInstalled } = usePwaInstallHandler();
 * ```
 */
const usePWAInstallHandler = () => {
    const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(null);
    const [isInstallable, setIsInstallable] = useState(false);
    const [isInstalled, setIsInstalled] = useState(false);
    const [manualInstructions, setManualInstructions] = useState<InstallInstruction | null>(null);

    useEffect(() => {
        const handler = (e: BeforeInstallPromptEvent) => {
            e.preventDefault();
            setDeferredPrompt(e);
            setIsInstallable(true);

            if (!environment.production) console.log('mount beforeinstallprompt')
        };

        if (!deferredPrompt) window.addEventListener('beforeinstallprompt', handler);

        return () => {
            console.log('unmount beforeinstallprompt')
            window.removeEventListener('beforeinstallprompt', handler);
        };
    }, []);

    useEffect(() => {
        checkIfInstalled();
    }, [isInstallable])

    const installPWA = async () => {
        if (deferredPrompt) {
            deferredPrompt?.prompt();
            const {outcome} = await deferredPrompt?.userChoice;
            if (outcome === 'accepted') {
                setIsInstalled(true);
                LocalStorageService.save(LocalStorageKeys.PWA_IS_INSTALLED, true);
                if (!environment.production) console.log('User accepted install prompt PWA');
            } else {
                if (!environment.production) console.log('User rejected install prompt PWA');
            }
            setDeferredPrompt(null);
            setIsInstallable(false);
        } else {
            handleFallback();
        }
    };

    const checkIfInstalled = () => {
        const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
        const isIosStandalone = (window.navigator as any).standalone === true;
        const hasAlreadyInstalledPWA = LocalStorageService.get(LocalStorageKeys.PWA_IS_INSTALLED);

        if ((isStandalone || isIosStandalone) && !hasAlreadyInstalledPWA) LocalStorageService.save(LocalStorageKeys.PWA_IS_INSTALLED, true)
        setIsInstalled(isStandalone || isIosStandalone || hasAlreadyInstalledPWA);
    };

    const handleFallback = () => {
        setManualInstructions(getInstallInstructions())
    };

    const getInstallInstructions = (): InstallInstruction => {
        const isBrave = navigator.userAgent.includes("Brave");

        if (isIOS && !isSafari) {
            return {
                header: "Instalacja aplikacji na IOS jest dostępna wyłącznie w Safari:",
                steps: [
                    "Aby zainstalować aplikację, otwórz stronę w przeglądarce Safari i postępuj zgodnie z instrukcjami instalacji."
                ]
            };
        }

        switch (true) {
            case isChrome && isDesktop:
                return {
                    header: "Instalacja dla Google Chrome:",
                    steps: [
                        "W pasku adresu znajduje się ikona pobrania apki.",
                        "Kliknij ikonę i potwierdź instalację.",
                        "Aplikacja zostanie dodana na pulpit lub do menu Start."
                    ]
                };
            case isChrome && isMobile:
                return {
                    header: "Instalacja dla Google Chrome:",
                    steps: [
                        "W menu (trzy kropki) w prawym górnym rogu wybierz „Dodaj do ekranu głównego”.",
                        "Potwierdź dodanie, klikając „Zainstaluj jako aplikację”."
                    ]
                };
            case isEdge && isDesktop:
                return {
                    header: "Instalacja dla Microsoft Edge:",
                    steps: [
                        "W pasku adresu pojawia się ikona instalacji (okno z plusem).",
                        "Kliknij ikonę i wybierz „Zainstaluj jako aplikację”.",
                        "Aplikacja pojawi się w menu Start lub na pulpicie."
                    ]
                };
            case isEdge && isMobile:
                return {
                    header: "Instalacja dla Microsoft Edge:",
                    steps: [
                        "W menu (trzy kropki) w prawym dolnym rogu wybierz „Dodaj do ekranu głównego”.",
                        "Potwierdź dodanie, klikając „Dodaj”."
                    ]
                };
            case isOpera && isDesktop:
                return {
                    header: "Instalacja dla Opera:",
                    steps: [
                        "W pasku adresu pojawia się ikona „Zainstaluj”.",
                        "Kliknij ikonę i potwierdź instalację.",
                        "Aplikacja pojawi się na pulpicie lub w menu Start."
                    ]
                };
            case isOpera && isMobile:
                return {
                    header: "Instalacja dla Opera:",
                    steps: [
                        "W menu (trzy kropki) w prawym górnym rogu wybierz „Dodaj do ekranu głównego”.",
                        "Potwierdź dodanie, klikając „Dodaj”."
                    ]
                };
            case isBrave && isDesktop:
                return {
                    header: "Instalacja dla Brave:",
                    steps: [
                        "W pasku adresu pojawia się ikona „Zainstaluj”.",
                        "Kliknij ikonę i potwierdź dodanie aplikacji.",
                        "Aplikacja zostanie dodana na pulpit i do menu Start."
                    ]
                };
            case isBrave && isMobile:
                return {
                    header: "Instalacja dla Brave:",
                    steps: [
                        "W menu (trzy kropki) w prawym górnym rogu wybierz „Dodaj do ekranu głównego”.",
                        "Potwierdź, klikając „Dodaj” w oknie dialogowym."
                    ]
                };
            case isSafari && isDesktop:
                return {
                    header: "Instalacja dla Safari:",
                    steps: [
                        "Wybierz ikonę „Udostępnij” w pasku narzędzi.",
                        "Wybierz „Dodaj do Docka”.",
                        "Ikona aplikacji pojawi się w Docku."
                    ]
                };
            case isSafari && isMobile:
                return {
                    header: "Instalacja dla Safari:",
                    steps: [
                        "Wybierz ikonę „Udostępnij” (kwadrat z strzałką w górę) na dole ekranu.",
                        "Wybierz „Dodaj do ekranu początkowego”.",
                        "Potwierdź, klikając „Dodaj”."
                    ]
                };
            default:
                return {
                    header: "Twoja przeglądarka nie wspiera instalacji PWA.",
                    steps: [
                        "Spróbuj użyć jednej z kompatybilnych przeglądarek, takich jak Chrome, Safari, Edge, Opera lub Brave."
                    ]
                };
        }
    };


    return {isInstalled, manualInstructions, setManualInstructions, installPWA};
};

export default usePWAInstallHandler;
