import {Case, Default, Switch} from "../../../common/components/switch-case";
import {PaymentMethodType} from "../../../core/models/enums/payment-method-type.enum";
import React from "react";
import {ReactComponent as PayPalIcon} from "@assets/icons/payments/paypal.svg";
import {ReactComponent as SkrillIcon} from "@assets/icons/payments/skrill.svg";
import {ReactComponent as BlikIcon} from "@assets/icons/payments/blik.svg";
import {ReactComponent as Transfers24Icon} from "@assets/icons/payments/transfers-24.svg";
import {ReactComponent as CardIcon} from "@assets/icons/payments/card.svg";

interface RenderPaymentMethodIconProps {
    option: PaymentMethodType,
}

const RenderPaymentMethodIcon = ({option}:RenderPaymentMethodIconProps) => {
    return (
        <Switch value={option}>
            <Case case={PaymentMethodType.PAYPAL}><PayPalIcon/></Case>
            <Case case={PaymentMethodType.SKRILL}><SkrillIcon/></Case>
            <Case case={PaymentMethodType.CARD}><CardIcon/></Case>
            <Case case={PaymentMethodType.BLIK}><BlikIcon/></Case>
            <Case case={PaymentMethodType.P24}><Transfers24Icon/></Case>
            <Default><span>Unsupported ICON</span></Default>
        </Switch>
    )
}

export default RenderPaymentMethodIcon
