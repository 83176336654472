export enum CloseButtonPosition {
    TOP_LEFT = "top-left",
    TOP_CENTER = "top-center",
    TOP_RIGHT = "top-right",
    BOTTOM_LEFT = "bottom-left",
    BOTTOM_CENTER = "bottom-center",
    BOTTOM_RIGHT = "bottom-right",
    LEFT_TOP = "left-top",
    LEFT_CENTER = "left-center",
    LEFT_BOTTOM = "left-bottom",
    RIGHT_TOP = "right-top",
    RIGHT_CENTER = "right-center",
    RIGHT_BOTTOM = "right-bottom",
}
