import Coin from "../../../core/components/ui/coin";
import React from "react";
import {DigitalProductDetails} from "../../../core/models/interfaces/digital-product-details.interface";
import FileHelper from "../../../../utils/file-helper";
import {DateHelper} from "../../../../utils/date-helper";

interface DetailsProductSectionProps {
    product: DigitalProductDetails
}

const DetailsProductSection = (props: DetailsProductSectionProps) => {
    const {product} = props

    const productSize = product.files.reduce((totalSize, file) => totalSize + file.fileSize, 0)
    const fileFormats = product.files
        .filter((file,index,self)=> index === self.findIndex(f => f.fileFormat === file.fileFormat))
        .reduce((totalSize, file, index) => totalSize + file.fileFormat+", ", "")
        .slice(0,-2)
    const editedDate = new Date(product.edited);

    return (
        <div className="space-y-4">
            <h2 className="text-lg font-semibold text-black leading-[1.35rem]">Details</h2>
            <div className="flex justify-between items-center">
                <span className='text-special-gray font-medium text-sm leading-[1.05rem]'>Price</span>
                <span className="font-bold text-black text-lg flex items-center gap-1">
                    {product.price} <Coin/>
                </span>
            </div>
            {/*<div className="flex justify-between">*/}
            {/*    <span className='text-special-gray font-medium text-sm leading-[1.05rem]'>Price with subscription</span>*/}
            {/*    <span className="flex items-center gap-1 font-medium text-base text-black leading-[1.2rem]">*/}
            {/*        {product.price} <Coin />*/}
            {/*    </span>*/}
            {/*</div>*/}
            <div className="flex justify-between">
                <span className='text-special-gray font-medium text-sm leading-[1.05rem]'>Author</span>
                <span className="font-medium text-base text-black leading-[1.2rem]">{product.creatorName}</span>
            </div>
            <div className="flex justify-between">
                <span className='text-special-gray font-medium text-sm leading-[1.05rem]'>Latest update</span>
                <span className="font-medium text-base text-black leading-[1.2rem]">{DateHelper.timeAgo(editedDate)}</span>
            </div>
            <div className="flex justify-between">
                <span className='text-special-gray font-medium text-sm leading-[1.05rem]'>Product size</span>
                <span className="font-medium text-base text-black leading-[1.2rem]">{FileHelper.getReadableFileSize(productSize)}</span>
            </div>
            <div className="flex justify-between">
                <span className='text-special-gray font-medium text-sm leading-[1.05rem]'>File formats</span>
                <span className="uppercase font-medium text-base text-black leading-[1.2rem]">{fileFormats}</span>
            </div>
        </div>
    )
}

export default DetailsProductSection
