import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-white">
            <h1 className="text-9xl font-bold text-purple-500">404</h1>
            <p className="mt-4 text-xl text-gray-600">Sorry, this page isn't available.</p>
            <p className="mt-2 text-md text-gray-500">
                The link you followed may be broken, or the page may have been removed.
            </p>
            <Link
                to="/"
                className="mt-4 text-purple-600 underline hover:text-red-500 transition-colors duration-200"
            >
                Go back to yurs.io
            </Link>
            <Link
                to="/"
                className="mt-6 px-6 py-3 bg-purple-500 text-white font-semibold rounded-lg hover:bg-purple-600 transition-colors duration-300"
            >
                Go Home
            </Link>
        </div>
    );
};

export default NotFoundPage;
